


















































































































































































































import Vue from 'vue';
import ApiPagingRequestViewModel from '../../../services/v1/viewModel/resource/ApiPagingRequestViewModel';
import ContactAddressResourceViewModel from '../../../services/v1/viewModel/resource/ContactAddressResourceViewModel';
import RegisterClassRequestViewModel from '../../../services/v1/viewModel/resource/RegisterClassRequestViewModel';
import WidgetClassListResourceViewModel from '../../../services/v1/viewModel/resource/WidgetClassListResourceViewModel';
import WidgetSearchClassRequestViewModel from '../../../services/v1/viewModel/resource/WidgetSearchClassRequestViewModel';

export default Vue.extend({
  layout: 'layout-widget-form',
  data: () => ({
    isRegisterSuccess: false,
    isSending: false,
    isLoading: true,
    specificQuestionsFormDefinition: undefined as any,
    marketingQuestionsFormDefinition: undefined as any,
    subjectAreaList: undefined as any,
    institutionModel: new ContactAddressResourceViewModel(),
    model: new RegisterClassRequestViewModel().fromDTO({
      contactAddress: {},
      correspondenceAddress: { contactAddress: {}, isDifferent: false },
      invoiceAddress: { contactAddress: {}, isDifferent: false },
    } as any),
    courseId: -1,
    course: new WidgetClassListResourceViewModel(),
    responseError: undefined as any,
  }),
  watch: {
    'model.correspondenceAddress.isDifferent': {
      handler() {
        if (!this.model.correspondenceAddress.isDifferent) {
          this.model.correspondenceAddress.contactAddress = new ContactAddressResourceViewModel();
        } else {
          this.updateFromInstitutionModel();
        }
      },
    },
    'model.invoiceAddress.isDifferent': {
      handler() {
        if (!this.model.invoiceAddress.isDifferent) {
          this.model.invoiceAddress.contactAddress = new ContactAddressResourceViewModel();
        } else {
          this.updateFromInstitutionModel();
        }
      },
    },
    institutionModel: {
      deep: true,
      handler() {
        this.updateFromInstitutionModel();
      },
    },
  },
  async mounted() {
    const pp = this.$routerUtils.getQueryParams();
    if (pp.id !== undefined) {
      this.courseId = Number.parseInt(pp.id, 10);
      if (!Number.isNaN(this.courseId)) {
        await this.loadCourseInformation();
      } else {
        this.responseError = `Ungültige Kursnummer: ${pp.id}`;
      }
    } else {
      // TODO: Should this not be an error state, shown to the user?
      this.$log.error('No courseId given');
    }
  },
  methods: {
    updateFromInstitutionModel() {
      this.institutionModel.isCompany = true;
      if (this.model.correspondenceAddress.isDifferent) {
        this.model.correspondenceAddress.contactAddress = this.institutionModel;
      }
      if (this.model.invoiceAddress.isDifferent) {
        this.model.invoiceAddress.contactAddress = this.institutionModel;
      }
    },
    async sendForm() {
      this.model.eventId = this.courseId;
      if (this.model.correspondenceAddress.isDifferent || this.model.invoiceAddress.isDifferent) {
        // Needs to be validated separatly since it's not the same model, but schemas are kept in same place
        if (!this.institutionModel.validateWithSchema(this.model.schemaInstitution)) {
          return;
        }
      }
      if (!this.model.validate()) {
        return;
      }
      this.isSending = true;
      try {
        await this.$service.api.widgetClasses.widgetClassRegisterRequest(this.courseId, this.model);
      } catch (e: any) {
        this.responseError = e;
      }
      this.isRegisterSuccess = true;
      this.isSending = false;
    },
    async loadDynamicForms() {
      const responseSpecific = await this.$service.api.widgetClasses.getWidgetClassRegistrationQuestions(this.courseId);
      this.specificQuestionsFormDefinition = responseSpecific.items;
      const responseMarketing = await this.$service.api.widgetClasses.getWidgetRegistrationMarketingQuestions();
      this.marketingQuestionsFormDefinition = responseMarketing.items;
      const responseSubjectArea = await this.$service.api.widgetClasses.getWidgetSubjectAreas();
      this.subjectAreaList = responseSubjectArea.items;
    },
    async loadCourseInformation() {
      this.isLoading = true;
      await this.$guards.waitForAppLoad();
      try {
        const widgetSearchClassRequestVM = new WidgetSearchClassRequestViewModel();
        widgetSearchClassRequestVM.toccoFilter = `(pk == long:"${this.courseId}")`;
        const page = new ApiPagingRequestViewModel();
        page.pageSize = 1;
        widgetSearchClassRequestVM.paging = page;
        const response = await this.$service.v1.api.widgetClasses.getWidgetClasses(widgetSearchClassRequestVM);
        [this.course] = response.items.filter((i) => i.id === this.courseId);
        await this.loadDynamicForms();
      } catch (e : any) {
        this.responseError = e;
      }
      this.isLoading = false;
    },
  },
});
