// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';
import Int32LookupSchema from './int32-lookup-schema';

const schema = {
  $id: '/scheduler-schema',
  type: 'object',
  required: ['id'],
  nullable: true,
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    type: {
      ...Int32LookupSchema,
    },
    caption: {
      type: 'string',
      nullable: true,
    },
    details: {
      type: 'string',
      nullable: true,
    },
    sort: {
      type: 'array',
      nullable: true,
      items: {
        type: 'integer',
      },
    },
    startDate: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    endDate: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    tags: {
      type: 'array',
      nullable: true,
      items: {
        type: 'string',
      },
    },
    parentResourceId: {
      type: 'integer',
      nullable: true,
    },
    parentResourceType: {
      ...Int32LookupSchema,
    },
  },
} as IJSONSchema;

export default schema;
