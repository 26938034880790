

































import DataRecord, { isDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import Vue, { PropType } from 'vue';

export interface IAgogisTableColumn {
  field: string,
  value?: string,
  textAlign?: string,
  // eslint-disable-next-line no-unused-vars
  format?: (value: any) => string
}

export default Vue.extend({
  name: 'AgogisTable',
  props: {
    value: { type: Array as PropType<DataRecord<any>[]>, default: () => [] as DataRecord<any>[], validator(values: any[]) { return values?.every((i) => isDataRecord(i)); } },
    columns: { type: Array as PropType<IAgogisTableColumn[]>, default: () => [] as IAgogisTableColumn[] },
    gotoDetailUrl: { type: String, default: undefined },
  },
  data: () => ({

  }),
  methods: {
    getFormattedDisplay(record: DataRecord<any>, column: any) {
      const value = record?.data[column.field];
      if (typeof column.format === 'function') {
        return column.format(value);
      }
      return value;
    },
  },
});
