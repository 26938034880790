
















































































import Vue from 'vue';
import ResponsePagingInfo from '@glittr/frontend-core/src/plugins/servicelayer/responseTypes/responsePagingInfo';
import PListView from '@glittr/frontend-core/src/components/p-list-view/p-list-view.vue';
import LecturePrototypeModuleListResourceViewModel from '../../../../../services/v1/viewModel/resource/LecturePrototypeModuleListResourceViewModel';
import GetPrototypeModulesListRequestViewModel from '../../../../../services/v1/viewModel/request/AgogisCockpit/GetPrototypeModulesListRequestViewModel';

export default Vue.extend({
  auth: true,
  data: () => ({
    availableModules: [] as LecturePrototypeModuleListResourceViewModel[],
    pagination: {
      totalCount: undefined as any,
      pageSize: undefined as any,
      currentPage: undefined as any,
      totalPages: undefined as any,
      previousPage: undefined as any,
      nextPage: undefined as any,
    } as ResponsePagingInfo,
    isLoading: false,
    error: undefined as any,
    selectedYear: Vue.$date.now().year(),
    filter: new GetPrototypeModulesListRequestViewModel().fromModel({}),
    list: {},
    columns: [] as {}[],
    extendedFilterVisible: false,
  }),
  computed: {
    querySearchCriteria() {
      return Vue.$router.currentRoute.query.searchCriteria;
    },
    queryYear() {
      return Vue.$router.currentRoute.query.classYear;
    },
    queryClassId() {
      return Vue.$router.currentRoute.query.classId;
    },
  },
  watch: {
    pagination: {
      deep: true,
      handler() { this.writeRequestToQuery(); },
    },
  },
  async beforeMount() {
    this.setQueryValuesToFilter();
    this.getQueryFromRequest();
  },
  async mounted() {
    this.columns = [
      {
        value: this.$t('page.modulePlanning.table.module'), field: 'moduleName', textAlign: 'left',
      },
      {
        value: this.$t('page.scheduler.columns.class'), field: 'className', textAlign: 'left',
      },
      {
        value: this.$t('page.modulePlanning.table.start'), field: 'moduleStartDate', textAlign: 'left', format: (value:any) => this.$format.localDate(value),
      },
      {
        value: this.$t('page.modulePlanning.table.end'), field: 'moduleEndDate', textAlign: 'left', format: (value:any) => this.$format.localDate(value),
      },
      {
        value: this.$t('page.modulePlanning.table.numberOfRequiredReferents'), field: 'countOfRequiredLecturers', textAlign: 'right',
      },
      {
        value: this.$t('page.modulePlanning.table.numberOfProvisionalClasses'), field: 'countOfProvisionalClasses', textAlign: 'right',
      },
      {
        value: this.$t('page.modulePlanning.table.numberOfReservedClasses'), field: 'countOfReservedClasses', textAlign: 'right',
      },
      {
        value: this.$t('page.modulePlanning.table.numberOfConfirmedClasses'), field: 'countOfConfirmedClasses', textAlign: 'right',
      },
      {
        value: this.$t('page.modulePlanning.table.completed'), field: 'completed', textAlign: 'center',
      },
    ];
  },
  methods: {
    gotoDetail(entry: {moduleId: any, classId: any, classStartYear: any}) {
      this.$router.push({
        path: '/agogis/cockpit/schedule-planning/modules/detail',
        query: {
          moduleid: entry.moduleId,
          classid: entry.classId,
          year: entry.classStartYear,
        },
      });
    },
    refresh() {
      const listView = this.$refs.ordersList as InstanceType<typeof PListView>;
      listView.populateList();
    },
    getQueryFromRequest() {
      const queryParams = new GetPrototypeModulesListRequestViewModel();
      queryParams.fromQuery(this.$routerUtils.getQueryParams());
      this.filter.searchCriteria = queryParams.searchCriteria;
      this.filter.classId = queryParams.classId;

      this.pagination.currentPage = queryParams['paging.CurrentPage']!;
      this.pagination.pageSize = queryParams['paging.PageSize']!;
    },
    clearAdvancedFilter() {
      this.filter.classId = undefined as number | undefined;
    },
    completePlanning() {
      this.$dialog.notify('Not implemented', 'This function is not implemented yet');
    },
    sendNotification() {
      this.$dialog.notify('Not implemented', 'This function is not implemented yet');
    },
    setQueryValuesToFilter() {
      const queryParams = this.$routerUtils.getQueryParams();
      if (queryParams.classYear) {
        this.selectedYear = Number.parseInt(queryParams.year, 10);
      }
      this.filter.fromQuery(queryParams);
    },
    onYearChange() {
      this.clearAdvancedFilter();
      this.onFilterInput();
    },
    onFilterInput() {
      this.pagination.currentPage = 1;
      this.$debounce(() => {
        this.writeRequestToQuery();
        this.refresh();
      }, 500, this)();
    },
    writeRequestToQuery() {
      this.filter['paging.CurrentPage'] = this.pagination.currentPage;
      this.filter['paging.PageSize'] = this.pagination.pageSize;
      this.filter['paging.IncludeCount'] = true;
      this.$routerUtils.updateQueryParams({ ...this.filter.toQuery(), year: this.selectedYear }, { preserve: false });
    },
    async populateData(data: any) {
      const userId = await this.$service.v2.api.me.getUserId();
      this.filter.userId = userId;
      this.filter['paging.CurrentPage'] = data.query['paging.CurrentPage'];
      this.filter['paging.PageSize'] = data.query['paging.PageSize'];
      this.filter['paging.IncludeCount'] = true;
      const response = await this.$service.api.agogisCockpit.getPrototypeModulesList(this.selectedYear, this.filter); // zusätzliches attribut isprototype = true
      response.items = response.items.map((i) => ({ ...i.toDTO() })) as any[];
      return response;
    },
  },
});
