// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import AssignLessonsRequestViewModel from '../../viewModel/resource/AssignLessonsRequestViewModel';
import Int64LookupResourceListResultViewModel from '../../viewModel/resource/Int64LookupResourceListResultViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async updateLessonAssignment(agogisUserId: number, lectureLessonStatusId: number, model: AssignLessonsRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-lesson-assignment'] ?? 'LecturerLessonAssignments/{agogisUserId}/Status/{lectureLessonStatusId}';
    endpointPath = endpointPath.replace(/\{agogisUserId\}/g, `${agogisUserId}`);
    endpointPath = endpointPath.replace(/\{lectureLessonStatusId\}/g, `${lectureLessonStatusId}`);
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async moveAssignmentToLessonAsync(id: number, lessonId: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-move-assignment-to-lesson-async'] ?? 'LecturerLessonAssignments/{id}/MoveToLesson/{lessonId}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    endpointPath = endpointPath.replace(/\{lessonId\}/g, `${lessonId}`);
    const response = await service.post<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async assignLessons(agogisUserId: number, model: AssignLessonsRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-assign-lessons'] ?? 'LecturerLessonAssignments/{agogisUserId}/Assign';
    endpointPath = endpointPath.replace(/\{agogisUserId\}/g, `${agogisUserId}`);
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async confirmLessons(agogisUserId: number, model: AssignLessonsRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-confirm-lessons'] ?? 'LecturerLessonAssignments/{agogisUserId}/Confirm';
    endpointPath = endpointPath.replace(/\{agogisUserId\}/g, `${agogisUserId}`);
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async declineLessons(agogisUserId: number, model: AssignLessonsRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-decline-lessons'] ?? 'LecturerLessonAssignments/{agogisUserId}/Decline';
    endpointPath = endpointPath.replace(/\{agogisUserId\}/g, `${agogisUserId}`);
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async getLessonLecturerAssignmentStatusLookup(config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-lesson-lecturer-assignment-status-lookup'] ?? 'LecturerLessonAssignments/Status/Lookup';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new Int64LookupResourceListResultViewModel().fromDTO(dto);
  },
});
