// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import UpdateUserRequestModel from '../../model/update-user-request-model';
import UpdateUserRequestDTO from '../dto/update-user-request-dto';
import dtoSchema from '../schemas/update-user-request-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class UpdateUserRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<UpdateUserRequestDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.creatorId = data.creatorId;
      this.creatorName = data.creatorName ?? undefined;
      this.created = data.created;
      this.modifierId = data.modifierId ?? undefined;
      this.modifierName = data.modifierName ?? undefined;
      this.modified = data.modified ?? undefined;
      this.email = data.email ?? undefined;
      this.userName = data.userName ?? undefined;
      this.gdprConsentDate = data.gdprConsentDate ?? undefined;
      this.gdprConsentVersion = data.gdprConsentVersion ?? undefined;
      this.verificationCode = data.verificationCode ?? undefined;
      this.verificationCodeSentOn = data.verificationCodeSentOn ?? undefined;
      this.verificationCodeValidUntil = data.verificationCodeValidUntil ?? undefined;
      this.verifiedOn = data.verifiedOn ?? undefined;
      this.statusId = data.statusId;
      this.appHostRoleId = data.appHostRoleId;
      this.userLanguage = data.userLanguage ?? undefined;
      this.userCulture = data.userCulture ?? undefined;
      this.registrationKey = data.registrationKey ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  creatorId?: number;
  /**
  */
  creatorName?: string;
  /**
  * @type {date-time}
  */
  created?: string;
  /**
  * @type {int64}
  */
  modifierId?: number;
  /**
  */
  modifierName?: string;
  /**
  * @type {date-time}
  */
  modified?: string;
  /**
  */
  email?: string;
  /**
  */
  userName?: string;
  /**
  * @type {date-time}
  */
  gdprConsentDate?: string;
  /**
  * @type {int32}
  */
  gdprConsentVersion?: number;
  /**
  */
  verificationCode?: string;
  /**
  * @type {date-time}
  */
  verificationCodeSentOn?: string;
  /**
  * @type {date-time}
  */
  verificationCodeValidUntil?: string;
  /**
  * @type {date-time}
  */
  verifiedOn?: string;
  /**
  * @type {int32}
  */
  statusId?: 0 | 1 | 2 | 3; // UserStatusDTO
  /**
  * @type {int64}
  */
  appHostRoleId?: number;
  /**
  */
  userLanguage?: string;
  /**
  */
  userCulture?: string;
  /**
  */
  registrationKey?: string;

  static toModel(dto: DeepPartial<UpdateUserRequestDTO>): UpdateUserRequestModel;
  static toModel(dto: DeepPartial<UpdateUserRequestDTO> | undefined | null): UpdateUserRequestModel | undefined;
  static toModel(dto: DeepPartial<UpdateUserRequestDTO> | undefined | null): UpdateUserRequestModel | undefined {
    return dto ? new UpdateUserRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<UpdateUserRequestModel> | UpdateUserRequestModel): UpdateUserRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      creatorId: unwrapped.creatorId,
      creatorName: unwrapped.creatorName,
      created: unwrapped.created,
      modifierId: unwrapped.modifierId,
      modifierName: unwrapped.modifierName,
      modified: unwrapped.modified,
      email: unwrapped.email,
      userName: unwrapped.userName,
      gdprConsentDate: unwrapped.gdprConsentDate,
      gdprConsentVersion: unwrapped.gdprConsentVersion,
      verificationCode: unwrapped.verificationCode,
      verificationCodeSentOn: unwrapped.verificationCodeSentOn,
      verificationCodeValidUntil: unwrapped.verificationCodeValidUntil,
      verifiedOn: unwrapped.verifiedOn,
      statusId: unwrapped.statusId,
      appHostRoleId: unwrapped.appHostRoleId,
      userLanguage: unwrapped.userLanguage,
      userCulture: unwrapped.userCulture,
      registrationKey: unwrapped.registrationKey,
    } as UpdateUserRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for UpdateUserRequestModel, can not map to UpdateUserRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
