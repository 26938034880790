// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetClassesRequestViewModel from '../../viewModel/request/Classes/GetClassesRequestViewModel';
import LookupLecturerClassesRequestViewModel from '../../viewModel/request/Classes/LookupLecturerClassesRequestViewModel';
import ClassListResourcePagingResultViewModel from '../../viewModel/resource/ClassListResourcePagingResultViewModel';
import Int32LookupResourceListResultViewModel from '../../viewModel/resource/Int32LookupResourceListResultViewModel';
import Int64LookupResourceListResultViewModel from '../../viewModel/resource/Int64LookupResourceListResultViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getClasses(request: GetClassesRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-classes'] ?? 'Classes';
    const response = await service.get<any>(endpointPath, {
      query: {
        classStartYear: requestDTO.classStartYear,
        periodFrom: requestDTO.periodFrom,
        periodTo: requestDTO.periodTo,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new ClassListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async lookupYears(config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-lookup-years'] ?? 'Classes/Years/Lookup';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new Int32LookupResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async lookupLecturerClasses(classYear: number, request: LookupLecturerClassesRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-lookup-lecturer-classes'] ?? 'Classes/Lookup/ClassYear/{classYear}';
    endpointPath = endpointPath.replace(/\{classYear\}/g, `${classYear}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        lectureLessonStatusId: requestDTO.lectureLessonStatusId,
        userId: requestDTO.userId,
        lecturerId: requestDTO.lecturerId,
        primaryLeadLecturerId: requestDTO.primaryLeadLecturerId,
        isPrototype: requestDTO.isPrototype,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new Int64LookupResourceListResultViewModel().fromDTO(dto);
  },
});
