


















































import Vue from 'vue';
import DashboardPage from './dashboard.vue.model';

export default Vue.extend({
  auth: true,
  data: () => ({
    page: new DashboardPage(),
    visible: true,
  }),
  async mounted() {
    this.page.initialize();
  },
  methods: {
    gotoScheduler() {
      this.$router.push('/agogis/cockpit/agenda/?tab=0');
    },
    gotoLessons() {
      this.$router.push('/agogis/cockpit/schedule-planning/lessons');
    },
  },

});
