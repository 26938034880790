
























































import Vue from 'vue';
import ApiPagingRequestViewModel from '../../../services/v1/viewModel/resource/ApiPagingRequestViewModel';
import WidgetClassListResourceViewModel from '../../../services/v1/viewModel/resource/WidgetClassListResourceViewModel';
import WidgetSearchClassRequestViewModel from '../../../services/v1/viewModel/resource/WidgetSearchClassRequestViewModel';

export default Vue.extend({
  layout: 'layout-widget',
  data: () => ({
    courses: [] as WidgetClassListResourceViewModel[],
    searchTerm: '' as string,
    searchTag: '' as string,
    isLoading: true as boolean,
    isTermineVisible: [] as boolean[],
  }),
  computed: {
    toccoFilter(): string {
      return this.$config.values['widget-toccofilter'] ?? '1==1';
    },
    pageSize():number {
      if (this.$config.values['widget-pagesize'] !== undefined) { return this.$config.values['widget-pagesize']; }
      return 10;
    },
    isResultAvailable():boolean {
      if (this.courses.length === 0) { return false; }
      return true;
    },
  },
  async mounted() {
    const qp = this.$routerUtils.getQueryParams();
    if (qp.search !== undefined) {
      this.searchTerm = qp.search;
    }
    if (qp.tag !== undefined) {
      this.searchTag = qp.tag;
    }
    this.doSearch();
  },
  methods: {
    async onSearchInput() {
      this.$debounce(this.doSearch, 500, this)();
    },
    async clearSearch() {
      this.searchTerm = '';
      this.doSearch();
    },
    async doSearch() {
      try {
        this.isLoading = true;
        const widgetSearchClassRequestVM = new WidgetSearchClassRequestViewModel();
        widgetSearchClassRequestVM.searchCriteria = this.searchTerm;
        widgetSearchClassRequestVM.searchTag = this.searchTag;
        widgetSearchClassRequestVM.toccoFilter = this.toccoFilter;
        const page = new ApiPagingRequestViewModel();
        page.pageSize = this.pageSize;
        widgetSearchClassRequestVM.paging = page;
        const response = await this.$service.api.widgetClasses.getWidgetClasses(widgetSearchClassRequestVM);
        this.courses = response.items;
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    toggleTermine(id:number) {
      this.$set(this.isTermineVisible, id, !this.isTermineVisible[id]);
    },
  },
});
