// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import WidgetSearchClassInformationRequestViewModel from '../../../../viewModel/resource/WidgetSearchClassInformationRequestViewModel';
import WidgetSearchClassInformationRequestDTO from '../../dto/resource/WidgetSearchClassInformationRequestDTO';

export default abstract class WidgetSearchClassInformationRequestModel extends BaseModel<WidgetSearchClassInformationRequestDTO> {
  /**
  */
  get classTypeKey() { return this.dto.classTypeKey; }
  set classTypeKey(value) { this.dto.classTypeKey = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.classTypeKey = qs.classtypekey;
  }
}
