// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetResourceTypesRequestModel from '../../model/get-resource-types-request-model';
import GetSchedulerByClassesRequestModel from '../../model/get-scheduler-by-classes-request-model';
import GetSchedulerByReferentsRequestModel from '../../model/get-scheduler-by-referents-request-model';
import GetSchedulerByRoomsRequestModel from '../../model/get-scheduler-by-rooms-request-model';
import GetSchedulerByLessonsRequestModel from '../../model/get-scheduler-by-lessons-request-model';
import GetSchedulerByAppointmentsRequestModel from '../../model/get-scheduler-by-appointments-request-model';
import Int32LookupListResultModel from '../../model/int32-lookup-list-result-model';
import SchedulerListResultModel from '../../model/scheduler-list-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async getResourceTypes(request: GetResourceTypesRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-resource-types'] ?? 'SchedulerResources/ResourceTypes';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Int32LookupListResultModel.toModel(dto));
  },
  /**  */
  async getSchedulerByClasses(request: GetSchedulerByClassesRequestModel, config?: RequestConfig) {
    const requestDTO = GetSchedulerByClassesRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-scheduler-by-classes'] ?? 'SchedulerResources/ByClasses';
    const response = await service.get<any>(endpointPath, {
      query: {
        startTime: requestDTO.startTime,
        endTime: requestDTO.endTime,
        selectedResourceTypes: requestDTO.selectedResourceTypes,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(SchedulerListResultModel.toModel(dto));
  },
  /**  */
  async getSchedulerByReferents(request: GetSchedulerByReferentsRequestModel, config?: RequestConfig) {
    const requestDTO = GetSchedulerByReferentsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-scheduler-by-referents'] ?? 'SchedulerResources/ByReferents';
    const response = await service.get<any>(endpointPath, {
      query: {
        startTime: requestDTO.startTime,
        endTime: requestDTO.endTime,
        selectedResourceTypes: requestDTO.selectedResourceTypes,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(SchedulerListResultModel.toModel(dto));
  },
  /**  */
  async getSchedulerByRooms(request: GetSchedulerByRoomsRequestModel, config?: RequestConfig) {
    const requestDTO = GetSchedulerByRoomsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-scheduler-by-rooms'] ?? 'SchedulerResources/ByRooms';
    const response = await service.get<any>(endpointPath, {
      query: {
        startTime: requestDTO.startTime,
        endTime: requestDTO.endTime,
        selectedResourceTypes: requestDTO.selectedResourceTypes,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(SchedulerListResultModel.toModel(dto));
  },
  /**  */
  async getSchedulerByLessons(request: GetSchedulerByLessonsRequestModel, config?: RequestConfig) {
    const requestDTO = GetSchedulerByLessonsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-scheduler-by-lessons'] ?? 'SchedulerResources/ByLessons';
    const response = await service.get<any>(endpointPath, {
      query: {
        startTime: requestDTO.startTime,
        endTime: requestDTO.endTime,
        selectedResourceTypes: requestDTO.selectedResourceTypes,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(SchedulerListResultModel.toModel(dto));
  },
  /**  */
  async getSchedulerByAppointments(request: GetSchedulerByAppointmentsRequestModel, config?: RequestConfig) {
    const requestDTO = GetSchedulerByAppointmentsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-scheduler-by-appointments'] ?? 'SchedulerResources/ByAppointments';
    const response = await service.get<any>(endpointPath, {
      query: {
        startTime: requestDTO.startTime,
        endTime: requestDTO.endTime,
        selectedResourceTypes: requestDTO.selectedResourceTypes,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(SchedulerListResultModel.toModel(dto));
  },
});
