// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/get-lecture-lesson-history-paged-list-request-schema',
  type: 'object',
  required: ['lectureLessonId'],
  properties: {
    lectureLessonId: {
      type: 'integer',
    },
    lecturerId: {
      type: 'integer',
    },
    from: {
      type: 'string',
      format: 'date-time',
    },
    to: {
      type: 'string',
      format: 'date-time',
    },
    searchCriteria: {
      type: 'string',
    },
    'paging.CurrentPage': {
      type: 'integer',
    },
    'paging.PageSize': {
      type: 'integer',
    },
    'paging.IncludeCount': {
      type: 'boolean',
    },
    'paging.NoPaging': {
      type: 'boolean',
    },
  },
} as IJSONSchema;

export default schema;
