import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import Vue from 'vue';
import LectureWorkloadSummaryStatusItemModel from '../../../services/v2/model/lecture-workload-summary-status-item-model';
import LookupWorkloadSummaryRequestModel from '../../../services/v2/model/lookup-workload-summary-request-model';

export default class DashboardPage extends PageBase {
  isLoading = true;
  servicesInstance = Vue.$service;
  workloadSummaryDataSource = new DataSource({
    selectCommand: async () => {
      const isEL = (await this.servicesInstance.v2.api.me.getAmIPlanningAdmin());
      if (!isEL) {
        return this.loadWorkloadSummary();
      }
      return Promise.resolve(null);
    },
  });

  async initialize(): Promise<void> {
    await this.workloadSummaryDataSource.select();
    this.isLoading = false;
  }
  /** All workload parts, including selected workload */
  get workloadBreakdown(): LectureWorkloadSummaryStatusItemModel[] {
    return [
      ...(this.workloadSummaryDataSource.data?.data.summaryItemsByStatus ?? []),
    ];
  }
  async loadWorkloadSummary() {
    this.isLoading = true;
    const req = new LookupWorkloadSummaryRequestModel();
    req.id = this.servicesInstance.v2.api.me.getAgogisUserId();
    req.year = new Date().getFullYear();
    const resp = await this.servicesInstance.v2.api.agogisCockpit.lookupWorkloadSummary(req);
    resp?.data.summaryItemsByStatus?.forEach((i) => { i.totalDays = i.totalDays ?? 0; });
    return resp;
  }
}
