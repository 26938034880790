// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import FormFieldItemSchema from './form-field-item-schema';

const schema = {
  $id: '/form-field-schema',
  type: 'object',
  required: ['order'],
  nullable: true,
  properties: {
    id: {
      type: 'string',
      nullable: true,
    },
    caption: {
      type: 'string',
      nullable: true,
    },
    order: {
      type: 'integer',
    },
    isRequired: {
      type: 'boolean',
    },
    inputType: {
      enum: [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
      ],
    },
    picklistItems: {
      type: 'array',
      nullable: true,
      items: { ...FormFieldItemSchema },
    },
    defaultValue: {
      type: 'string',
      nullable: true,
    },
    items: {
      type: 'array',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
