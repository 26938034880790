// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetWidgetClassesRequestModel from '../../model/get-widget-classes-request-model';
import GetWidgetRegistrationMarketingQuestionsRequestModel from '../../model/get-widget-registration-marketing-questions-request-model';
import GetWidgetSubjectAreasRequestModel from '../../model/get-widget-subject-areas-request-model';
import GetWidgetClassRegistrationQuestionsRequestModel from '../../model/get-widget-class-registration-questions-request-model';
import WidgetClassRegisterRequestRequestModel from '../../model/widget-class-register-request-request-model';
import GetWidgetClassInfoRequestModel from '../../model/get-widget-class-info-request-model';
import DownloadPdfRequestModel from '../../model/download-pdf-request-model';
import WidgetClassListPagingResultModel from '../../model/widget-class-list-paging-result-model';
import FormFieldListResultModel from '../../model/form-field-list-result-model';
import SubjectAreaListResultModel from '../../model/subject-area-list-result-model';
import WidgetClassInformationModel from '../../model/widget-class-information-model';

export default (service: Servicelayer) => ({
  /**  */
  async getWidgetClasses(request: GetWidgetClassesRequestModel, config?: RequestConfig) {
    const requestDTO = GetWidgetClassesRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-widget-classes'] ?? 'WidgetClasses';
    const response = await service.post<any>(endpointPath, {
      body: {
        searchCriteria: requestDTO.searchCriteria,
        paging: requestDTO.paging,
        toccoFilter: requestDTO.toccoFilter,
        searchTag: requestDTO.searchTag,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(WidgetClassListPagingResultModel.toModel(dto));
  },
  /**  */
  async getWidgetRegistrationMarketingQuestions(request: GetWidgetRegistrationMarketingQuestionsRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-widget-registration-marketing-questions'] ?? 'WidgetClasses/RegistrationMarketingQuestions';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(FormFieldListResultModel.toModel(dto));
  },
  /**  */
  async getWidgetSubjectAreas(request: GetWidgetSubjectAreasRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-widget-subject-areas'] ?? 'WidgetClasses/SubjectAreas';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(SubjectAreaListResultModel.toModel(dto));
  },
  /**  */
  async getWidgetClassRegistrationQuestions(request: GetWidgetClassRegistrationQuestionsRequestModel, config?: RequestConfig) {
    const requestDTO = GetWidgetClassRegistrationQuestionsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-widget-class-registration-questions'] ?? 'WidgetClasses/{id}/RegistrationQuestions';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(FormFieldListResultModel.toModel(dto));
  },
  /**  */
  async widgetClassRegisterRequest(request: WidgetClassRegisterRequestRequestModel, config?: RequestConfig) {
    const requestDTO = WidgetClassRegisterRequestRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-widget-class-register-request'] ?? 'WidgetClasses/{id}/Register';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.post<any>(endpointPath, {
      body: {
        eventId: requestDTO.eventId,
        contactAddress: requestDTO.contactAddress,
        invoiceAddress: requestDTO.invoiceAddress,
        correspondenceAddress: requestDTO.correspondenceAddress,
        registrationAnswers: requestDTO.registrationAnswers,
        employmentPosition: requestDTO.employmentPosition,
        employmentArea: requestDTO.employmentArea,
        employmentGradePercent: requestDTO.employmentGradePercent,
        employmentStartDate: requestDTO.employmentStartDate,
        comment: requestDTO.comment,
        marketingAnswers: requestDTO.marketingAnswers,
        requestSummary: requestDTO.requestSummary,
        acceptNewsletter: requestDTO.acceptNewsletter,
        acceptGdpr: requestDTO.acceptGdpr,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async getWidgetClassInfo(request: GetWidgetClassInfoRequestModel, config?: RequestConfig) {
    const requestDTO = GetWidgetClassInfoRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-widget-class-info'] ?? 'WidgetClasses/ClassInformation';
    const response = await service.post<any>(endpointPath, {
      body: {
        classTypeKey: requestDTO.classTypeKey,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(WidgetClassInformationModel.toModel(dto));
  },
  /**  */
  async downloadPdf(request: DownloadPdfRequestModel, config?: RequestConfig) {
    const requestDTO = DownloadPdfRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-download-pdf'] ?? 'WidgetClasses/DownloadPdf';
    const response = await service.post<any>(endpointPath, {
      responseType: 'blob',
      body: {
        classTypeKey: requestDTO.classTypeKey,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(new Blob([result], { type: 'application/octet-stream' }));
  },
});
