import DataRecord from '@glittr/frontend-core/src/core/v2/data/data-record';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import LectureLessonListModel from './lecture-lesson-list-model';

export default class ModuleListResourceModel extends ServiceModelBase {
  constructor(data: {id?:number,caption?:string}) {
    super();
    if (data) {
      this.id = data.id?? -1;
      this.caption = data.caption ?? undefined;
    }

  }

  id: number = -1;

  items = new DataSource({
    selectCommand: async () => [] as DataRecord<LectureLessonListModel>[],
  });
  caption?: string;

  get anyLessonSelected() { return (this.items.data?.length ?? 0) && !!this.items.data?.find((i) => i.isSelected); }
  get allLessonsSelected() {
    return (this.items.data?.length ?? 0) > 0 && this.items.data?.reduce((prev, curr) => prev && curr.isSelected, true);
  }
  set allLessonsSelected(value) {
    this.items.data?.forEach((i) => {
      i.isSelected = value!;
    });
  }
}
