// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ContactSalutationTypeResourceViewModel from '../../../../viewModel/resource/ContactSalutationTypeResourceViewModel';
import ContactAddressResourceViewModel from '../../../../viewModel/resource/ContactAddressResourceViewModel';
import ContactAddressResourceDTO from '../../dto/resource/ContactAddressResourceDTO';

export default abstract class ContactAddressResourceModel extends BaseModel<ContactAddressResourceDTO> {
  /**
  */
  get isCompany() { return this.dto.isCompany; }
  set isCompany(value) { this.dto.isCompany = value; }
  /**
  */
  get companyName() { return this.dto.companyName; }
  set companyName(value) { this.dto.companyName = value; }
  /**
  * @type {int32}
  */
  get salutation() { return this.dto.salutation; }
  set salutation(value) { this.dto.salutation = value; }
  /**
  */
  get firstName() { return this.dto.firstName; }
  set firstName(value) { this.dto.firstName = value; }
  /**
  */
  get lastName() { return this.dto.lastName; }
  set lastName(value) { this.dto.lastName = value; }
  /**
  * @type {date-time}
  */
  get dateOfBirth() { return this.dto.dateOfBirth; }
  set dateOfBirth(value) { this.dto.dateOfBirth = value; }
  /**
  */
  get eMail() { return this.dto.eMail; }
  set eMail(value) { this.dto.eMail = value; }
  /**
  */
  get phoneNumber() { return this.dto.phoneNumber; }
  set phoneNumber(value) { this.dto.phoneNumber = value; }
  /**
  */
  get mobilePhoneNumber() { return this.dto.mobilePhoneNumber; }
  set mobilePhoneNumber(value) { this.dto.mobilePhoneNumber = value; }
  /**
  */
  get street() { return this.dto.street; }
  set street(value) { this.dto.street = value; }
  /**
  */
  get addressLine1() { return this.dto.addressLine1; }
  set addressLine1(value) { this.dto.addressLine1 = value; }
  /**
  */
  get poBox() { return this.dto.poBox; }
  set poBox(value) { this.dto.poBox = value; }
  /**
  */
  get postalCode() { return this.dto.postalCode; }
  set postalCode(value) { this.dto.postalCode = value; }
  /**
  */
  get city() { return this.dto.city; }
  set city(value) { this.dto.city = value; }
  /**
  */
  get countryIso2() { return this.dto.countryIso2; }
  set countryIso2(value) { this.dto.countryIso2 = value; }
  /**
  */
  get languageIso2() { return this.dto.languageIso2; }
  set languageIso2(value) { this.dto.languageIso2 = value; }
  /**
  */
  get website() { return this.dto.website; }
  set website(value) { this.dto.website = value; }
  /**
  */
  get subjectArea() { return this.dto.subjectArea; }
  set subjectArea(value) { this.dto.subjectArea = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = qs.isCompany === 'true' ? true : qs.iscompany;
    value = qs.isCompany === 'false' ? false : undefined;
    this.dto.isCompany = value;
    this.dto.companyName = qs.companyname;
    value = Number.parseFloat(qs.salutation);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.salutation = value;
    this.dto.firstName = qs.firstname;
    this.dto.lastName = qs.lastname;
    this.dto.dateOfBirth = qs.dateofbirth;
    this.dto.eMail = qs.email;
    this.dto.phoneNumber = qs.phonenumber;
    this.dto.mobilePhoneNumber = qs.mobilephonenumber;
    this.dto.street = qs.street;
    this.dto.addressLine1 = qs.addressline1;
    this.dto.poBox = qs.pobox;
    this.dto.postalCode = qs.postalcode;
    this.dto.city = qs.city;
    this.dto.countryIso2 = qs.countryiso2;
    this.dto.languageIso2 = qs.languageiso2;
    this.dto.website = qs.website;
  }
}
