



























import Vue from 'vue';
import LookupYearsRequestModel from '../../services/v2/model/lookup-years-request-model';
import Int32LookupModel from '../../services/v2/model/int32-lookup-model';

export default Vue.extend({
  props: {
    value: { default: undefined, type: Number },
    disabled: { default: false, type: Boolean },
    readonly: { default: false, type: Boolean },
    solo: { default: false, type: Boolean },
    label: { default: undefined, type: String },
    required: { default: false, type: Boolean },
    clearable: { default: false, type: Boolean },
    noSteppers: { default: false, type: Boolean },
  },
  data: () => ({
    isLoading: false,
    items: [] as Int32LookupModel[],
    error: undefined as any,
    innerValue: undefined as number | undefined,
  }),
  computed: {
    isNextYearDisabled(): boolean {
      if (this.isLoading || this.disabled) {
        return true;
      }
      const maxYear = Math.max(...this.items.map((i) => i.id!));
      return !!this.innerValue && this.innerValue >= maxYear;
    },
    isPreviousYearDisabled(): boolean {
      if (this.isLoading || this.disabled) {
        return true;
      }
      const minYear = Math.min(...this.items.map((i) => i.id!));
      return !!this.innerValue && this.innerValue <= minYear;
    },
    defaultYear(): number {
      const date = this.$date(`${this.$date.now().year()}-07-31`);
      if (this.$date.now() > date) {
        return this.$date.now().year() + 1;
      }
      return this.$date.now().year();
    },
  },
  watch: {
    value() {
      if (typeof this.value === 'string') {
        this.$emit('input', Number.parseInt(this.value, 10));
      } else {
        this.readValueToInnerValue();
      }
    },
  },
  async beforeMount() {
    if (!this.value) {
      this.$emit('input', this.defaultYear);
    }
    if (this.value) {
      this.readValueToInnerValue();
    }
    this.isLoading = true;
    try {
      const response = await this.$service.v2.api.classes.lookupYears({} as LookupYearsRequestModel, {});
      this.items = response.items.map((x) => x.data);
    } catch (error : any) {
      this.error = error?.message;
    }
    this.isLoading = false;
  },
  methods: {
    readValueToInnerValue() {
      if (typeof this.value === 'string') {
        this.innerValue = Number.parseInt(this.value, 10);
      } else {
        this.innerValue = this.value;
      }
    },
    addYear(amount?: number) {
      if (!this.innerValue) {
        const [firstEntry] = this.items;
        this.innerValue = firstEntry.id!;
        return;
      }
      this.innerValue += (amount ?? 1);
      this.$debounce(() => {
        this.$emit('input', this.innerValue);
      }, 400, this)();
    },
  },
});
