// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ContactIdMappingModel from '../../model/contact-id-mapping-model';
import ContactIdMappingDTO from '../dto/contact-id-mapping-dto';
import dtoSchema from '../schemas/contact-id-mapping-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class ContactIdMappingModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ContactIdMappingDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.creatorId = data.creatorId;
      this.creatorName = data.creatorName ?? undefined;
      this.created = data.created;
      this.modifierId = data.modifierId ?? undefined;
      this.modifierName = data.modifierName ?? undefined;
      this.modified = data.modified ?? undefined;
      this.crmId = data.crmId;
      this.foreignAppSystemId = data.foreignAppSystemId;
      this.foreignId = data.foreignId ?? undefined;
      this.crmStateId = data.crmStateId;
      this.crmStateName = data.crmStateName ?? undefined;
      this.mappingStateId = data.mappingStateId ?? undefined;
      this.mappingStateName = data.mappingStateName ?? undefined;
      this.mappingStateReason = data.mappingStateReason ?? undefined;
      this.currentContactData = data.currentContactData ?? undefined;
      this.previousContactData = data.previousContactData ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  creatorId?: number;
  /**
  */
  creatorName?: string;
  /**
  * @type {date-time}
  */
  created?: string;
  /**
  * @type {int64}
  */
  modifierId?: number;
  /**
  */
  modifierName?: string;
  /**
  * @type {date-time}
  */
  modified?: string;
  /**
  * @type {uuid}
  */
  crmId?: string;
  /**
  * @type {int64}
  */
  foreignAppSystemId?: number;
  /**
  */
  foreignId?: string;
  /**
  * @type {int32}
  */
  crmStateId?: number;
  /**
  */
  crmStateName?: string;
  /**
  * @type {int32}
  */
  mappingStateId?: number;
  /**
  */
  mappingStateName?: string;
  /**
  */
  mappingStateReason?: string;
  /**
  */
  currentContactData?: string;
  /**
  */
  previousContactData?: string;

  static toModel(dto: DeepPartial<ContactIdMappingDTO>): ContactIdMappingModel;
  static toModel(dto: DeepPartial<ContactIdMappingDTO> | undefined | null): ContactIdMappingModel | undefined;
  static toModel(dto: DeepPartial<ContactIdMappingDTO> | undefined | null): ContactIdMappingModel | undefined {
    return dto ? new ContactIdMappingModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ContactIdMappingModel> | ContactIdMappingModel): ContactIdMappingDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      creatorId: unwrapped.creatorId,
      creatorName: unwrapped.creatorName,
      created: unwrapped.created,
      modifierId: unwrapped.modifierId,
      modifierName: unwrapped.modifierName,
      modified: unwrapped.modified,
      crmId: unwrapped.crmId,
      foreignAppSystemId: unwrapped.foreignAppSystemId,
      foreignId: unwrapped.foreignId,
      crmStateId: unwrapped.crmStateId,
      crmStateName: unwrapped.crmStateName,
      mappingStateId: unwrapped.mappingStateId,
      mappingStateName: unwrapped.mappingStateName,
      mappingStateReason: unwrapped.mappingStateReason,
      currentContactData: unwrapped.currentContactData,
      previousContactData: unwrapped.previousContactData,
    } as ContactIdMappingDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ContactIdMappingModel, can not map to ContactIdMappingDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
