// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import LectureLessonListModel from '../../model/lecture-lesson-list-model';
import LectureLessonListDTO from '../dto/lecture-lesson-list-dto';
import dtoSchema from '../schemas/lecture-lesson-list-schema';
import ResourceLinkModel from '../../model/resource-link-model';
import LectureLessonStatusModel from '../../model/lecture-lesson-status-model';

export default abstract class LectureLessonListModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<LectureLessonListDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.lectureClassId = data.lectureClassId ?? undefined;
      this.lectureModuleId = data.lectureModuleId ?? undefined;
      this.moduleName = data.moduleName ?? undefined;
      this.lessonName = data.lessonName ?? undefined;
      this.primaryLeadLecturerId = data.primaryLeadLecturerId ?? undefined;
      this.secondaryLeadLecturerId = data.secondaryLeadLecturerId ?? undefined;
      this.lecturerId = data.lecturerId ?? undefined;
      this.lecturerRoleId = data.lecturerRoleId ?? undefined;
      this.primaryLeadLecturerFirstName = data.primaryLeadLecturerFirstName ?? undefined;
      this.primaryLeadLecturerLastName = data.primaryLeadLecturerLastName ?? undefined;
      this.className = data.className ?? undefined;
      this.lecturerRoleName = data.lecturerRoleName ?? undefined;
      this.lecturerFirstName = data.lecturerFirstName ?? undefined;
      this.lecturerLastName = data.lecturerLastName ?? undefined;
      this.lecturerStatus = data.lecturerStatus ? LectureLessonStatusModel.toModel(data.lecturerStatus) : undefined;
      this.lecturerComment = data.lecturerComment ?? undefined;
      this.lecturerAdminComment = data.lecturerAdminComment ?? undefined;
      this.startTime = data.startTime ?? undefined;
      this.endTime = data.endTime ?? undefined;
      this.moduleStartDate = data.moduleStartDate ?? undefined;
      this.moduleEndDate = data.moduleEndDate ?? undefined;
      this.moduleTotalDuration = data.moduleTotalDuration;
      this.duration = data.duration;
      this.classIsPrototype = data.classIsPrototype;
      this.classStartYear = data.classStartYear;
      this.teamTeaching = data.teamTeaching;
      this.bookingAction = data.bookingAction;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  lectureClassId?: number;
  /**
  * @type {int64}
  */
  lectureModuleId?: number;
  /**
  */
  moduleName?: string;
  /**
  */
  lessonName?: string;
  /**
  * @type {int64}
  */
  primaryLeadLecturerId?: number;
  /**
  * @type {int64}
  */
  secondaryLeadLecturerId?: number;
  /**
  * @type {int64}
  */
  lecturerId?: number;
  /**
  * @type {int64}
  */
  lecturerRoleId?: number;
  /**
  */
  primaryLeadLecturerFirstName?: string;
  /**
  */
  primaryLeadLecturerLastName?: string;
  /**
  */
  className?: string;
  /**
  */
  lecturerRoleName?: string;
  /**
  */
  lecturerFirstName?: string;
  /**
  */
  lecturerLastName?: string;
  /**
  */
  lecturerStatus?: LectureLessonStatusModel;
  /**
  */
  lecturerComment?: string;
  /**
  */
  lecturerAdminComment?: string;
  /**
  * @type {date-time}
  */
  startTime?: string;
  /**
  * @type {date-time}
  */
  endTime?: string;
  /**
  * @type {date-time}
  */
  moduleStartDate?: string;
  /**
  * @type {date-time}
  */
  moduleEndDate?: string;
  /**
  * @type {double}
  */
  moduleTotalDuration?: number;
  /**
  * @type {double}
  */
  duration?: number;
  /**
  */
  classIsPrototype?: boolean;
  /**
  * @type {int32}
  */
  classStartYear?: number;
  /**
  */
  teamTeaching?: boolean;
  /**
  * @type {int32}
  */
  bookingAction?: 0 | 1 | 2 | 4; // LectureLessonBookingActionDTO

  static toModel(dto: DeepPartial<LectureLessonListDTO>): LectureLessonListModel;
  static toModel(dto: DeepPartial<LectureLessonListDTO> | undefined | null): LectureLessonListModel | undefined;
  static toModel(dto: DeepPartial<LectureLessonListDTO> | undefined | null): LectureLessonListModel | undefined {
    return dto ? new LectureLessonListModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<LectureLessonListModel> | LectureLessonListModel): LectureLessonListDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      lectureClassId: unwrapped.lectureClassId,
      lectureModuleId: unwrapped.lectureModuleId,
      moduleName: unwrapped.moduleName,
      lessonName: unwrapped.lessonName,
      primaryLeadLecturerId: unwrapped.primaryLeadLecturerId,
      secondaryLeadLecturerId: unwrapped.secondaryLeadLecturerId,
      lecturerId: unwrapped.lecturerId,
      lecturerRoleId: unwrapped.lecturerRoleId,
      primaryLeadLecturerFirstName: unwrapped.primaryLeadLecturerFirstName,
      primaryLeadLecturerLastName: unwrapped.primaryLeadLecturerLastName,
      className: unwrapped.className,
      lecturerRoleName: unwrapped.lecturerRoleName,
      lecturerFirstName: unwrapped.lecturerFirstName,
      lecturerLastName: unwrapped.lecturerLastName,
      lecturerStatus: unwrapped.lecturerStatus,
      lecturerComment: unwrapped.lecturerComment,
      lecturerAdminComment: unwrapped.lecturerAdminComment,
      startTime: unwrapped.startTime,
      endTime: unwrapped.endTime,
      moduleStartDate: unwrapped.moduleStartDate,
      moduleEndDate: unwrapped.moduleEndDate,
      moduleTotalDuration: unwrapped.moduleTotalDuration,
      duration: unwrapped.duration,
      classIsPrototype: unwrapped.classIsPrototype,
      classStartYear: unwrapped.classStartYear,
      teamTeaching: unwrapped.teamTeaching,
      bookingAction: unwrapped.bookingAction,
    } as LectureLessonListDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for LectureLessonListModel, can not map to LectureLessonListDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
