// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';
import Int64LookupSchema from './int64-lookup-schema';

const schema = {
  $id: '/referent-list-schema',
  type: 'object',
  required: ['id', 'sortOrder', 'employeeNumber'],
  nullable: true,
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    caption: {
      type: 'string',
      nullable: true,
    },
    details: {
      type: 'array',
      nullable: true,
      items: {
        type: 'string',
      },
    },
    group: {
      ...Int64LookupSchema,
    },
    sortOrder: {
      type: 'integer',
    },
    employeeNumber: {
      type: 'integer',
    },
    firstName: {
      type: 'string',
      nullable: true,
    },
    lastName: {
      type: 'string',
      nullable: true,
    },
    fullName: {
      type: 'string',
      nullable: true,
    },
    referentTypes: {
      type: 'array',
      nullable: true,
      items: { ...Int64LookupSchema },
    },
    schedulerResourceType: {
      enum: [
        0,
        1,
        2,
        3,
        4,
        5,
      ],
    },
  },
} as IJSONSchema;

export default schema;
