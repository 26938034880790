
















































































import Vue, { PropType } from 'vue';
import WidgetClassListResourceViewModel from '../services/v1/viewModel/resource/WidgetClassListResourceViewModel';

export default Vue.extend({
  name: 'AgogisWidgetCourseCard',
  props: {
    value: { type: Object as PropType<WidgetClassListResourceViewModel>, default: undefined },
  },
  data: () => ({
    isAppointmentsVisible: false,
  }),
  computed: {
    hasAppointments(): boolean {
      return this.eventTypeConfig?.hasAppointments ?? true;
    },
    eventTypeConfig(): NonNullable<typeof Vue.$config.values['widget-course-event-type-config']>[string] | undefined {
      const eventTypeConfig = this.$config.values['widget-course-event-type-config'];
      if (!this._.isObject(eventTypeConfig)) {
        this.$log.error('Config entry \'widget-course-event-type-config\' wrong type');
        return undefined;
      }
      return eventTypeConfig[this.value.eventType?.toString()!];
    },
    dateSectionText(): string {
      const startDate = this.$format.localDate(this.value.startDate!);
      const endDate = this.$format.localDate(this.value.endDate!);
      const dateString = `${startDate} - ${endDate}`;

      const configText = this.eventTypeConfig?.dateText ?? dateString;
      const displayText = this.$tAlt(configText, configText);
      return displayText;
    },
  },
  methods: {
    toggleAppointments() {
      this.isAppointmentsVisible = !this.isAppointmentsVisible;
    },
  },
});
