import coreRoutes from '@glittr/frontend-core/src/routes';
import Home from './views/pages/index.vue';

export default {
  mode: 'history',
  base: process.env!.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      // Project specific profile page
      path: '/_me/profile',
      name: 'project-profile',
      component: () => import('./views/pages/_me/profile.vue'),
    },
    ...coreRoutes,
    {
      path: '/_widgets/',
      name: 'widgets-index',
      component: () => import('./views/widgets/app/index.vue'),
    },
    {
      path: '/_widgets/nextEvents',
      name: 'widgets-nextEvents',
      component: () => import('./views/widgets/app/widget-nextEvents.vue'),
    },
    {
      path: '/_widgets/nextCourses',
      name: 'widgets-nextCourses',
      component: () => import('./views/widgets/app/widget-nextCourses.vue'),
    },
    {
      path: '/_widgets/courseInfoEvents',
      name: 'widgets-courseInfoEvents',
      component: () => import('./views/widgets/app/widget-courseInfoEvents.vue'),
    },
    {
      path: '/_widgets/searchCourses',
      name: 'widgets-searchCourses',
      component: () => import('./views/widgets/app/widget-searchCourses.vue'),
    },
    {
      path: '/_widgets/registerCourse',
      name: 'widgets-registerCourse',
      component: () => import('./views/widgets/app/widget-registerCourse.vue'),
    },
    {
      path: '/_widgets/courseTextResources',
      name: 'widgets-courseTextResources',
      component: () => import('./views/widgets/app/widget-courseTextResources.vue'),
    },
    {
      path: '/_widgets/poi',
      name: 'widgets-poi',
      component: () => import('./views/widgets/app/widget-poi.vue'),
    },
    {
      path: '/:tenant/:workspace',
      name: 'dashboard',
      component: () => import('./views/pages/app/dashboard.vue'),
    },
    {
      path: '/:tenant/:workspace/settings',
      name: 'settings',
      component: () => import('./views/pages/app/settings.vue'),
    },
    {
      path: '/:tenant/:workspace/agenda/',
      alias: '/:tenant/:workspace/scheduler/',
      name: 'agenda',
      component: () => import('./views/pages/app/agenda/index.vue'),
    },
    {
      path: '/:tenant/:workspace/schedule-planning/modules/detail',
      name: 'kanban',
      component: () => import('./views/pages/app/schedule-planning/module/detail.vue'),
    },
    {
      path: '/:tenant/:workspace/agenda/:tabId',
      alias: '/:tenant/:workspace/scheduler/:tabId',
      name: 'agenda-tab',
      component: () => import('./views/pages/app/agenda/index.vue'),
    },
    {
      path: '/:tenant/:workspace/schedule-planning',
      name: 'schedule-planning-dashboard',
      component: () => import('./views/pages/app/schedule-planning/index.vue'),
    },
    {
      path: '/:tenant/:workspace/schedule-planning/modules',
      name: 'schedule-planning-modules',
      component: () => import('./views/pages/app/schedule-planning/module/list.vue'),
    },
    {
      path: '/:tenant/:workspace/schedule-planning/lessons',
      name: 'schedule-planning-lessons',
      component: () => import('./views/pages/app/schedule-planning/lesson/lesson-list.vue'),
    },
    {
      path: '/:tenant/:workspace/schedule-planning/lessons/:id',
      name: 'schedule-planning-lessons-detail',
      component: () => import('./views/pages/app/schedule-planning/lesson/lesson-detail.vue'),
    },
  ],
};
