// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ClassListModel from '../../model/class-list-model';
import ClassListDTO from '../dto/class-list-dto';
import dtoSchema from '../schemas/class-list-schema';
import ResourceLinkModel from '../../model/resource-link-model';
import Int64LookupModel from '../../model/int64-lookup-model';

export default abstract class ClassListModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ClassListDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.caption = data.caption ?? undefined;
      this.details = data.details ?? undefined;
      this.group = data.group ? Int64LookupModel.toModel(data.group) : undefined;
      this.sortOrder = data.sortOrder;
      this.courseType = data.courseType ? Int64LookupModel.toModel(data.courseType) : undefined;
      this.startDate = data.startDate;
      this.endDate = data.endDate;
      this.leadReferentName = data.leadReferentName ?? undefined;
      this.city = data.city ?? undefined;
      this.status = data.status ? Int64LookupModel.toModel(data.status) : undefined;
      this.schedulerResourceType = data.schedulerResourceType;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  */
  caption?: string;
  /**
  */
  details?: string[];
  /**
  */
  group?: Int64LookupModel;
  /**
  * @type {int32}
  */
  sortOrder?: number;
  /**
  */
  courseType?: Int64LookupModel;
  /**
  * @type {date-time}
  */
  startDate?: string;
  /**
  * @type {date-time}
  */
  endDate?: string;
  /**
  */
  leadReferentName?: string;
  /**
  */
  city?: string;
  /**
  */
  status?: Int64LookupModel;
  /**
  * @type {int32}
  */
  schedulerResourceType?: 0 | 1 | 2 | 3 | 4 | 5; // ResourceTypeDTO

  static toModel(dto: DeepPartial<ClassListDTO>): ClassListModel;
  static toModel(dto: DeepPartial<ClassListDTO> | undefined | null): ClassListModel | undefined;
  static toModel(dto: DeepPartial<ClassListDTO> | undefined | null): ClassListModel | undefined {
    return dto ? new ClassListModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ClassListModel> | ClassListModel): ClassListDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      caption: unwrapped.caption,
      details: unwrapped.details,
      group: unwrapped.group,
      sortOrder: unwrapped.sortOrder,
      courseType: unwrapped.courseType,
      startDate: unwrapped.startDate,
      endDate: unwrapped.endDate,
      leadReferentName: unwrapped.leadReferentName,
      city: unwrapped.city,
      status: unwrapped.status,
      schedulerResourceType: unwrapped.schedulerResourceType,
    } as ClassListDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ClassListModel, can not map to ClassListDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
