














import Vue from 'vue';
import LookupLecturerModulesRequestModel from '../../services/v2/model/lookup-lecturer-modules-request-model';
import GetMeRequestModel from '../../services/v2/model/get-me-request-model';
import Int64LookupModel from '../../services/v2/model/int64-lookup-model';

export default Vue.extend({
  props: {
    value: { default: undefined, type: undefined },
    disabled: { default: false, type: Boolean },
    readonly: { default: false, type: Boolean },
    solo: { default: false, type: Boolean },
    label: { default: undefined, type: String },
    required: { default: false, type: Boolean },
    clearable: { default: false, type: Boolean },
    year: { default: undefined, type: Number },
    showPrototypeClass: { default: false, type: Boolean },
  },
  data: () => ({
    isLoading: false,
    items: [] as Int64LookupModel[],
    error: undefined as any,
  }),
  watch: {
    value: {
      immediate: true,
      handler() {
        if (typeof this.value === 'string') {
          this.$emit('input', Number.parseInt(this.value, 10));
        }
      },
    },
    year: {
      deep: true,
      async handler() {
        await this.loadAppointments();
      },
    },
  },
  async mounted() {
    await this.loadAppointments();
  },
  methods: {
    async loadAppointments() {
      if (!this.year) { return; }
      this.isLoading = true;
      try {
        const request = new LookupLecturerModulesRequestModel({
          classYear: this.year,
          isPrototype: this.showPrototypeClass,
          userId: (await Vue.$service.v2.api.me.getMe({} as GetMeRequestModel)).data.id,
        });
        const response = await this.$service.v2.api.modules.lookupLecturerModules(request);
        this.items = response.items.map((x) => x.data);
      } catch (error : any) {
        this.error = error?.message;
      } finally {
        this.isLoading = false;
      }
    },
  },
});
