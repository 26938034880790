// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import RequestSummaryModel from '../../model/request-summary-model';
import RequestSummaryDTO from '../dto/request-summary-dto';
import dtoSchema from '../schemas/request-summary-schema';
import RequestSummarySectionModel from '../../model/request-summary-section-model';

export default abstract class RequestSummaryModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<RequestSummaryDTO>) {
    super();
    if (data) {
      this.sections = data.sections?.map((i) => RequestSummarySectionModel.toModel(i)) ?? [];
    }
  }
  /**
  */
  sections?: RequestSummarySectionModel[];

  static toModel(dto: DeepPartial<RequestSummaryDTO>): RequestSummaryModel;
  static toModel(dto: DeepPartial<RequestSummaryDTO> | undefined | null): RequestSummaryModel | undefined;
  static toModel(dto: DeepPartial<RequestSummaryDTO> | undefined | null): RequestSummaryModel | undefined {
    return dto ? new RequestSummaryModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<RequestSummaryModel> | RequestSummaryModel): RequestSummaryDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      sections: unwrapped.sections,
    } as RequestSummaryDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for RequestSummaryModel, can not map to RequestSummaryDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
