// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ContactAddressSchema from './contact-address-schema';

const schema = {
  $id: '/contact-correspondence-address-schema',
  type: 'object',
  required: [],
  properties: {
    isDifferent: {
      type: 'boolean',
    },
    contactAddress: {
      ...ContactAddressSchema,
    },
  },
} as IJSONSchema;

export default schema;
