// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import FormFieldModel from '../../model/form-field-model';
import FormFieldDTO from '../dto/form-field-dto';
import dtoSchema from '../schemas/form-field-schema';
import FormFieldItemModel from '../../model/form-field-item-model';

export default abstract class FormFieldModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<FormFieldDTO>) {
    super();
    if (data) {
      this.id = data.id ?? undefined;
      this.caption = data.caption ?? undefined;
      this.order = data.order;
      this.isRequired = data.isRequired;
      this.inputType = data.inputType;
      this.picklistItems = data.picklistItems?.map((i) => FormFieldItemModel.toModel(i)) ?? [];
      this.defaultValue = data.defaultValue ?? undefined;
      this.items = data.items?.map((i) => FormFieldModel.toModel(i)) ?? [];
    }
  }
  /**
  */
  id?: string;
  /**
  */
  caption?: string;
  /**
  * @type {int32}
  */
  order?: number;
  /**
  */
  isRequired?: boolean;
  /**
  * @type {int32}
  */
  inputType?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10; // FormFieldInputTypeDTO
  /**
  */
  picklistItems?: FormFieldItemModel[];
  /**
  */
  defaultValue?: string;
  /**
  */
  items?: FormFieldModel[];

  static toModel(dto: DeepPartial<FormFieldDTO>): FormFieldModel;
  static toModel(dto: DeepPartial<FormFieldDTO> | undefined | null): FormFieldModel | undefined;
  static toModel(dto: DeepPartial<FormFieldDTO> | undefined | null): FormFieldModel | undefined {
    return dto ? new FormFieldModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<FormFieldModel> | FormFieldModel): FormFieldDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      caption: unwrapped.caption,
      order: unwrapped.order,
      isRequired: unwrapped.isRequired,
      inputType: unwrapped.inputType,
      picklistItems: unwrapped.picklistItems,
      defaultValue: unwrapped.defaultValue,
      items: unwrapped.items,
    } as FormFieldDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for FormFieldModel, can not map to FormFieldDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
