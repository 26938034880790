// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import AgogisUserResourceViewModel from '../../../../viewModel/resource/AgogisUserResourceViewModel';
import AgogisUserResourceDTO from '../../dto/resource/AgogisUserResourceDTO';

export default abstract class AgogisUserResourceModel extends BaseModel<AgogisUserResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  * @type {int64}
  */
  get userId() { return this.dto.userId; }
  set userId(value) { this.dto.userId = value; }
  /**
  */
  get firstName() { return this.dto.firstName; }
  set firstName(value) { this.dto.firstName = value; }
  /**
  */
  get lastName() { return this.dto.lastName; }
  set lastName(value) { this.dto.lastName = value; }
  /**
  */
  get fullName() { return this.dto.fullName; }
  set fullName(value) { this.dto.fullName = value; }
  /**
  */
  get email() { return this.dto.email; }
  set email(value) { this.dto.email = value; }
  /**
  */
  get function() { return this.dto.function; }
  set function(value) { this.dto.function = value; }
  /**
  * @type {date-time}
  */
  get functionValidTill() { return this.dto.functionValidTill; }
  set functionValidTill(value) { this.dto.functionValidTill = value; }
  /**
  */
  get userStatus() { return this.dto.userStatus; }
  set userStatus(value) { this.dto.userStatus = value; }
  /**
  */
  get isActive() { return this.dto.isActive; }
  set isActive(value) { this.dto.isActive = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.userid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.userId = value;
    this.dto.firstName = qs.firstname;
    this.dto.lastName = qs.lastname;
    this.dto.fullName = qs.fullname;
    this.dto.email = qs.email;
    this.dto.function = qs.function;
    this.dto.functionValidTill = qs.functionvalidtill;
    this.dto.userStatus = qs.userstatus;
    value = qs.isActive === 'true' ? true : qs.isactive;
    value = qs.isActive === 'false' ? false : undefined;
    this.dto.isActive = value;
  }
}
