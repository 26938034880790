





























import Vue from 'vue';

export default Vue.extend({
  name: 'AgogisToggleSelection',
  props: {
    value: { default: undefined, type: undefined },
    trueLabel: { default: 'core.app.yes', type: undefined },
    falseLabel: { default: 'core.app.no', type: undefined },
    trueValue: { default: undefined, type: undefined },
    falseValue: { default: undefined, type: undefined },
    trueColor: { default: 'positive', type: String },
    falseColor: { default: 'negative', type: String },
    trueDisabled: { default: false, type: Boolean },
    falseDisabled: { default: false, type: Boolean },
    disabled: { default: false, type: Boolean },
    column: { default: true, type: Boolean },
  },
  data: () => ({
    selected: -1,
  }),
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value === (this.trueValue ?? true)) {
          this.selected = 1;
        } else if (this.value === (this.falseValue ?? true)) {
          this.selected = 0;
        }
      },
    },
  },
  methods: {
    onSelect(selected: number) {
      this.selected = selected;
      if (this.selected === 0) {
        this.$emit('input', this.falseValue ?? false);
      } else {
        this.$emit('input', this.trueValue ?? true);
      }
    },
  },
});
