// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import PublicHolidayListModel from '../../model/public-holiday-list-model';
import PublicHolidayListDTO from '../dto/public-holiday-list-dto';
import dtoSchema from '../schemas/public-holiday-list-schema';
import ResourceLinkModel from '../../model/resource-link-model';
import Int64LookupModel from '../../model/int64-lookup-model';

export default abstract class PublicHolidayListModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<PublicHolidayListDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.caption = data.caption ?? undefined;
      this.details = data.details ?? undefined;
      this.group = data.group ? Int64LookupModel.toModel(data.group) : undefined;
      this.sortOrder = data.sortOrder;
      this.isAllDay = data.isAllDay;
      this.startTime = data.startTime ?? undefined;
      this.endTime = data.endTime ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  */
  caption?: string;
  /**
  */
  details?: string[];
  /**
  */
  group?: Int64LookupModel;
  /**
  * @type {int32}
  */
  sortOrder?: number;
  /**
  */
  isAllDay?: boolean;
  /**
  * @type {date-time}
  */
  startTime?: string;
  /**
  * @type {date-time}
  */
  endTime?: string;

  static toModel(dto: DeepPartial<PublicHolidayListDTO>): PublicHolidayListModel;
  static toModel(dto: DeepPartial<PublicHolidayListDTO> | undefined | null): PublicHolidayListModel | undefined;
  static toModel(dto: DeepPartial<PublicHolidayListDTO> | undefined | null): PublicHolidayListModel | undefined {
    return dto ? new PublicHolidayListModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<PublicHolidayListModel> | PublicHolidayListModel): PublicHolidayListDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      caption: unwrapped.caption,
      details: unwrapped.details,
      group: unwrapped.group,
      sortOrder: unwrapped.sortOrder,
      isAllDay: unwrapped.isAllDay,
      startTime: unwrapped.startTime,
      endTime: unwrapped.endTime,
    } as PublicHolidayListDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for PublicHolidayListModel, can not map to PublicHolidayListDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
