// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import FormFieldValueRequestModel from '../../model/form-field-value-request-model';
import FormFieldValueRequestDTO from '../dto/form-field-value-request-dto';
import dtoSchema from '../schemas/form-field-value-request-schema';

export default abstract class FormFieldValueRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<FormFieldValueRequestDTO>) {
    super();
    if (data) {
      this.id = data.id ?? undefined;
      this.values = data.values ?? undefined;
    }
  }
  /**
  */
  id?: string;
  /**
  */
  values?: string[];

  static toModel(dto: DeepPartial<FormFieldValueRequestDTO>): FormFieldValueRequestModel;
  static toModel(dto: DeepPartial<FormFieldValueRequestDTO> | undefined | null): FormFieldValueRequestModel | undefined;
  static toModel(dto: DeepPartial<FormFieldValueRequestDTO> | undefined | null): FormFieldValueRequestModel | undefined {
    return dto ? new FormFieldValueRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<FormFieldValueRequestModel> | FormFieldValueRequestModel): FormFieldValueRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      values: unwrapped.values,
    } as FormFieldValueRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for FormFieldValueRequestModel, can not map to FormFieldValueRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
