/* eslint-disable no-underscore-dangle */
import DataEvent from '@glittr/frontend-core/src/core/v2/data/data-event';
import DataRecord from '@glittr/frontend-core/src/core/v2/data/data-record';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import Vue from 'vue';
import LectureLessonListModel from '../../../../../services/v2/model/lecture-lesson-list-model';
import LectureWorkloadLessonListModel from '../../../../../services/v2/model/lecture-workload-summary-item-model';
import LectureWorkloadSummaryStatusItemModel from '../../../../../services/v2/model/lecture-workload-summary-status-item-model';
import LecturerWorkloadSummaryModel from '../../../../../services/v2/model/lecturer-workload-summary-model';
import LookupWorkloadSummaryRequestModel from '../../../../../services/v2/model/lookup-workload-summary-request-model';
import SearchLessonsRequestModel from '../../../../../services/v2/model/search-lessons-request-model';
import UpdateLessonAssignmentRequestModel from '../../../../../services/v2/model/update-lesson-assignment-request-model';
import AssignLessonsRequestModel from '../../../../../services/v2/model/assign-lessons-request-model';
import DeclineLessonsRequestModel from '../../../../../services/v2/model/decline-lessons-request-model';
import ConfirmLessonsRequestModel from '../../../../../services/v2/model/confirm-lessons-request-model';

// eslint-disable-next-line no-use-before-define
type FilterType = LecturerBookingSummaryView['filter'];

export default class LecturerBookingSummaryView {
  selectedLessons: DataRecord<LectureLessonListModel>[] = [];
  comments:Array<string> = [];
  adminComments:Array<string> = [];
  bookingFormSubmittedEvent = new DataEvent();
  bookingFormCanceledEvent = new DataEvent();
  isWorking = false;
  bookingFormMessage?: string = undefined;
  bookingFormMessageType: 'info' | 'error' | 'warning' = 'info';

  assignBookingDataSource = new DataSource({
    selectCommand: () => this.assignBooking(),
  });
  confirmBookingDataSource = new DataSource({
    selectCommand: () => this.confirmBooking(),
  });
  declineBookingDataSource = new DataSource({
    selectCommand: () => this.declineBooking(),
  });
  updateBookingDataSource = new DataSource({
    selectCommand: () => this.updateBooking(),
  });
  updateCommentDataSource = new DataSource({
    selectCommand: () => this.updateComment(),
  });

  get isBookingFormLoading() {
    return this.isWorking
    || this.assignBookingDataSource.isLoading
    || this.confirmBookingDataSource.isLoading
    || this.declineBookingDataSource.isLoading
    || this.updateCommentDataSource.isLoading
    || this.updateBookingDataSource.isLoading;
  }

  filter: {
    classYear?: number,
    classIds?: number[],
    lecturerId?: number,
  } = {};

  workloadSummaryDataSource = new DataSource({
    selectCommand: () => this.loadWorkloadSummary(),
  });
  bookingForm : Partial<UpdateLessonAssignmentRequestModel> = new UpdateLessonAssignmentRequestModel();

  constructor(filterChangeEvent: DataEvent<[SearchLessonsRequestModel]>, lessonSelectionEvent: DataEvent<[DataRecord<LectureLessonListModel>[]]>) {
    filterChangeEvent.addEventListener((filter) => this.onFilterChange(filter));
    lessonSelectionEvent.addEventListener((selectedLessons) => this.onLessonSelectionChange(selectedLessons));
    this.onLessonSelectionChange([]);
  }

  onLessonSelectionChange(selection: DataRecord<LectureLessonListModel>[]) {
    if (selection.length === 0) {
      this.filter.lecturerId = undefined;
      this.workloadSummaryDataSource.select();
      this.selectedLessons = [];
      this.clearBookingForm();
    } else {
      this.selectedLessons = selection;
      this.comments = selection.map((x) => x.data.lecturerComment ?? '');
      this.adminComments = selection.map((x) => x.data.lecturerAdminComment ?? '');
      this.bookingForm.selectedLessonIds = this.selectedLessons.map((i) => i.data!.id!);
      const lectureLessonStatusIdDistincted = [...new Set(selection.map((x) => x.data.lecturerStatus?.id))];

      this.bookingForm.lectureLessonStatusId = lectureLessonStatusIdDistincted.length === 1 ? lectureLessonStatusIdDistincted[0] : undefined;
      const lectureIds = this.selectedLessons
        .map((i) => i.data.lecturerId)
        .filter((value, index, array) => array.indexOf(value) === index);
      if (lectureIds.length === 1) {
        const distinctedLectureId = lectureIds[0];
        this.filter.lecturerId = distinctedLectureId;
      }
      this.filter.lecturerId = this.filter.lecturerId || this.bookingForm.agogisUserId;
      this.onFilterChange(this.filter);
    }
    this.bookingFormMessage = undefined;
    this.bookingFormMessageType = 'info';
    if (!this.hasSelectedLessons) {
      this.bookingFormMessage = 'Keine Änderung möglich. Bitte selektieren Sie in der Liste mindestens einen Eintrag';
      this.bookingFormMessageType = 'info';
    } else if (this.lessonSelectionHasNoBookingAction) {
      this.bookingFormMessage = 'Einträge in dieser Auswahl sind bei der Einsatzplanung in Bearbeitung und können von Ihnen nicht weiter bearbeitet werden';
      this.bookingFormMessageType = 'warning';
    } else if (this.invalidLessonSelection) {
      this.bookingFormMessage = 'Die Einsatz-Stati sind nicht kompatibel und können nicht gemeinsam aktualisiert werden';
      this.bookingFormMessageType = 'warning';
    }

    this.workloadSummaryDataSource.select();
  }

  onFilterChange(filter: FilterType) {
    this.filter = filter;
    if (!Vue._.isSet(this.bookingForm.agogisUserId)) {
      // Preset agogisUserId so it's the same as the filter
      this.bookingForm.agogisUserId = this.filter.lecturerId!;
    }
    this.workloadSummaryDataSource.select();
  }

  get isPlanningAdmin(): boolean {
    return Vue.$service.v2.api.me.getAmIPlanningAdmin();
  }

  get invalidLessonSelection() {
    return (!this.lessonSelectionCanBeAssigned && !this.lessonSelectionCanBeConfirmed && !this.lessonSelectionCanBeDeclined);
  }

  get hasSelectedLessons() {
    return (this.selectedLessons?.length ?? 0) > 0;
  }

  get lessonSelectionHasNoBookingAction() {
    return this.hasSelectedLessons && this.selectedLessons.find((i) => i.data.hasNoBookingAction && !this.isPlanningAdmin);
  }

  get lessonSelectionCanBeAssigned() {
    return this.hasSelectedLessons && this.selectedLessons.every((i) => i.data.canBeAssigned || (i.data.hasNoBookingAction && this.isPlanningAdmin));
  }

  get lessonSelectionCanBeConfirmed() {
    return this.hasSelectedLessons && this.selectedLessons.every((i) => i.data.canBeConfirmed || (i.data.hasNoBookingAction && this.isPlanningAdmin));
  }

  get lessonSelectionCanBeDeclined() {
    return this.hasSelectedLessons && this.selectedLessons.every((i) => i.data.canBeDeclined || (i.data.hasNoBookingAction && this.isPlanningAdmin));
  }

  get bookedWorkload() {
    return this.workloadSummaryDataSource.data?.data.totalDays ?? 0;
  }

  get selectedWorkload() {
    return this.selectedLessons.reduce((prev, curr) => {
      let sum: number = prev;
      // Don't include already booked workload
      const isNotBooked = curr.data.canBeConfirmed || curr.data.canBeAssigned;
      if (curr.data.lecturerId !== this.filter.lecturerId || isNotBooked) {
        sum += curr.data.duration ?? 0;
      }
      return sum;
    }, 0);
  }

  /** Sum of all workload parts, including selected workload */
  get totalWorkload() {
    return this.bookedWorkload + (this.selectedWorkload ?? 0);
  }

  /** All workload parts, including selected workload */
  get workloadBreakdown(): LectureWorkloadSummaryStatusItemModel[] {
    return [
      ...(this.workloadSummaryDataSource.data?.data.summaryItemsByStatus ?? []),
    ];
  }

  /** All total parts */
  get totalItems(): LectureWorkloadLessonListModel[] {
    return [
      ...(this.workloadSummaryDataSource.data?.data.totalItems ?? []),
    ];
  }

  isWorkloadSummaryAvailable:boolean = false;
  async loadWorkloadSummary() {
    this.isWorkloadSummaryAvailable = false;
    if (this.filter.lecturerId !== undefined) {
      const req = new LookupWorkloadSummaryRequestModel();
      req.id = this.filter.lecturerId;
      req.year = this.filter.classYear!;
      const resp = await Vue.$service.v2.api.agogisCockpit.lookupWorkloadSummary(req);
      resp?.data.summaryItemsByStatus?.forEach((i) => { i.totalDays = i.totalDays ?? 0; });
      return resp;
    }
    this.isWorkloadSummaryAvailable = false;
    return new DataRecord(new LecturerWorkloadSummaryModel());
  }

  async assignBooking() {
    this.isWorking = true;
    this.bookingForm.updateAdminComment = (this.bookingForm.adminComment || '').length > 0;
    this.bookingForm.updateComment = (this.bookingForm.comment || '').length > 0;
    const res = await Vue.$service.v2.api.lecturerLessonAssignments.assignLessons(this.bookingForm as AssignLessonsRequestModel);
    this.bookingFormSubmittedEvent.invoke();
    this.isWorking = false;
    return res;
  }
  async updateComment() {
    this.isWorking = true;
    this.bookingForm.updateAdminComment = (this.bookingForm.adminComment || '').length > 0;
    this.bookingForm.updateComment = (this.bookingForm.comment || '').length > 0;
    const res = await Vue.$service.v2.api.lecturerLessonAssignments.updateCommentLessons(this.bookingForm as UpdateLessonAssignmentRequestModel);
    this.bookingFormSubmittedEvent.invoke();
    this.isWorking = false;
    return res;
  }

  async confirmBooking() {
    this.isWorking = true;
    this.bookingForm.updateAdminComment = (this.bookingForm.adminComment || '').length > 0;
    this.bookingForm.updateComment = (this.bookingForm.comment || '').length > 0;
    const res = await Vue.$service.v2.api.lecturerLessonAssignments.confirmLessons(this.bookingForm as ConfirmLessonsRequestModel);
    this.bookingFormSubmittedEvent.invoke();
    this.isWorking = false;
    return res;
  }

  async declineBooking() {
    this.isWorking = true;
    this.bookingForm.updateAdminComment = (this.bookingForm.adminComment || '').length > 0;
    this.bookingForm.updateComment = (this.bookingForm.comment || '').length > 0;
    const res = await Vue.$service.v2.api.lecturerLessonAssignments.declineLessons(this.bookingForm as DeclineLessonsRequestModel);
    this.bookingFormSubmittedEvent.invoke();
    this.isWorking = false;
    return res;
  }

  async updateBooking() {
    this.isWorking = true;
    this.bookingForm.updateAdminComment = (this.bookingForm.adminComment || '').length > 0;
    this.bookingForm.updateComment = (this.bookingForm.comment || '').length > 0;
    const res = await Vue.$service.v2.api.lecturerLessonAssignments.updateLessonAssignment(this.bookingForm as UpdateLessonAssignmentRequestModel);
    this.bookingFormSubmittedEvent.invoke();
    this.isWorking = false;
    return res;
  }

  clearBookingForm() {
    this.bookingForm = new UpdateLessonAssignmentRequestModel();
    this.filter.lecturerId = undefined;
  }
}
