// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import GetSchedulerByRoomsRequestDTO from '../../dto/request/GetSchedulerByRoomsRequestDTO';

export default abstract class GetSchedulerByRoomsRequestModel extends BaseModel<GetSchedulerByRoomsRequestDTO> {
  // #region    ──BodyRequest──────────────────────────╮
  // #endregion ───────────────────────────────────────╯
  // #region    ──HeaderRequest──────────────────────────╮
  // #endregion ─────────────────────────────────────────╯
  // #region    ──QueryRequest──────────────────────────╮
  /**
  * @type {date-time}
  */
  get startTime() { return this.dto.startTime; }
  set startTime(value) { this.dto.startTime = value; }
  /**
  * @type {date-time}
  */
  get endTime() { return this.dto.endTime; }
  set endTime(value) { this.dto.endTime = value; }
  /**
  */
  get selectedResourceTypes() { return this.dto.selectedResourceTypes; }
  set selectedResourceTypes(value) { this.dto.selectedResourceTypes = value; }
  // #endregion ────────────────────────────────────────╯

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.startTime = qs.starttime;
    this.dto.endTime = qs.endtime;
  }
}
