// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';
import Int64LookupSchema from './int64-lookup-schema';
import BuildingListSchema from './building-list-schema';

const schema = {
  $id: '/room-list-schema',
  type: 'object',
  required: ['id', 'sortOrder'],
  nullable: true,
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    caption: {
      type: 'string',
      nullable: true,
    },
    details: {
      type: 'array',
      nullable: true,
      items: {
        type: 'string',
      },
    },
    group: {
      ...Int64LookupSchema,
    },
    sortOrder: {
      type: 'integer',
    },
    shortName: {
      type: 'string',
      nullable: true,
    },
    floor: {
      type: 'string',
      nullable: true,
    },
    roomType: {
      ...Int64LookupSchema,
    },
    building: {
      ...BuildingListSchema,
    },
    schedulerResourceType: {
      enum: [
        0,
        1,
        2,
        3,
        4,
        5,
      ],
    },
  },
} as IJSONSchema;

export default schema;
