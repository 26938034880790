// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import UpdateLessonAssignmentRequestModel from '../../model/update-lesson-assignment-request-model';
import MoveAssignmentToLessonAsyncRequestModel from '../../model/move-assignment-to-lesson-async-request-model';
import AssignLessonsRequestModel from '../../model/assign-lessons-request-model';
import UpdateCommentLessonsRequestModel from '../../model/update-comment-lessons-request-model';
import ConfirmLessonsRequestModel from '../../model/confirm-lessons-request-model';
import DeclineLessonsRequestModel from '../../model/decline-lessons-request-model';
import GetLessonLecturerAssignmentStatusLookupRequestModel from '../../model/get-lesson-lecturer-assignment-status-lookup-request-model';
import Int64LookupListResultModel from '../../model/int64-lookup-list-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async updateLessonAssignment(request: UpdateLessonAssignmentRequestModel, config?: RequestConfig) {
    const requestDTO = UpdateLessonAssignmentRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-lesson-assignment'] ?? 'LecturerLessonAssignments/{agogisUserId}/Status/{lectureLessonStatusId}';
    endpointPath = endpointPath.replace(/\{agogisUserId\}/g, `${requestDTO.agogisUserId}`);
    endpointPath = endpointPath.replace(/\{lectureLessonStatusId\}/g, `${requestDTO.lectureLessonStatusId}`);
    const response = await service.post<any>(endpointPath, {
      body: {
        updateComment: requestDTO.updateComment,
        comment: requestDTO.comment,
        updateAdminComment: requestDTO.updateAdminComment,
        adminComment: requestDTO.adminComment,
        selectedLessonIds: requestDTO.selectedLessonIds,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async moveAssignmentToLessonAsync(request: MoveAssignmentToLessonAsyncRequestModel, config?: RequestConfig) {
    const requestDTO = MoveAssignmentToLessonAsyncRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-move-assignment-to-lesson-async'] ?? 'LecturerLessonAssignments/{id}/MoveToLesson/{lessonId}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    endpointPath = endpointPath.replace(/\{lessonId\}/g, `${requestDTO.lessonId}`);
    const response = await service.post<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async assignLessons(request: AssignLessonsRequestModel, config?: RequestConfig) {
    const requestDTO = AssignLessonsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-assign-lessons'] ?? 'LecturerLessonAssignments/{agogisUserId}/Assign';
    endpointPath = endpointPath.replace(/\{agogisUserId\}/g, `${requestDTO.agogisUserId}`);
    const response = await service.post<any>(endpointPath, {
      body: {
        updateComment: requestDTO.updateComment,
        comment: requestDTO.comment,
        updateAdminComment: requestDTO.updateAdminComment,
        adminComment: requestDTO.adminComment,
        selectedLessonIds: requestDTO.selectedLessonIds,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async updateCommentLessons(request: UpdateCommentLessonsRequestModel, config?: RequestConfig) {
    const requestDTO = UpdateCommentLessonsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-comment-lessons'] ?? 'LecturerLessonAssignments/{agogisUserId}/UpdateComment';
    endpointPath = endpointPath.replace(/\{agogisUserId\}/g, `${requestDTO.agogisUserId}`);
    const response = await service.post<any>(endpointPath, {
      body: {
        updateComment: requestDTO.updateComment,
        comment: requestDTO.comment,
        updateAdminComment: requestDTO.updateAdminComment,
        adminComment: requestDTO.adminComment,
        selectedLessonIds: requestDTO.selectedLessonIds,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async confirmLessons(request: ConfirmLessonsRequestModel, config?: RequestConfig) {
    const requestDTO = ConfirmLessonsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-confirm-lessons'] ?? 'LecturerLessonAssignments/{agogisUserId}/Confirm';
    endpointPath = endpointPath.replace(/\{agogisUserId\}/g, `${requestDTO.agogisUserId}`);
    const response = await service.post<any>(endpointPath, {
      body: {
        updateComment: requestDTO.updateComment,
        comment: requestDTO.comment,
        updateAdminComment: requestDTO.updateAdminComment,
        adminComment: requestDTO.adminComment,
        selectedLessonIds: requestDTO.selectedLessonIds,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async declineLessons(request: DeclineLessonsRequestModel, config?: RequestConfig) {
    const requestDTO = DeclineLessonsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-decline-lessons'] ?? 'LecturerLessonAssignments/{agogisUserId}/Decline';
    endpointPath = endpointPath.replace(/\{agogisUserId\}/g, `${requestDTO.agogisUserId}`);
    const response = await service.post<any>(endpointPath, {
      body: {
        updateComment: requestDTO.updateComment,
        comment: requestDTO.comment,
        updateAdminComment: requestDTO.updateAdminComment,
        adminComment: requestDTO.adminComment,
        selectedLessonIds: requestDTO.selectedLessonIds,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async getLessonLecturerAssignmentStatusLookup(request: GetLessonLecturerAssignmentStatusLookupRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-lesson-lecturer-assignment-status-lookup'] ?? 'LecturerLessonAssignments/Status/Lookup';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Int64LookupListResultModel.toModel(dto));
  },
});
