


















import Vue from 'vue';
import GetReferentLookupRequestModel from '../../services/v2/model/get-referent-lookup-request-model';

export default Vue.extend({
  name: 'AgogisLecturerLookup',
  props: {
    value: { type: [Number, String], default: undefined },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    label: { type: String, default: undefined },
    required: { default: false, type: Boolean },
    errors: { type: String, default: undefined },
  },
  data: () => ({
    isLoading: false,
    search: undefined as string | undefined,
    items: [] as any[],
    innerErrors: undefined as any,
  }),
  computed: {
    computedLoading(): boolean {
      return this.isLoading ?? this.loading;
    },
    computedLabel(): string {
      return this.label ?? 'Dozent / Referent';
    },
  },
  watch: {
    errors: {
      immediate: true,
      handler() {
        this.innerErrors = this.errors;
      },
    },
    value: {
      immediate: true,
      handler() {
        if (typeof this.value === 'string') {
          this.$emit('input', Number.parseInt(this.value, 10));
        } else {
          this.populateData();
        }
      },
    },
    search: {
      handler() {
        if (this.search?.length ?? 0 > 1) {
          this.populateData();
        }
      },
    },
  },
  methods: {
    populateData() {
      this.$debounce(async () => {
        this.isLoading = true;
        try {
          const req = new GetReferentLookupRequestModel({
            searchCriteria: this.search,
            'paging.CurrentPage': 1,
            'paging.PageSize': 25,
            id: !this.search ? this.value as any : undefined,
          });
          const response = await this.$service.v2.api.referents.getReferentLookup(req);
          this.items = response.items.map((x) => x.data);
        } catch (error: any) {
          this.innerErrors = error?.raw?.statusText ?? error?.message ?? error;
        } finally {
          this.isLoading = false;
        }
      }, 300, this)();
    },
  },
});
