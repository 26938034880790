// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import LectureWorkloadSummaryItemModel from '../../model/lecture-workload-summary-item-model';
import LectureWorkloadSummaryItemDTO from '../dto/lecture-workload-summary-item-dto';
import dtoSchema from '../schemas/lecture-workload-summary-item-schema';

export default abstract class LectureWorkloadSummaryItemModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<LectureWorkloadSummaryItemDTO>) {
    super();
    if (data) {
      this.name = data.name ?? undefined;
      this.totalDays = data.totalDays;
    }
  }
  /**
  */
  name?: string;
  /**
  * @type {double}
  */
  totalDays?: number;

  static toModel(dto: DeepPartial<LectureWorkloadSummaryItemDTO>): LectureWorkloadSummaryItemModel;
  static toModel(dto: DeepPartial<LectureWorkloadSummaryItemDTO> | undefined | null): LectureWorkloadSummaryItemModel | undefined;
  static toModel(dto: DeepPartial<LectureWorkloadSummaryItemDTO> | undefined | null): LectureWorkloadSummaryItemModel | undefined {
    return dto ? new LectureWorkloadSummaryItemModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<LectureWorkloadSummaryItemModel> | LectureWorkloadSummaryItemModel): LectureWorkloadSummaryItemDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      name: unwrapped.name,
      totalDays: unwrapped.totalDays,
    } as LectureWorkloadSummaryItemDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for LectureWorkloadSummaryItemModel, can not map to LectureWorkloadSummaryItemDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
