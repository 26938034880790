// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import WidgetSearchClassRequestViewModel from '../../viewModel/resource/WidgetSearchClassRequestViewModel';
import WidgetClassListResourcePagingResultViewModel from '../../viewModel/resource/WidgetClassListResourcePagingResultViewModel';
import FormFieldResourceListResultViewModel from '../../viewModel/resource/FormFieldResourceListResultViewModel';
import SubjectAreaResourceListResultViewModel from '../../viewModel/resource/SubjectAreaResourceListResultViewModel';
import RegisterClassRequestViewModel from '../../viewModel/resource/RegisterClassRequestViewModel';
import WidgetSearchClassInformationRequestViewModel from '../../viewModel/resource/WidgetSearchClassInformationRequestViewModel';
import WidgetClassInformationResourceViewModel from '../../viewModel/resource/WidgetClassInformationResourceViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getWidgetClasses(model: WidgetSearchClassRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-widget-classes'] ?? 'WidgetClasses';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const dto = response.data.result;
    return new WidgetClassListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async getWidgetRegistrationMarketingQuestions(config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-widget-registration-marketing-questions'] ?? 'WidgetClasses/RegistrationMarketingQuestions';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new FormFieldResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async getWidgetSubjectAreas(config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-widget-subject-areas'] ?? 'WidgetClasses/SubjectAreas';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new SubjectAreaResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async getWidgetClassRegistrationQuestions(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-widget-class-registration-questions'] ?? 'WidgetClasses/{id}/RegistrationQuestions';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new FormFieldResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async widgetClassRegisterRequest(id: number, model: RegisterClassRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-widget-class-register-request'] ?? 'WidgetClasses/{id}/Register';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async getWidgetClassInfo(model: WidgetSearchClassInformationRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-widget-class-info'] ?? 'WidgetClasses/ClassInformation';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const dto = response.data.result;
    return new WidgetClassInformationResourceViewModel().fromDTO(dto);
  },
  /**  */
  async downloadPdf(model: WidgetSearchClassInformationRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-download-pdf'] ?? 'WidgetClasses/DownloadPdf';
    const response = await service.post<any>(endpointPath, {
      responseType: 'blob',
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return new Blob([result], { type: 'application/octet-stream' });
  },
});
