// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/agogis-user-schema',
  type: 'object',
  required: ['id'],
  properties: {
    id: {
      type: 'integer',
    },
    userId: {
      type: 'integer',
      nullable: true,
    },
    firstName: {
      type: 'string',
      nullable: true,
    },
    lastName: {
      type: 'string',
      nullable: true,
    },
    fullName: {
      type: 'string',
      nullable: true,
    },
    email: {
      type: 'string',
      nullable: true,
    },
    function: {
      type: 'string',
      nullable: true,
    },
    functionValidTill: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    userStatus: {
      type: 'string',
      nullable: true,
    },
    isActive: {
      type: 'boolean',
    },
  },
} as IJSONSchema;

export default schema;
