




















































import Vue from 'vue';
import ServiceResponse from '@glittr/frontend-core/src/plugins/servicelayer/responseTypes/serviceResponse';
import WidgetClassInformationResourceViewModel from '../../../services/v1/viewModel/resource/WidgetClassInformationResourceViewModel';
import WidgetSearchClassInformationRequestViewModel from '../../../services/v1/viewModel/resource/WidgetSearchClassInformationRequestViewModel';

export default Vue.extend({
  layout: 'layout-widget',
  data: () => ({
    classInfo: {} as WidgetClassInformationResourceViewModel,
    isDownloadLoading: false,
    isAccordeonDetailVisible: [] as boolean[],
  }),
  computed: {
    toccoCourseKey(): string {
      return this.$config.values['widget-toccocoursekey'];
    },
    showTitle(): boolean {
      return this.$config.values['widget-textresource-showtitle'];
    },
    showSummary(): boolean {
      return this.$config.values['widget-textresource-showsummary'];
    },
    showAccordion(): boolean {
      return this.$config.values['widget-textresource-showaccordion'];
    },
    showPrint(): boolean {
      return this.$config.values['widget-textresource-showprint'];
    },
  },
  async mounted() {
    try {
      const widgetSearchClassInfoResourceRequestVM = new WidgetSearchClassInformationRequestViewModel();
      widgetSearchClassInfoResourceRequestVM.classTypeKey = this.toccoCourseKey;
      const response = await this.$service.api.widgetClasses.getWidgetClassInfo(widgetSearchClassInfoResourceRequestVM);
      this.classInfo = response;
    } catch (e) {
      const errorResponse = e as ServiceResponse;
      if (errorResponse.status === 503) {
        // = errorResponse.data;
      }
      this.$log.error(e);
    }
  },
  methods: {
    async onPdfDownload() {
      this.isDownloadLoading = true;
      try {
        const widgetSearchClassInfoResourceRequestVM = new WidgetSearchClassInformationRequestViewModel();
        widgetSearchClassInfoResourceRequestVM.classTypeKey = this.toccoCourseKey;
        const response = await this.$service.api.widgetClasses.downloadPdf(widgetSearchClassInfoResourceRequestVM);
        const fileName = `agogis-${this.classInfo.title?.toLocaleLowerCase().split(' ').join('-')}.pdf`;
        this.$service.utils.downloadFile(fileName, response);
      } catch (e) {
        const errorResponse = e as ServiceResponse;
        if (errorResponse.status === 503) {
        // = errorResponse.data;
        }
        this.$log.error(e);
      } finally {
        this.isDownloadLoading = false;
      }
    },
    toggleDetail(index:number) {
      this.$set(this.isAccordeonDetailVisible, index, !this.isAccordeonDetailVisible[index]);
    },
  },
});
