// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ContactAddressResourceViewModel from '../../../../viewModel/resource/ContactAddressResourceViewModel';
import ContactCorrespondenceAddressResourceViewModel from '../../../../viewModel/resource/ContactCorrespondenceAddressResourceViewModel';
import ContactCorrespondenceAddressResourceDTO from '../../dto/resource/ContactCorrespondenceAddressResourceDTO';

export default abstract class ContactCorrespondenceAddressResourceModel extends BaseModel<ContactCorrespondenceAddressResourceDTO> {
  /**
  */
  get isDifferent() { return this.dto.isDifferent; }
  set isDifferent(value) { this.dto.isDifferent = value; }
  /**
  */
  get contactAddress() { return this.getObjectModel(() => this.dto.contactAddress, ContactAddressResourceViewModel)!; }
  set contactAddress(value) { this.setObjectModel(() => this.dto.contactAddress, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = qs.isDifferent === 'true' ? true : qs.isdifferent;
    value = qs.isDifferent === 'false' ? false : undefined;
    this.dto.isDifferent = value;
  }
}
