// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import AgogisUserResourceViewModel from '../../../../viewModel/resource/AgogisUserResourceViewModel';
import LectureWorkloadSummaryStatusItemResourceViewModel from '../../../../viewModel/resource/LectureWorkloadSummaryStatusItemResourceViewModel';
import LectureWorkloadSummaryItemResourceViewModel from '../../../../viewModel/resource/LectureWorkloadSummaryItemResourceViewModel';
import LecturerWorkloadSummaryResourceViewModel from '../../../../viewModel/resource/LecturerWorkloadSummaryResourceViewModel';
import LecturerWorkloadSummaryResourceDTO from '../../dto/resource/LecturerWorkloadSummaryResourceDTO';

export default abstract class LecturerWorkloadSummaryResourceModel extends BaseModel<LecturerWorkloadSummaryResourceDTO> {
  /**
  * @type {int32}
  */
  get classYear() { return this.dto.classYear; }
  set classYear(value) { this.dto.classYear = value; }
  /**
  * @type {double}
  */
  get totalDays() { return this.dto.totalDays; }
  set totalDays(value) { this.dto.totalDays = value; }
  /**
  */
  get lecturer() { return this.getObjectModel(() => this.dto.lecturer, AgogisUserResourceViewModel)!; }
  set lecturer(value) { this.setObjectModel(() => this.dto.lecturer, value); }
  /**
  */
  get summaryItemsByStatus() { return this.getArrayModels(() => this.dto.summaryItemsByStatus, LectureWorkloadSummaryStatusItemResourceViewModel)!; }
  set summaryItemsByStatus(value) { this.setArrayModels(() => this.dto.summaryItemsByStatus, value); }
  /**
  */
  get totalItems() { return this.getArrayModels(() => this.dto.totalItems, LectureWorkloadSummaryItemResourceViewModel)!; }
  set totalItems(value) { this.setArrayModels(() => this.dto.totalItems, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.classyear);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.classYear = value;
    value = Number.parseFloat(qs.totaldays);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.totalDays = value;
  }
}
