













































































































































import Vue from 'vue';
import SchedulerClass from './scheduler-class.vue';
// import SchedulerLesson from './scheduler-lesson.vue';
import SchedulerReferent from './scheduler-referent.vue';
import SchedulerRoom from './scheduler-room.vue';

export default Vue.extend({
  auth: true,
  components: {
    SchedulerClass,
    // SchedulerLesson,
    SchedulerReferent,
    SchedulerRoom,
  },
  data: () => ({
    timeScale: 'Day',
    selectedTab: 0,
    timeRangeStart: Vue.$date.now().format('YYYY-MM-DD'),
    timeRangeEnd: Vue.$date.now().format('YYYY-MM-DD'),
    search: undefined as string | undefined,
    groupId: undefined as any,
    showLessons: true,
    showRooms: true,
    showReferents: true,
    hideEmptyRows: true,
    groupFilterItems: [] as any[],
    isLoading: false,
    extendedFilterVisible: false,
  }),
  watch: {
    hideEmptyRows() {
      this.$localStorage.set('scheduler-hideEmptyRows', this.hideEmptyRows);
    },
  },
  // mounted() {
  //   const tabId = Number.parseInt(this.$route.query.tab as string, 10);
  //   if (!Number.isNaN(tabId)) {
  //     this.selectedTab = tabId;
  //   }
  // },
  beforeMount() {
    this.hideEmptyRows = this.$localStorage.get('scheduler-hideEmptyRows') ?? true;
    const tabId = this.$route.params.tabId as string;
    if (tabId !== undefined && tabId !== null) {
      this.selectedTab = Number.parseInt(this.$route.params.tabId as string, 10);
    }
  },
  methods: {
    onTimeRangeChange(start: string, end: string, timeScale: string) {
      this.timeRangeEnd = end;
      this.timeRangeStart = start;
      this.timeScale = timeScale;
    },
    navigateInTime(amount: number) {
      const newStart = this.$date(this.timeRangeStart).add(amount, this.timeScale).format('YYYY-MM-DD');
      this.timeRangeStart = newStart;
    },
    dateDisplayFormatter(userInput: string | undefined, value: string | undefined) {
      if (this.timeScale === 'Week') {
        const startTimeObj = this.$date(this.timeRangeStart);
        const endTimeObj = this.$date(this.timeRangeEnd);
        return `${startTimeObj.format('DD.MM.YY')} - ${endTimeObj.format('DD.MM.YY')}`;
      }
      if (this.timeScale === 'Day') {
        return this.$date(value).format('dd DD.MM.YYYY');
      }
      return this.$date(this.timeRangeStart).format('DD.MM.YYYY');
    },
    changeTimeScale(timeScale: string) {
      this.timeScale = timeScale;
    },
    onTabClick(tabId: number) {
      const query = this.$routerUtils.getQueryParams();
      this.$router.replace({ path: `/agogis/cockpit/scheduler/${tabId}`, query });
    },
  },
});
