import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import Vue from 'vue';
import DataRecord from '@glittr/frontend-core/src/core/v2/data/data-record';
import GetPrototypeModuleOverviewRequestModel from '../../../../../services/v2/model/get-prototype-module-overview-request-model';
import ModulePlanningOverviewModel from '../../../../../services/v2/model/module-planning-overview-model';
import ModulePlanningLecturerModel from '../../../../../services/v2/model/module-planning-lecturer-model';
import ModulePlanningLessonModel from '../../../../../services/v2/model/module-planning-lesson-model';
import ModulePlanningClassModel from '../../../../../services/v2/model/module-planning-class-model';
import GetReferentLookupRequestModel from '../../../../../services/v2/model/get-referent-lookup-request-model';
import GetLectureLessonStatesRequestModel from '../../../../../services/v2/model/get-lecture-lesson-states-request-model';
import MoveAssignmentToLessonAsyncRequestModel from '../../../../../services/v2/model/move-assignment-to-lesson-async-request-model';

export interface CellConfigurationModel {
  id: number;
  x?: number;
  y?: number;
  isBookable?: boolean;
}

export interface LectureLessonStates {
  visibility: boolean,
  htmlColor: string,
  id?: number,
  name?: string | null,
}

export interface VisibleColumns {
  id: number,
  name: string,
  visibility: boolean,
}

export interface ModulePlanningLecturerModelWithPlaning extends ModulePlanningLecturerModel {
  modulePlanningViewLessonId?: number
}

export default class ModuleDetailPage extends PageBase {
  visibleColumnsSettings = false;
  lectureLessonStates : Array<LectureLessonStates> = [];
  visibleColumns : Array<VisibleColumns> = [];
  error?: string | {message: string};

  moduleOverviewDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.agogisCockpit.getPrototypeModuleOverview,
  });

  async initialize() {
    this.moduleOverviewDataSource.onSelected.addEventListener((record) => this.populateLecturerNames(record));
    const queries = Vue.$routerUtils.getQueryParams();
    this.moduleOverviewDataSource.filter = new GetPrototypeModuleOverviewRequestModel();
    this.moduleOverviewDataSource.filter.moduleId = Number.parseInt(queries.moduleid, 10);
    this.moduleOverviewDataSource.filter.classId = Number.parseInt(queries.classid, 10);
    this.moduleOverviewDataSource.filter.year = queries.year;
    Vue.set(this, 'visibleColumns', []);
    Vue.set(this, 'states', []);
    try {
      const req = new GetLectureLessonStatesRequestModel();
      const resp = await Vue.$service.v2.api.lectureLessonStatus.getLectureLessonStates(req);
      resp.items.forEach(({ data }) => {
        this.lectureLessonStates.push({
          id: data.id, name: data.name, visibility: true, htmlColor: data.htmlColor || '#ffffffff',
        });
      });
    } catch (error: any) {
      Vue.set(this, 'error', error);
    }
    await this.moduleOverviewDataSource.select();
  }

  localDateAndTime(d :string) { return Vue.$format.localDateAndTime(d); }
  get totalWorkloadDays() { return this.moduleOverviewDataSource.data?.data.lessons?.reduce((prev, curr) => prev + (curr.duration ?? 0), 0) ?? 0; }
  get totalClasses() { return this.moduleOverviewDataSource.data?.data.countOfRequiredLecturers ?? 0; }
  get moduleName() { return this.moduleOverviewDataSource.data?.data.moduleName ?? ''; }
  get className() { return this.moduleOverviewDataSource.data?.data.className ?? ''; }

  async populateLecturerNames(record: DataRecord<ModulePlanningOverviewModel>) {
    setTimeout(() => {
      const { data } = record;
      if (this.visibleColumns.length === 0 && data.classes?.length !== 0) {
        data.classes?.forEach(async (x) => {
          const primaryLeadLecturerName = await this.getLectureNameById(x.primaryLeadLecturerId);
          if (primaryLeadLecturerName) {
            x.name = `${x.name} | ${primaryLeadLecturerName}` || '';
          }
        });
        data.classes?.forEach((x) => this.visibleColumns.push({ id: x.id || 0, name: x.name || '', visibility: true }));
      }
    }, 1);
  }

  get rows(): Array<ModulePlanningLessonModel> {
    return this.moduleOverviewDataSource.data?.data.lessons ?? [];
  }
  get columns(): Array<ModulePlanningClassModel> {
    const classes = this.moduleOverviewDataSource.data?.data.classes ?? [];
    return classes.filter((c) => this.visibleColumns.some((v) => v.visibility && v.id === c.id)) ?? [];
  }
  get cards():Array<ModulePlanningLecturerModelWithPlaning> {
    const ret : Array<ModulePlanningLecturerModelWithPlaning> = [];
    this.moduleOverviewDataSource.data?.data.bookings?.forEach((b) => {
      b.assignedLecturers?.forEach((l) => {
        const r = (l as ModulePlanningLecturerModelWithPlaning);
        r.modulePlanningViewLessonId = b.modulePlanningViewLessonId;
        ret.push(r);
      });
    });
    return ret;
  }
  get cellConfigurations():Array<CellConfigurationModel> {
    const ret :Array<CellConfigurationModel> = [];
    this.moduleOverviewDataSource.data?.data.bookings?.forEach((i) => {
      ret.push({
        id: i.lessonId || 0, x: i.classId, y: i.modulePlanningViewLessonId, isBookable: i.isBookable,
      });
    });
    return ret;
  }
  cardColor(card:ModulePlanningLecturerModel):string {
    return this.lectureLessonStates.filter((x) => x.id === card.lessonAssignmentStatusId)[0]?.htmlColor || '';
  }
  cardsOfCell(classId: number, lessonId: number):ModulePlanningLecturerModel[] {
    return this.cards?.filter((c) => c.assignedClassId === classId && c.modulePlanningViewLessonId === lessonId && this.lectureLessonStates.filter((x) => x.id === c.lessonAssignmentStatusId)[0].visibility);
  }
  cellConfiguration(x: number, y: number):CellConfigurationModel {
    return this.cellConfigurations?.filter((c) => c.x === x && c.y === y)[0];
  }
  startDrag(evt: DragEvent, card:ModulePlanningLecturerModel, rowId: number) {
    if (this.moduleOverviewDataSource.isLoading) {
      evt.stopPropagation();
      return;
    }
    if (evt.dataTransfer && card && card?.lessonAssignmentId) {
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      if (evt.dataTransfer?.getData('itemID') === '') {
        evt.dataTransfer?.setData('itemID', card?.lessonAssignmentId.toString());
      }
      if (evt.dataTransfer?.getData('rowId') === '') {
        evt.dataTransfer?.setData('rowId', rowId.toString());
      }
    }
  }
  onDragover(evt: DragEvent, colID : number, rowID : number) {
    if (this.moduleOverviewDataSource.isLoading) { return; }
    const isBookable = this.cellConfiguration(colID, rowID).isBookable || false;
    if (isBookable && (evt?.dataTransfer?.getData('rowId') === '' || rowID === +(evt?.dataTransfer?.getData('rowId') || ''))) {
      evt.preventDefault();
    } else {
      evt.stopPropagation();
    }
  }
  async onDrop(evt: DragEvent, colID: number, rowID : number) {
    if (this.moduleOverviewDataSource.isLoading) { return; }
    const targetId = this.cellConfiguration(colID, rowID).id;
    this.moduleOverviewDataSource.isLoading = true;
    const lessonAssignmentId = +(evt.dataTransfer?.getData('itemID') || '');
    const card = this.cards.filter((x) => x.lessonAssignmentId === lessonAssignmentId)[0];
    if (card && targetId !== card.assignedLessonId && rowID === +(evt?.dataTransfer?.getData('rowId') || '-1')) {
      try {
        const req = new MoveAssignmentToLessonAsyncRequestModel();
        req.lessonId = targetId;
        req.id = lessonAssignmentId;
        await Vue.$service.v2.api.lecturerLessonAssignments.moveAssignmentToLessonAsync(req);
      } catch (error: any) {
      // TODO: Reset card in case of error, at the moment the board is not refreshed properly
        Vue.set(this, 'error', error);
      } finally {
        this.moduleOverviewDataSource.isLoading = false;
        await this.moduleOverviewDataSource.select();
      }
    }
    this.moduleOverviewDataSource.isLoading = false;
  }
  async getLectureNameById(id: number|undefined): Promise<string | null | undefined> {
    if (!id) {
      return undefined;
    }
    try {
      const request = new GetReferentLookupRequestModel();
      request.id = id;
      const lectureUser = await Vue.$service.v2.api.referents.getReferentLookup(request);
      return lectureUser.items[0].data.caption;
    } catch (error: any) {
    // TODO: Reset card in case of error, at the moment the board is not refreshed properly
      return undefined;
    }
  }
}
