// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import SetPasswordRequestModel from '../../model/set-password-request-model';
import SetPasswordRequestDTO from '../dto/set-password-request-dto';
import dtoSchema from '../schemas/set-password-request-schema';

export default abstract class SetPasswordRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<SetPasswordRequestDTO>) {
    super();
    if (data) {
      this.email = data.email;
      this.oldPassword = data.oldPassword;
      this.newPassword = data.newPassword;
    }
  }
  /**
  * @minimum 1
  * @type {email}
  */
  email?: string;
  /**
  * @minimum 6
  */
  oldPassword?: string;
  /**
  * @minimum 6
  */
  newPassword?: string;

  static toModel(dto: DeepPartial<SetPasswordRequestDTO>): SetPasswordRequestModel;
  static toModel(dto: DeepPartial<SetPasswordRequestDTO> | undefined | null): SetPasswordRequestModel | undefined;
  static toModel(dto: DeepPartial<SetPasswordRequestDTO> | undefined | null): SetPasswordRequestModel | undefined {
    return dto ? new SetPasswordRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<SetPasswordRequestModel> | SetPasswordRequestModel): SetPasswordRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      email: unwrapped.email,
      oldPassword: unwrapped.oldPassword,
      newPassword: unwrapped.newPassword,
    } as SetPasswordRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for SetPasswordRequestModel, can not map to SetPasswordRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
