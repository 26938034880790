

































































































































































































import Vue from 'vue';
import ModuleDetailPage from './module-detail-page';

export default Vue.extend({
  components: {
  },
  data: () => ({
    page: new ModuleDetailPage(),
  }),
  computed: {
  },
  mounted() {
    this.page.initialize();
  },
  methods: {
    setVisibilityForAllColumns(newState: boolean) {
      this.page.visibleColumns.forEach((x) => { x.visibility = newState; });
    },
    setVisibilityForAllStates(newState: boolean) {
      this.page.lectureLessonStates.forEach((x) => { x.visibility = newState; });
    },
    refresh() {
      this.page.moduleOverviewDataSource.select();
    },
    returnToPreviousPage() {
      this.$router.back();
    },
    gotoDetail(id: number) {
      this.$router.push(`/agogis/cockpit/schedule-planning/lessons/${id}`);
    },
  },
});
