
































































import Vue, { PropType } from 'vue';
import LecturerBookingSummaryView from './lecturer-booking-summary-view';
import AgogisBookingCommentComponent from '../../../../../components/agogis-booking-comment.vue';

export default Vue.extend({
  name: 'AgogisAssignToLessonFormReduced',
  components: {
    AgogisBookingCommentComponent,
  },
  props: {
    value: { default: () => ({} as LecturerBookingSummaryView), type: Object as PropType<LecturerBookingSummaryView> },
  },
  data: () => ({
    isLoading: false,
    confirmAssignment: undefined as boolean | undefined,
    hasChanges: false,
    lecturerError: undefined as string | undefined,
    lecturerAssignmentStatusError: undefined as string | undefined,
    errorResponse: undefined as any,
  }),
  computed: {
  },
  methods: {
    async onDecline() {
      const shallDecline = await this.$dialog.confirm('Absage', 'Wollen Sie diese Einsätze wirklich absagen?');
      if (shallDecline) {
        await this.value.declineBookingDataSource.select();
        this.$emit('submit');
      }
    },
    async onConfirm() {
      await this.value.confirmBookingDataSource.select();
      this.$emit('submit');
    },
    async onAssign() {
      await this.value.assignBookingDataSource.select();
      this.$emit('submit');
    },
    onInput() {
      this.$emit('input', []);
    },
    onCancel() {
      this.$emit('cancel');
    },
    onSubmit() {
      let hasError = false;
      this.lecturerError = undefined;
      this.lecturerAssignmentStatusError = undefined;
      if (!this.value.bookingForm.agogisUserId) {
        this.lecturerError = 'Ref/Doz muss ausgewählt werden';
        hasError = true;
      }
      if (Vue._.isEmpty(this.value.bookingForm.lectureLessonStatusId)) {
        this.lecturerAssignmentStatusError = 'Buchungsstatus muss ausgewählt werden';
        hasError = true;
      }
      if (!hasError) {
        this.submitNewComment();
      }
    },
    async submitNewComment() {
      try {
        await this.value.updateCommentDataSource.select();
        this.$emit('newComment');
      } catch (error : any) {
        this.errorResponse = error;
      }
    },
  },
});
