

































import Vue from 'vue';
import ServiceResponse from '@glittr/frontend-core/src/plugins/servicelayer/responseTypes/serviceResponse';
import WidgetClassListResourceViewModel from '../../../services/v1/viewModel/resource/WidgetClassListResourceViewModel';
import ApiPagingRequestViewModel from '../../../services/v1/viewModel/resource/ApiPagingRequestViewModel';
import WidgetSearchClassRequestViewModel from '../../../services/v1/viewModel/resource/WidgetSearchClassRequestViewModel';

export default Vue.extend({
  layout: 'layout-widget',
  data: () => ({
    events: [] as WidgetClassListResourceViewModel[],
  }),
  computed: {
    toccoFilter(): string {
      return this.$config.values['widget-toccofilter'] ?? '1==1';
    },
    pageSize():number {
      if (this.$config.values['widget-pagesize'] !== undefined) { return this.$config.values['widget-pagesize']; }
      return 10;
    },
  },
  async mounted() {
    try {
      const widgetSearchClassRequestVM = new WidgetSearchClassRequestViewModel();
      widgetSearchClassRequestVM.toccoFilter = this.toccoFilter;
      const page = new ApiPagingRequestViewModel();
      page.pageSize = this.pageSize;
      widgetSearchClassRequestVM.paging = page;
      const response = await this.$service.api.widgetClasses.getWidgetClasses(widgetSearchClassRequestVM);
      this.events = response.items;
    } catch (e) {
      const errorResponse = e as ServiceResponse;
      if (errorResponse.status === 503) {
        // this.events = errorResponse.data;
      }
      this.$log.error(e);
    }
  },
});
