// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import AppInfoComponentSchema from './app-info-component-schema';

const schema = {
  $id: '/app-info-schema',
  type: 'object',
  required: [],
  properties: {
    title: {
      type: 'string',
      nullable: true,
    },
    version: {
      type: 'string',
      nullable: true,
    },
    healthEndpoint: {
      type: 'string',
      nullable: true,
    },
    environment: {
      type: 'string',
      nullable: true,
    },
    components: {
      type: 'array',
      nullable: true,
      items: { ...AppInfoComponentSchema },
    },
  },
} as IJSONSchema;

export default schema;
