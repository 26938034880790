// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ModulePlanningOverviewModel from '../../model/module-planning-overview-model';
import ModulePlanningOverviewDTO from '../dto/module-planning-overview-dto';
import dtoSchema from '../schemas/module-planning-overview-schema';
import ResourceLinkModel from '../../model/resource-link-model';
import ModulePlanningLessonModel from '../../model/module-planning-lesson-model';
import ModulePlanningClassModel from '../../model/module-planning-class-model';
import ModulePlanningBookingSlotModel from '../../model/module-planning-booking-slot-model';

export default abstract class ModulePlanningOverviewModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ModulePlanningOverviewDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.moduleName = data.moduleName ?? undefined;
      this.moduleStartDate = data.moduleStartDate;
      this.moduleEndDate = data.moduleEndDate;
      this.moduleTotalDuration = data.moduleTotalDuration;
      this.moduleLessonsCount = data.moduleLessonsCount;
      this.classId = data.classId;
      this.className = data.className ?? undefined;
      this.classStartYear = data.classStartYear;
      this.classIsPrototype = data.classIsPrototype;
      this.countOfRequiredLecturers = data.countOfRequiredLecturers ?? undefined;
      this.countOfProvisionalClasses = data.countOfProvisionalClasses ?? undefined;
      this.countOfReservedClasses = data.countOfReservedClasses ?? undefined;
      this.countOfConfirmedClasses = data.countOfConfirmedClasses ?? undefined;
      this.lessons = data.lessons?.map((i) => ModulePlanningLessonModel.toModel(i)) ?? [];
      this.classes = data.classes?.map((i) => ModulePlanningClassModel.toModel(i)) ?? [];
      this.bookings = data.bookings?.map((i) => ModulePlanningBookingSlotModel.toModel(i)) ?? [];
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  */
  moduleName?: string;
  /**
  * @type {date-time}
  */
  moduleStartDate?: string;
  /**
  * @type {date-time}
  */
  moduleEndDate?: string;
  /**
  * @type {double}
  */
  moduleTotalDuration?: number;
  /**
  * @type {int32}
  */
  moduleLessonsCount?: number;
  /**
  * @type {int64}
  */
  classId?: number;
  /**
  */
  className?: string;
  /**
  * @type {int32}
  */
  classStartYear?: number;
  /**
  */
  classIsPrototype?: boolean;
  /**
  * @type {int32}
  */
  countOfRequiredLecturers?: number;
  /**
  * @type {int32}
  */
  countOfProvisionalClasses?: number;
  /**
  * @type {int32}
  */
  countOfReservedClasses?: number;
  /**
  * @type {int32}
  */
  countOfConfirmedClasses?: number;
  /**
  */
  lessons?: ModulePlanningLessonModel[];
  /**
  */
  classes?: ModulePlanningClassModel[];
  /**
  */
  bookings?: ModulePlanningBookingSlotModel[];

  static toModel(dto: DeepPartial<ModulePlanningOverviewDTO>): ModulePlanningOverviewModel;
  static toModel(dto: DeepPartial<ModulePlanningOverviewDTO> | undefined | null): ModulePlanningOverviewModel | undefined;
  static toModel(dto: DeepPartial<ModulePlanningOverviewDTO> | undefined | null): ModulePlanningOverviewModel | undefined {
    return dto ? new ModulePlanningOverviewModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ModulePlanningOverviewModel> | ModulePlanningOverviewModel): ModulePlanningOverviewDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      moduleName: unwrapped.moduleName,
      moduleStartDate: unwrapped.moduleStartDate,
      moduleEndDate: unwrapped.moduleEndDate,
      moduleTotalDuration: unwrapped.moduleTotalDuration,
      moduleLessonsCount: unwrapped.moduleLessonsCount,
      classId: unwrapped.classId,
      className: unwrapped.className,
      classStartYear: unwrapped.classStartYear,
      classIsPrototype: unwrapped.classIsPrototype,
      countOfRequiredLecturers: unwrapped.countOfRequiredLecturers,
      countOfProvisionalClasses: unwrapped.countOfProvisionalClasses,
      countOfReservedClasses: unwrapped.countOfReservedClasses,
      countOfConfirmedClasses: unwrapped.countOfConfirmedClasses,
      lessons: unwrapped.lessons,
      classes: unwrapped.classes,
      bookings: unwrapped.bookings,
    } as ModulePlanningOverviewDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ModulePlanningOverviewModel, can not map to ModulePlanningOverviewDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
