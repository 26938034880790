// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import OnlinePaymentOrderItemModel from '../../model/online-payment-order-item-model';
import OnlinePaymentOrderItemDTO from '../dto/online-payment-order-item-dto';
import dtoSchema from '../schemas/online-payment-order-item-schema';

export default abstract class OnlinePaymentOrderItemModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<OnlinePaymentOrderItemDTO>) {
    super();
    if (data) {
      this.productId = data.productId ?? undefined;
      this.sku = data.sku ?? undefined;
      this.name = data.name ?? undefined;
      this.unitPrice = data.unitPrice ?? undefined;
      this.discountFraction = data.discountFraction ?? undefined;
      this.quantity = data.quantity ?? undefined;
    }
  }
  /**
  */
  productId?: string;
  /**
  */
  sku?: string;
  /**
  */
  name?: string;
  /**
  * @type {double}
  */
  unitPrice?: number;
  /**
  * @type {double}
  */
  discountFraction?: number;
  /**
  * @type {double}
  */
  quantity?: number;

  static toModel(dto: DeepPartial<OnlinePaymentOrderItemDTO>): OnlinePaymentOrderItemModel;
  static toModel(dto: DeepPartial<OnlinePaymentOrderItemDTO> | undefined | null): OnlinePaymentOrderItemModel | undefined;
  static toModel(dto: DeepPartial<OnlinePaymentOrderItemDTO> | undefined | null): OnlinePaymentOrderItemModel | undefined {
    return dto ? new OnlinePaymentOrderItemModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<OnlinePaymentOrderItemModel> | OnlinePaymentOrderItemModel): OnlinePaymentOrderItemDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      productId: unwrapped.productId,
      sku: unwrapped.sku,
      name: unwrapped.name,
      unitPrice: unwrapped.unitPrice,
      discountFraction: unwrapped.discountFraction,
      quantity: unwrapped.quantity,
    } as OnlinePaymentOrderItemDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for OnlinePaymentOrderItemModel, can not map to OnlinePaymentOrderItemDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
