

































import Vue, { PropType } from 'vue';
import AgogisAssignToLessonFormReduced from './agogis-assign-to-lesson-form-reduced.vue';
import AgogisAssignToLessonFormAdmin from './agogis-assign-to-lesson-form-admin.vue';
import LecturerBookingSummaryView from './lecturer-booking-summary-view';

export default Vue.extend({
  name: 'AgogisAssignToLessonForm',
  components: {
    AgogisAssignToLessonFormAdmin,
    AgogisAssignToLessonFormReduced,
  },
  props: {
    value: { default: () => ({} as LecturerBookingSummaryView), type: Object as PropType<LecturerBookingSummaryView> },
  },
  data: () => ({
  }),
  methods: {
    onSubmit() {
      this.$emit('submitted');
    },
    onNewComment() {
      this.$emit('commented');
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
});
