// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import LectureLessonStatusResourceViewModel from '../../../../viewModel/resource/LectureLessonStatusResourceViewModel';
import LectureLessonBookingActionResourceViewModel from '../../../../viewModel/resource/LectureLessonBookingActionResourceViewModel';
import LectureLessonListResourceViewModel from '../../../../viewModel/resource/LectureLessonListResourceViewModel';
import LectureLessonListResourceDTO from '../../dto/resource/LectureLessonListResourceDTO';

export default abstract class LectureLessonListResourceModel extends BaseModel<LectureLessonListResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get lectureClassId() { return this.dto.lectureClassId; }
  set lectureClassId(value) { this.dto.lectureClassId = value; }
  /**
  * @type {int64}
  */
  get lectureModuleId() { return this.dto.lectureModuleId; }
  set lectureModuleId(value) { this.dto.lectureModuleId = value; }
  /**
  */
  get moduleName() { return this.dto.moduleName; }
  set moduleName(value) { this.dto.moduleName = value; }
  /**
  */
  get lessonName() { return this.dto.lessonName; }
  set lessonName(value) { this.dto.lessonName = value; }
  /**
  * @type {int64}
  */
  get primaryLeadLecturerId() { return this.dto.primaryLeadLecturerId; }
  set primaryLeadLecturerId(value) { this.dto.primaryLeadLecturerId = value; }
  /**
  * @type {int64}
  */
  get secondaryLeadLecturerId() { return this.dto.secondaryLeadLecturerId; }
  set secondaryLeadLecturerId(value) { this.dto.secondaryLeadLecturerId = value; }
  /**
  * @type {int64}
  */
  get lecturerId() { return this.dto.lecturerId; }
  set lecturerId(value) { this.dto.lecturerId = value; }
  /**
  * @type {int64}
  */
  get lecturerRoleId() { return this.dto.lecturerRoleId; }
  set lecturerRoleId(value) { this.dto.lecturerRoleId = value; }
  /**
  */
  get primaryLeadLecturerFirstName() { return this.dto.primaryLeadLecturerFirstName; }
  set primaryLeadLecturerFirstName(value) { this.dto.primaryLeadLecturerFirstName = value; }
  /**
  */
  get primaryLeadLecturerLastName() { return this.dto.primaryLeadLecturerLastName; }
  set primaryLeadLecturerLastName(value) { this.dto.primaryLeadLecturerLastName = value; }
  /**
  */
  get className() { return this.dto.className; }
  set className(value) { this.dto.className = value; }
  /**
  */
  get lecturerRoleName() { return this.dto.lecturerRoleName; }
  set lecturerRoleName(value) { this.dto.lecturerRoleName = value; }
  /**
  */
  get lecturerFirstName() { return this.dto.lecturerFirstName; }
  set lecturerFirstName(value) { this.dto.lecturerFirstName = value; }
  /**
  */
  get lecturerLastName() { return this.dto.lecturerLastName; }
  set lecturerLastName(value) { this.dto.lecturerLastName = value; }
  /**
  */
  get lecturerStatus() { return this.getObjectModel(() => this.dto.lecturerStatus, LectureLessonStatusResourceViewModel)!; }
  set lecturerStatus(value) { this.setObjectModel(() => this.dto.lecturerStatus, value); }
  /**
  */
  get lecturerComment() { return this.dto.lecturerComment; }
  set lecturerComment(value) { this.dto.lecturerComment = value; }
  /**
  */
  get lecturerAdminComment() { return this.dto.lecturerAdminComment; }
  set lecturerAdminComment(value) { this.dto.lecturerAdminComment = value; }
  /**
  * @type {date-time}
  */
  get startTime() { return this.dto.startTime; }
  set startTime(value) { this.dto.startTime = value; }
  /**
  * @type {date-time}
  */
  get endTime() { return this.dto.endTime; }
  set endTime(value) { this.dto.endTime = value; }
  /**
  * @type {date-time}
  */
  get moduleStartDate() { return this.dto.moduleStartDate; }
  set moduleStartDate(value) { this.dto.moduleStartDate = value; }
  /**
  * @type {date-time}
  */
  get moduleEndDate() { return this.dto.moduleEndDate; }
  set moduleEndDate(value) { this.dto.moduleEndDate = value; }
  /**
  * @type {double}
  */
  get moduleTotalDuration() { return this.dto.moduleTotalDuration; }
  set moduleTotalDuration(value) { this.dto.moduleTotalDuration = value; }
  /**
  * @type {double}
  */
  get duration() { return this.dto.duration; }
  set duration(value) { this.dto.duration = value; }
  /**
  */
  get classIsPrototype() { return this.dto.classIsPrototype; }
  set classIsPrototype(value) { this.dto.classIsPrototype = value; }
  /**
  * @type {int32}
  */
  get classStartYear() { return this.dto.classStartYear; }
  set classStartYear(value) { this.dto.classStartYear = value; }
  /**
  */
  get teamTeaching() { return this.dto.teamTeaching; }
  set teamTeaching(value) { this.dto.teamTeaching = value; }
  /**
  * @type {int32}
  */
  get bookingAction() { return this.dto.bookingAction; }
  set bookingAction(value) { this.dto.bookingAction = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.lectureclassid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.lectureClassId = value;
    value = Number.parseFloat(qs.lecturemoduleid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.lectureModuleId = value;
    this.dto.moduleName = qs.modulename;
    this.dto.lessonName = qs.lessonname;
    value = Number.parseFloat(qs.primaryleadlecturerid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.primaryLeadLecturerId = value;
    value = Number.parseFloat(qs.secondaryleadlecturerid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.secondaryLeadLecturerId = value;
    value = Number.parseFloat(qs.lecturerid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.lecturerId = value;
    value = Number.parseFloat(qs.lecturerroleid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.lecturerRoleId = value;
    this.dto.primaryLeadLecturerFirstName = qs.primaryleadlecturerfirstname;
    this.dto.primaryLeadLecturerLastName = qs.primaryleadlecturerlastname;
    this.dto.className = qs.classname;
    this.dto.lecturerRoleName = qs.lecturerrolename;
    this.dto.lecturerFirstName = qs.lecturerfirstname;
    this.dto.lecturerLastName = qs.lecturerlastname;
    this.dto.lecturerComment = qs.lecturercomment;
    this.dto.lecturerAdminComment = qs.lectureradmincomment;
    this.dto.startTime = qs.starttime;
    this.dto.endTime = qs.endtime;
    this.dto.moduleStartDate = qs.modulestartdate;
    this.dto.moduleEndDate = qs.moduleenddate;
    value = Number.parseFloat(qs.moduletotalduration);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.moduleTotalDuration = value;
    value = Number.parseFloat(qs.duration);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.duration = value;
    value = qs.classIsPrototype === 'true' ? true : qs.classisprototype;
    value = qs.classIsPrototype === 'false' ? false : undefined;
    this.dto.classIsPrototype = value;
    value = Number.parseFloat(qs.classstartyear);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.classStartYear = value;
    value = qs.teamTeaching === 'true' ? true : qs.teamteaching;
    value = qs.teamTeaching === 'false' ? false : undefined;
    this.dto.teamTeaching = value;
    value = Number.parseFloat(qs.bookingaction);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.bookingAction = value;
  }
}
