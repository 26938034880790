
































































import Vue, { PropType } from 'vue';
import LecturerBookingSummaryView from './lecturer-booking-summary-view';
import AgogisBookingCommentComponent from '../../../../../components/agogis-booking-comment.vue';

export default Vue.extend({
  name: 'AgogisAssignToLessonFormAdmin',
  components: {
    AgogisBookingCommentComponent,
  },
  props: {
    value: { default: () => ({} as LecturerBookingSummaryView), type: Object as PropType<LecturerBookingSummaryView> },
  },
  data: () => ({
    hasChanges: false,
    lecturerError: undefined as string | undefined,
    lecturerAssignmentStatusError: undefined as string | undefined,
    errorResponse: undefined as any,
  }),
  computed: {
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onSubmit() {
      let hasError = false;
      this.lecturerError = undefined;
      this.lecturerAssignmentStatusError = undefined;
      if (!this.value.bookingForm.agogisUserId) {
        this.lecturerError = 'Ref/Doz muss ausgewählt werden';
        hasError = true;
      }
      if (Vue._.isEmpty(this.value.bookingForm.lectureLessonStatusId)) {
        this.lecturerAssignmentStatusError = 'Buchungsstatus muss ausgewählt werden';
        hasError = true;
      }
      if (!hasError) {
        this.submitAssignment();
      }
    },
    async submitAssignment() {
      try {
        await this.value.updateBookingDataSource.select();
        this.$emit('submit');
      } catch (error : any) {
        this.errorResponse = error;
      }
    },
  },
});
