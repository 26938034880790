// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import SearchLessonsRequestModel from '../../model/search-lessons-request-model';
import ExportLessonsRequestModel from '../../model/export-lessons-request-model';
import SearchLessonByModuleRequestModel from '../../model/search-lesson-by-module-request-model';
import GetLessonByIdRequestModel from '../../model/get-lesson-by-id-request-model';
import GetReferentsByLessonIdRequestModel from '../../model/get-referents-by-lesson-id-request-model';
import LectureLessonListPagingResultModel from '../../model/lecture-lesson-list-paging-result-model';
import LectureLessonListListResultModel from '../../model/lecture-lesson-list-list-result-model';
import LectureLessonModel from '../../model/lecture-lesson-model';
import LectureLessonLecturerListResultModel from '../../model/lecture-lesson-lecturer-list-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async searchLessons(request: SearchLessonsRequestModel, config?: RequestConfig) {
    const requestDTO = SearchLessonsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-search-lessons'] ?? 'Lessons/ClassYear/{classYear}';
    endpointPath = endpointPath.replace(/\{classYear\}/g, `${requestDTO.classYear}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        moduleId: requestDTO.moduleId,
        classIds: requestDTO.classIds,
        lectureLessonStatusIds: requestDTO.lectureLessonStatusIds,
        eventTypeIds: requestDTO.eventTypeIds,
        userId: requestDTO.userId,
        lecturerId: requestDTO.lecturerId,
        primaryLeadLecturerId: requestDTO.primaryLeadLecturerId,
        isPrototype: requestDTO.isPrototype,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(LectureLessonListPagingResultModel.toModel(dto));
  },
  /**  */
  async exportLessons(request: ExportLessonsRequestModel, config?: RequestConfig) {
    const requestDTO = ExportLessonsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-export-lessons'] ?? 'Lessons/ClassYear/{classYear}/Export';
    endpointPath = endpointPath.replace(/\{classYear\}/g, `${requestDTO.classYear}`);
    const response = await service.get<any>(endpointPath, {
      responseType: 'blob',
      query: {
        moduleIds: requestDTO.moduleIds,
        classIds: requestDTO.classIds,
        lectureLessonStatusIds: requestDTO.lectureLessonStatusIds,
        eventTypeIds: requestDTO.eventTypeIds,
        userId: requestDTO.userId,
        lecturerId: requestDTO.lecturerId,
        primaryLeadLecturerId: requestDTO.primaryLeadLecturerId,
        isPrototype: requestDTO.isPrototype,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(new Blob([result], { type: 'application/octet-stream' }));
  },
  /**  */
  async searchLessonByModule(request: SearchLessonByModuleRequestModel, config?: RequestConfig) {
    const requestDTO = SearchLessonByModuleRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-search-lesson-by-module'] ?? 'Lessons/ClassYear/{classYear}/Module/{moduleId}';
    endpointPath = endpointPath.replace(/\{classYear\}/g, `${requestDTO.classYear}`);
    endpointPath = endpointPath.replace(/\{moduleId\}/g, `${requestDTO.moduleId}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        moduleId: requestDTO.moduleId,
        classIds: requestDTO.classIds,
        lectureLessonStatusIds: requestDTO.lectureLessonStatusIds,
        eventTypeIds: requestDTO.eventTypeIds,
        userId: requestDTO.userId,
        lecturerId: requestDTO.lecturerId,
        primaryLeadLecturerId: requestDTO.primaryLeadLecturerId,
        isPrototype: requestDTO.isPrototype,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(LectureLessonListListResultModel.toModel(dto));
  },
  /**  */
  async getLessonById(request: GetLessonByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetLessonByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-lesson-by-id'] ?? 'Lessons/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(LectureLessonModel.toModel(dto));
  },
  /**  */
  async getReferentsByLessonId(request: GetReferentsByLessonIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetReferentsByLessonIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-referents-by-lesson-id'] ?? 'Lessons/{id}/Referents';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(LectureLessonLecturerListResultModel.toModel(dto));
  },
});
