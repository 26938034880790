




















































































import UserSetPasswordRequestViewModel from '@glittr/frontend-core/src/services/v1/viewModel/resource/UserSetPasswordRequestViewModel';
import Vue from 'vue';

export default Vue.extend({
  auth: true,
  data: () => ({
    resetPassword: new UserSetPasswordRequestViewModel(),
    requestError: undefined as any,
    newPasswordError: undefined as undefined | string,
    repeatPasswordError: undefined as undefined | string,
    roles: [] as any,
    columns: [] as {}[],
    user: {} as any,
    isLoading: false,
  }),
  async mounted() {
    this.isLoading = true;
    await this.$auth.refresh();
    this.$set(this.$store.values, 'title', 'USER PROFILE');
    this.columns = [
      { value: this.$t('page.profile.roles.table.role'), field: 'label' },
      { value: this.$t('page.profile.roles.table.designation'), field: 'roleName' },
    ];
    this.user = await this.$auth.getUser();
    await this.getRoles();
    this.isLoading = false;
  },
  methods: {
    async getRoles() {
      const { claims } = this.$auth;
      const prefix = this.$config.values['cockpit-prefix'];

      Object.keys(claims).forEach((claimKey : string) => {
        if (this._.isSet(claimKey) && (claimKey.startsWith(prefix) || claimKey === 'role')) {
          const labelName = claimKey.replace(prefix, '');
          const role = { label: this.$tAlt(`page.profile.roles.label.${labelName[0].toLowerCase() + labelName.slice(1)}`, `${labelName[0].toLowerCase() + labelName.slice(1)}`), roleName: claims[claimKey] };
          this.roles.push(role);
        }
      });
    },
    async doLogoff() {
      try {
        await this.$auth.logout();
        Vue.$router.push('/_exterior/login');
      } catch (error) {
        this.requestError = `${error}`;
      }
    },
    async doResetPassword() {
      try {
        this.newPasswordError = undefined;
        this.repeatPasswordError = undefined;
        this.resetPassword.email = this.user.email!;
        // TODO: - can be validated through control
        if (!this.resetPassword.newPassword || this.resetPassword.newPassword.length < this.$config.values['auth-min-password-length']) {
          this.newPasswordError = this.$t('core.validation.minLength', this.$config.values['auth-min-password-length']);
          return;
        }
        if (this.resetPassword.newPassword !== this.resetPassword.repeatNewPassword) {
          this.repeatPasswordError = this.$t('core.validation.sameAs');
          return;
        }

        await this.$service.api.core.me.setPassword(this.resetPassword)
          .then(() => {
            this.doLogoff();
          });
      } catch (error) {
        this.requestError = error;
      }
    },
  },
});
