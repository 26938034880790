// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import LookupWorkloadSummaryRequestModel from '../../model/lookup-workload-summary-request-model';
import LookupWorkloadSummaryRequestDTO from '../dto/lookup-workload-summary-request-dto';
import dtoSchema from '../schemas/lookup-workload-summary-request-schema';

export default abstract class LookupWorkloadSummaryRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<LookupWorkloadSummaryRequestDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.year = data.year;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  * @type {int32}
  */
  year?: number;

  static toModel(dto: DeepPartial<LookupWorkloadSummaryRequestDTO>): LookupWorkloadSummaryRequestModel;
  static toModel(dto: DeepPartial<LookupWorkloadSummaryRequestDTO> | undefined | null): LookupWorkloadSummaryRequestModel | undefined;
  static toModel(dto: DeepPartial<LookupWorkloadSummaryRequestDTO> | undefined | null): LookupWorkloadSummaryRequestModel | undefined {
    return dto ? new LookupWorkloadSummaryRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<LookupWorkloadSummaryRequestModel> | LookupWorkloadSummaryRequestModel): LookupWorkloadSummaryRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      year: unwrapped.year,
    } as LookupWorkloadSummaryRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for LookupWorkloadSummaryRequestModel, can not map to LookupWorkloadSummaryRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
