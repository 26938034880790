// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import ModulePlanningLecturerResourceViewModel from '../../../../viewModel/resource/ModulePlanningLecturerResourceViewModel';
import ModulePlanningLecturerResourceDTO from '../../dto/resource/ModulePlanningLecturerResourceDTO';

export default abstract class ModulePlanningLecturerResourceModel extends BaseModel<ModulePlanningLecturerResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  */
  get fullName() { return this.dto.fullName; }
  set fullName(value) { this.dto.fullName = value; }
  /**
  * @type {int64}
  */
  get lessonAssignmentId() { return this.dto.lessonAssignmentId; }
  set lessonAssignmentId(value) { this.dto.lessonAssignmentId = value; }
  /**
  * @type {int64}
  */
  get assignedLessonId() { return this.dto.assignedLessonId; }
  set assignedLessonId(value) { this.dto.assignedLessonId = value; }
  /**
  * @type {int64}
  */
  get assignedClassId() { return this.dto.assignedClassId; }
  set assignedClassId(value) { this.dto.assignedClassId = value; }
  /**
  * @type {int64}
  */
  get lessonAssignmentStatusId() { return this.dto.lessonAssignmentStatusId; }
  set lessonAssignmentStatusId(value) { this.dto.lessonAssignmentStatusId = value; }
  /**
  */
  get lessonAssignmentStatusName() { return this.dto.lessonAssignmentStatusName; }
  set lessonAssignmentStatusName(value) { this.dto.lessonAssignmentStatusName = value; }
  /**
  */
  get lecturerAssignmentComment() { return this.dto.lecturerAssignmentComment; }
  set lecturerAssignmentComment(value) { this.dto.lecturerAssignmentComment = value; }
  /**
  */
  get lecturerAssignmentAdminComment() { return this.dto.lecturerAssignmentAdminComment; }
  set lecturerAssignmentAdminComment(value) { this.dto.lecturerAssignmentAdminComment = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    this.dto.fullName = qs.fullname;
    value = Number.parseFloat(qs.lessonassignmentid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.lessonAssignmentId = value;
    value = Number.parseFloat(qs.assignedlessonid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.assignedLessonId = value;
    value = Number.parseFloat(qs.assignedclassid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.assignedClassId = value;
    value = Number.parseFloat(qs.lessonassignmentstatusid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.lessonAssignmentStatusId = value;
    this.dto.lessonAssignmentStatusName = qs.lessonassignmentstatusname;
    this.dto.lecturerAssignmentComment = qs.lecturerassignmentcomment;
    this.dto.lecturerAssignmentAdminComment = qs.lecturerassignmentadmincomment;
  }
}
