

























































































































































































import Vue from 'vue';

export default Vue.extend({
  auth: true,
  data: () => ({
    date: Vue.$date.now().format('YYYY-MM-DD'),
    profileMenuVisible: false,
    isSidedrawerVisible: true,
    isSidedrawerMinified: false,
    isMobile: false,
    isSettingsVisible: false,
    isNavMinifed: false,
  }),
  computed: {
    defaultYear(): number {
      const date = Vue.$date(`${Vue.$date.now().year()}-07-31`);
      if (Vue.$date.now() > date) {
        return Vue.$date.now().year() + 1;
      }
      return Vue.$date.now().year();
    },
    user() {
      return this.$auth.user ?? {};
    },
    isPlanningAdmin() {
      return this.$service.v2.api.me.getAmIPlanningAdmin();
    },
    isAdmin() {
      let isAdmin = false;
      if (this.$auth.isLoggedIn) {
        isAdmin = this.$auth.isInRole('Admin', 'HostAdmin');
      }
      return isAdmin;
    },
    isLocalhost():boolean {
      const route = window.location.hostname;
      return route === 'localhost';
    },
  },
  watch: {
    isNavMinifed() {
      this.$sessionStorage.set('isNavMinifed', this.isNavMinifed);
    },
  },
  beforeMount() {
    if (this.$media.isNotMobile) {
      this.isSidedrawerVisible = this.$sessionStorage.get<boolean>('isSidedrawerVisible') ?? true;
      this.isSettingsVisible = this.isAdmin && (this.$sessionStorage.get<boolean>('isSettingsVisible') ?? false);
      this.isNavMinifed = this.$sessionStorage.get<boolean>('isNavMinifed') ?? false;
    }
  },
  methods: {
    async doLogoff() {
      try {
        await this.$auth.logout();
        this.$router.push('/_exterior/login');
      } catch (error) {
        this.$log.error('Unable to log out an error occurred');
        this.$log.error(error);
      }
    },
    toggleSettings() {
      this.isSettingsVisible = !this.isSettingsVisible;
      if (this.$media.isMobile) {
        this.toggleSideDrawerVisible(false);
      } else {
        this.isNavMinifed = this.isSettingsVisible;
      }
      this.$sessionStorage.set('isSettingsVisible', this.isSettingsVisible);
    },
    navigationClicked() {
      if (this.$media.isMobile) {
        this.toggleSideDrawerVisible(false);
      }
    },
    settingsClicked() {
      if (this.$media.isMobile) {
        this.toggleSettings();
      }
    },
    toggleSideDrawerVisible(isVisible?: boolean) {
      const newVisibleState = isVisible ?? !this.isSidedrawerVisible;
      this.isSidedrawerVisible = newVisibleState;
      this.$sessionStorage.set('isSidedrawerVisible', this.isSidedrawerVisible);
    },
  },
});
