// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import WidgetClassFaqModel from '../../model/widget-class-faq-model';
import WidgetClassFaqDTO from '../dto/widget-class-faq-dto';
import dtoSchema from '../schemas/widget-class-faq-schema';
import WidgetClassContentItemModel from '../../model/widget-class-content-item-model';

export default abstract class WidgetClassFaqModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<WidgetClassFaqDTO>) {
    super();
    if (data) {
      this.order = data.order;
      this.title = data.title ?? undefined;
      this.content = data.content?.map((i) => WidgetClassContentItemModel.toModel(i)) ?? [];
    }
  }
  /**
  * @type {int32}
  */
  order?: number;
  /**
  */
  title?: string;
  /**
  */
  content?: WidgetClassContentItemModel[];

  static toModel(dto: DeepPartial<WidgetClassFaqDTO>): WidgetClassFaqModel;
  static toModel(dto: DeepPartial<WidgetClassFaqDTO> | undefined | null): WidgetClassFaqModel | undefined;
  static toModel(dto: DeepPartial<WidgetClassFaqDTO> | undefined | null): WidgetClassFaqModel | undefined {
    return dto ? new WidgetClassFaqModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<WidgetClassFaqModel> | WidgetClassFaqModel): WidgetClassFaqDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      order: unwrapped.order,
      title: unwrapped.title,
      content: unwrapped.content,
    } as WidgetClassFaqDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for WidgetClassFaqModel, can not map to WidgetClassFaqDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
