














import Vue, { PropType } from 'vue';
import BookingCommentComponentModel from './agogis-booking-comment.vue.model.';

export default Vue.extend({
  name: 'AgogisBookingCommentComponent',
  props: {
    comments: { type: Array as PropType<string[]>, default: () => [] as string[] },
    isReadOnly: { default: true, type: Boolean },
    label: { default: '', type: String },
  },
  data: () => ({
    model: new BookingCommentComponentModel(),
  }),
  watch: {
    comments: {
      immediate: true,
      handler() {
        this.model.updateComments(this.comments);
      },
    },
  },
  async mounted() {
    this.model.initialize(this.label, this.isReadOnly, this.comments);
  },
});
