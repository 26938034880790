// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ModulePlanningLessonModel from '../../model/module-planning-lesson-model';
import ModulePlanningLessonDTO from '../dto/module-planning-lesson-dto';
import dtoSchema from '../schemas/module-planning-lesson-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class ModulePlanningLessonModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ModulePlanningLessonDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.lessonName = data.lessonName ?? undefined;
      this.startTime = data.startTime ?? undefined;
      this.endTime = data.endTime ?? undefined;
      this.duration = data.duration;
      this.isPrototype = data.isPrototype;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  */
  lessonName?: string;
  /**
  * @type {date-time}
  */
  startTime?: string;
  /**
  * @type {date-time}
  */
  endTime?: string;
  /**
  * @type {double}
  */
  duration?: number;
  /**
  */
  isPrototype?: boolean;

  static toModel(dto: DeepPartial<ModulePlanningLessonDTO>): ModulePlanningLessonModel;
  static toModel(dto: DeepPartial<ModulePlanningLessonDTO> | undefined | null): ModulePlanningLessonModel | undefined;
  static toModel(dto: DeepPartial<ModulePlanningLessonDTO> | undefined | null): ModulePlanningLessonModel | undefined {
    return dto ? new ModulePlanningLessonModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ModulePlanningLessonModel> | ModulePlanningLessonModel): ModulePlanningLessonDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      lessonName: unwrapped.lessonName,
      startTime: unwrapped.startTime,
      endTime: unwrapped.endTime,
      duration: unwrapped.duration,
      isPrototype: unwrapped.isPrototype,
    } as ModulePlanningLessonDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ModulePlanningLessonModel, can not map to ModulePlanningLessonDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
