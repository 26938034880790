// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ReferentListModel from '../../model/referent-list-model';
import ReferentListDTO from '../dto/referent-list-dto';
import dtoSchema from '../schemas/referent-list-schema';
import ResourceLinkModel from '../../model/resource-link-model';
import Int64LookupModel from '../../model/int64-lookup-model';

export default abstract class ReferentListModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ReferentListDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.caption = data.caption ?? undefined;
      this.details = data.details ?? undefined;
      this.group = data.group ? Int64LookupModel.toModel(data.group) : undefined;
      this.sortOrder = data.sortOrder;
      this.employeeNumber = data.employeeNumber;
      this.firstName = data.firstName ?? undefined;
      this.lastName = data.lastName ?? undefined;
      this.fullName = data.fullName ?? undefined;
      this.referentTypes = data.referentTypes?.map((i) => Int64LookupModel.toModel(i)) ?? [];
      this.schedulerResourceType = data.schedulerResourceType;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  */
  caption?: string;
  /**
  */
  details?: string[];
  /**
  */
  group?: Int64LookupModel;
  /**
  * @type {int32}
  */
  sortOrder?: number;
  /**
  * @type {int32}
  */
  employeeNumber?: number;
  /**
  */
  firstName?: string;
  /**
  */
  lastName?: string;
  /**
  * @readonly
  */
  fullName?: string;
  /**
  */
  referentTypes?: Int64LookupModel[];
  /**
  * @type {int32}
  */
  schedulerResourceType?: 0 | 1 | 2 | 3 | 4 | 5; // ResourceTypeDTO

  static toModel(dto: DeepPartial<ReferentListDTO>): ReferentListModel;
  static toModel(dto: DeepPartial<ReferentListDTO> | undefined | null): ReferentListModel | undefined;
  static toModel(dto: DeepPartial<ReferentListDTO> | undefined | null): ReferentListModel | undefined {
    return dto ? new ReferentListModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ReferentListModel> | ReferentListModel): ReferentListDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      caption: unwrapped.caption,
      details: unwrapped.details,
      group: unwrapped.group,
      sortOrder: unwrapped.sortOrder,
      employeeNumber: unwrapped.employeeNumber,
      firstName: unwrapped.firstName,
      lastName: unwrapped.lastName,
      fullName: unwrapped.fullName,
      referentTypes: unwrapped.referentTypes,
      schedulerResourceType: unwrapped.schedulerResourceType,
    } as ReferentListDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ReferentListModel, can not map to ReferentListDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
