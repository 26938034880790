// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import Int32LookupListResultModel from '../../model/int32-lookup-list-result-model';
import Int32LookupListResultDTO from '../dto/int32-lookup-list-result-dto';
import dtoSchema from '../schemas/int32-lookup-list-result-schema';
import Int32LookupModel from '../../model/int32-lookup-model';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class Int32LookupListResultModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<Int32LookupListResultDTO>) {
    super();
    if (data) {
      this.items = data.items?.map((i) => Int32LookupModel.toModel(i)) ?? [];
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
    }
  }
  /**
  */
  items?: Int32LookupModel[];
  /**
  */
  links?: ResourceLinkModel[];

  static toModel(dto: DeepPartial<Int32LookupListResultDTO>): Int32LookupListResultModel;
  static toModel(dto: DeepPartial<Int32LookupListResultDTO> | undefined | null): Int32LookupListResultModel | undefined;
  static toModel(dto: DeepPartial<Int32LookupListResultDTO> | undefined | null): Int32LookupListResultModel | undefined {
    return dto ? new Int32LookupListResultModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<Int32LookupListResultModel> | Int32LookupListResultModel): Int32LookupListResultDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      items: unwrapped.items,
      links: unwrapped.links,
    } as Int32LookupListResultDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for Int32LookupListResultModel, can not map to Int32LookupListResultDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
