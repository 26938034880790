// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import RequestSummarySectionResourceViewModel from '../../../../viewModel/resource/RequestSummarySectionResourceViewModel';
import RequestSummaryResourceViewModel from '../../../../viewModel/resource/RequestSummaryResourceViewModel';
import RequestSummaryResourceDTO from '../../dto/resource/RequestSummaryResourceDTO';

export default abstract class RequestSummaryResourceModel extends BaseModel<RequestSummaryResourceDTO> {
  /**
  */
  get sections() { return this.getArrayModels(() => this.dto.sections, RequestSummarySectionResourceViewModel)!; }
  set sections(value) { this.setArrayModels(() => this.dto.sections, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
  }
}
