








export default {
  data: () => ({
  }),
};
