// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';

const schema = {
  $id: '/lecture-lesson-history-list-schema',
  type: 'object',
  required: ['id', 'creatorId', 'lectureLessonId', 'lecturerId'],
  nullable: true,
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    creatorId: {
      type: 'integer',
    },
    creatorName: {
      type: 'string',
      nullable: true,
    },
    created: {
      type: 'string',
      format: 'date-time',
    },
    modifierId: {
      type: 'integer',
      nullable: true,
    },
    modifierName: {
      type: 'string',
      nullable: true,
    },
    modified: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    lectureLessonId: {
      type: 'integer',
    },
    lecturerId: {
      type: 'integer',
    },
    lecturerName: {
      type: 'string',
      nullable: true,
    },
    statusOld: {
      type: 'string',
      nullable: true,
    },
    statusNew: {
      type: 'string',
      nullable: true,
    },
    comment: {
      type: 'string',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
