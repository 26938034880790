// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import LectureWorkloadSummaryItemResourceViewModel from '../../../../viewModel/resource/LectureWorkloadSummaryItemResourceViewModel';
import LectureWorkloadSummaryItemResourceDTO from '../../dto/resource/LectureWorkloadSummaryItemResourceDTO';

export default abstract class LectureWorkloadSummaryItemResourceModel extends BaseModel<LectureWorkloadSummaryItemResourceDTO> {
  /**
  */
  get name() { return this.dto.name; }
  set name(value) { this.dto.name = value; }
  /**
  * @type {double}
  */
  get totalDays() { return this.dto.totalDays; }
  set totalDays(value) { this.dto.totalDays = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.name = qs.name;
    value = Number.parseFloat(qs.totaldays);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.totalDays = value;
  }
}
