






































































































































import Vue from 'vue';
import LessonDetailPage from './lesson-detail.vue.model';

export default Vue.extend({
  auth: true,
  data: () => ({
    isLoading: false,
    page: new LessonDetailPage(),
    errorMessage: undefined as string | undefined,
    successMessage: undefined as string | undefined,
  }),
  computed: {
    lessonStartEndTimeString() {
      return `${this.$format.localDateAndTime(this.page.lesson.startTime!)}-${this.$format.localTime(this.page.lesson.endTime!)} (${this.page.lesson.duration}d)`;
    },
  },
  async mounted() {
    this.page.initialize();
  },
  methods: {
    onFilterChangeClick() {
      this.page.onFilterChange();
    },
    onRefreshDataSourcesClick() {
      this.page.lessonHistoryDataSource.select();
    },
    getFormattedDisplay(row: any, column: any) {
      const value = row[column.field];
      if (typeof column.format === 'function') {
        return column.format(value);
      }
      return value;
    },
    back() {
      this.$router.back();
    },
  },
});
