
















































import Vue from 'vue';
import WidgetPoiPage from './widget-poi.vue.model';

export default Vue.extend({
  layout: 'layout-widget',
  auth: false,
  data: () => ({
    page: new WidgetPoiPage(),
  }),
  async mounted() {
    this.page.initialize();
  },
});
