// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import RequestSummaryItemResourceViewModel from '../../../../viewModel/resource/RequestSummaryItemResourceViewModel';
import RequestSummaryItemResourceDTO from '../../dto/resource/RequestSummaryItemResourceDTO';

export default abstract class RequestSummaryItemResourceModel extends BaseModel<RequestSummaryItemResourceDTO> {
  /**
  */
  get label() { return this.dto.label; }
  set label(value) { this.dto.label = value; }
  /**
  */
  get displayValue() { return this.dto.displayValue; }
  set displayValue(value) { this.dto.displayValue = value; }
  /**
  */
  get oldDisplayValue() { return this.dto.oldDisplayValue; }
  set oldDisplayValue(value) { this.dto.oldDisplayValue = value; }
  /**
  */
  get isLink() { return this.dto.isLink; }
  set isLink(value) { this.dto.isLink = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.label = qs.label;
    this.dto.displayValue = qs.displayvalue;
    this.dto.oldDisplayValue = qs.olddisplayvalue;
    value = qs.isLink === 'true' ? true : qs.islink;
    value = qs.isLink === 'false' ? false : undefined;
    this.dto.isLink = value;
  }
}
