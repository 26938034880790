






































































































































































































































































































































































import Vue from 'vue';
import AgogisTable from '../../../../../components/agogis-table.vue';
import LessonListPage from './lesson-list.vue.model';
import AgogisAssignToLessonForm from './agogis-assign-to-lesson-form.vue';

export default Vue.extend({
  auth: true,
  components: { AgogisTable, AgogisAssignToLessonForm },
  data: () => ({
    page: new LessonListPage(),
    visible: true,
  }),
  async mounted() {
    this.page.initialize();
  },
  methods: {
  },
});
