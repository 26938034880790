// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';

const schema = {
  $id: '/lecture-lesson-schema',
  type: 'object',
  required: ['id', 'duration', 'toccoLessonId'],
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    lectureClassId: {
      type: 'integer',
      nullable: true,
    },
    lectureClassName: {
      type: 'string',
      nullable: true,
    },
    lectureModuleId: {
      type: 'integer',
      nullable: true,
    },
    lectureModuleName: {
      type: 'string',
      nullable: true,
    },
    lessonName: {
      type: 'string',
      nullable: true,
    },
    startTime: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    endTime: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    duration: {
      type: 'number',
    },
    toccoLessonId: {
      type: 'integer',
    },
    toccoClassId: {
      type: 'integer',
      nullable: true,
    },
    teamTeaching: {
      type: 'boolean',
    },
  },
} as IJSONSchema;

export default schema;
