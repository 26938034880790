



































































































































import Vue from 'vue';
import ServiceResponse from '@glittr/frontend-core/src/plugins/servicelayer/responseTypes/serviceResponse';

export default Vue.extend({
  layout: 'layout-widget',
  data: () => ({
    appHealth: undefined as any,
    postResult: '' as any,
  }),
  async mounted() {
    try {
      const response = await this.$service.api.core.app.getHealth();
      this.appHealth = response.data;
    } catch (e) {
      const errorResponse = e as ServiceResponse;
      if (errorResponse.status === 503) {
        this.appHealth = errorResponse.data;
      }
      console.error(e);
    }
  },
  methods:
    {
      async doVerify() {
        try {
          this.postResult = await this.$service.api.widget.postHello('hello');
        } catch (error: any) {
          console.log(error);
        }
      },
    },
});
