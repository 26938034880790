import Vue from 'vue';
import core, { CoreOptions } from '@glittr/frontend-core';
import toccoWidgets from '@glittr/frontend-core/src-modules/tocco/widgets';
import router from './router';
import servicesV1 from './services/v1';
import servicesV2 from './services/v2';
import PScheduler from './components/p-scheduler.vue';
import agogisLanguageSelection from './components/agogis-language-selection.vue';
import agogisWidgetCourseCard from './components/agogis-widget-course-card.vue';
import agogisYearLookup from './components/lookup/agogis-year-lookup.vue';
import agogisClassLookup from './components/lookup/agogis-class-lookup.vue';
import agogisModuleLookup from './components/lookup/agogis-module-lookup.vue';
import agogisLecturerAssignmentStatusLookup from './components/lookup/agogis-lecturer-assignment-status-lookup.vue';
import agogisLecturerLookup from './components/lookup/agogis-lecturer-lookup.vue';
import agogisToggleSelection from './components/agogis-toggle-selection.vue';

Vue.config.productionTip = false;

const coreOptions = {
  // appId: 'custom-mount-id'
  router,
  Vue,
} as CoreOptions;
// Core entry point
Vue.use(core, coreOptions);
Vue.nextTick(() => {
  toccoWidgets.loadModule(coreOptions, Vue);
});

// APP____________________________________________________________

// STYLES
require('./assets/styles/main.scss');

// PLUGINS
Vue.use(servicesV1);
Vue.use(servicesV2);

// COMPONENTS
Vue.component('PScheduler', PScheduler);
Vue.component('AgogisLanguageSelection', agogisLanguageSelection);
Vue.component('AgogisWidgetCourseCard', agogisWidgetCourseCard);
Vue.component('AgogisYearLookup', agogisYearLookup);
Vue.component('AgogisClassLookup', agogisClassLookup);
Vue.component('AgogisModuleLookup', agogisModuleLookup);
Vue.component('AgogisLecturerAssignmentStatusLookup', agogisLecturerAssignmentStatusLookup);
Vue.component('AgogisLecturerLookup', agogisLecturerLookup);
Vue.component('AgogisToggleSelection', agogisToggleSelection);

// GUARDS
// Vue.$guards.push(yourGuard);

// LAYOUTS
const reqLayouts = require.context('./views/layouts', true);
reqLayouts.keys().forEach((componentPath) => {
  let componentName = componentPath.substr(componentPath.lastIndexOf('/') + 1, componentPath.length - 1);
  componentName = componentName.replace('.vue', '');
  const component = reqLayouts(componentPath).default;
  Vue.component(componentName, component);
});

// _______________________________________________________________

require('./store');

declare module '@core/plugins/config/config' {
  interface ConfigType {

    /* Cockpit-config */
    'cockpit-business-begins-hour': number | string,
    'cockpit-business-ends-hour': number | string,

    /* Widget-config */
    'widget-toccofilter'?: string,
    'widget-pagesize'?: number,
    'widget-toccocoursekey': string,
    'widget-course-event-type-config'?: Record<string, {dateText?: string, hasAppointments: boolean}>,
    'widget-textresource-course':boolean,
    'widget-textresource-showtitle':boolean,
    'widget-textresource-showsummary':boolean,
    'widget-textresource-showaccordion':boolean,
    'widget-textresource-showprint':boolean,
    'cockpit-prefix': string

    /* POI Widget */
    /**
     * The number of seconds between switching pages
     * If there is only one page it will refresh that page after the specified seconds
     * If undefined or 0 is provided the page will not refresh or cycle at all
     */
    'widget-poi-autorefresh'?: number,
  }
}
