// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import LookupEventTypesRequestModel from '../../model/lookup-event-types-request-model';
import LookupEventTypesRequestDTO from '../dto/lookup-event-types-request-dto';
import dtoSchema from '../schemas/lookup-event-types-request-schema';

export default abstract class LookupEventTypesRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<LookupEventTypesRequestDTO>) {
    super();
  }

  static toModel(dto: DeepPartial<LookupEventTypesRequestDTO>): LookupEventTypesRequestModel;
  static toModel(dto: DeepPartial<LookupEventTypesRequestDTO> | undefined | null): LookupEventTypesRequestModel | undefined;
  static toModel(dto: DeepPartial<LookupEventTypesRequestDTO> | undefined | null): LookupEventTypesRequestModel | undefined {
    return dto ? new LookupEventTypesRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<LookupEventTypesRequestModel> | LookupEventTypesRequestModel): LookupEventTypesRequestDTO {
    const dto = {
    } as LookupEventTypesRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for LookupEventTypesRequestModel, can not map to LookupEventTypesRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
