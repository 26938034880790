// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import DynamicFormPicklistItemDefinitionSchema from './dynamic-form-picklist-item-definition-schema';

const schema = {
  $id: '/dynamic-form-field-definition-schema',
  type: 'object',
  required: ['id', 'order', 'inputType'],
  nullable: true,
  properties: {
    id: {
      type: 'string',
      minLength: 1,
    },
    caption: {
      type: 'string',
      nullable: true,
    },
    order: {
      type: 'integer',
    },
    inputType: {
      enum: [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
      ],
    },
    isRequired: {
      type: 'boolean',
    },
    isMultiInput: {
      type: 'boolean',
    },
    isClearable: {
      type: 'boolean',
    },
    picklistItems: {
      type: 'array',
      nullable: true,
      items: { ...DynamicFormPicklistItemDefinitionSchema },
    },
    min: {
      type: 'string',
      nullable: true,
    },
    max: {
      type: 'string',
      nullable: true,
    },
    defaultValue: {
      type: 'string',
      nullable: true,
    },
    placeholder: {
      type: 'string',
      nullable: true,
    },
    description: {
      type: 'string',
      nullable: true,
    },
    decimalPlaces: {
      type: 'integer',
      nullable: true,
    },
    items: {
      type: 'array',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
