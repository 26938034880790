// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';
import LectureLessonStatusSchema from './lecture-lesson-status-schema';

const schema = {
  $id: '/lecture-lesson-list-schema',
  type: 'object',
  required: ['id', 'moduleTotalDuration', 'duration', 'classStartYear'],
  nullable: true,
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    lectureClassId: {
      type: 'integer',
      nullable: true,
    },
    lectureModuleId: {
      type: 'integer',
      nullable: true,
    },
    moduleName: {
      type: 'string',
      nullable: true,
    },
    lessonName: {
      type: 'string',
      nullable: true,
    },
    primaryLeadLecturerId: {
      type: 'integer',
      nullable: true,
    },
    secondaryLeadLecturerId: {
      type: 'integer',
      nullable: true,
    },
    lecturerId: {
      type: 'integer',
      nullable: true,
    },
    lecturerRoleId: {
      type: 'integer',
      nullable: true,
    },
    primaryLeadLecturerFirstName: {
      type: 'string',
      nullable: true,
    },
    primaryLeadLecturerLastName: {
      type: 'string',
      nullable: true,
    },
    className: {
      type: 'string',
      nullable: true,
    },
    lecturerRoleName: {
      type: 'string',
      nullable: true,
    },
    lecturerFirstName: {
      type: 'string',
      nullable: true,
    },
    lecturerLastName: {
      type: 'string',
      nullable: true,
    },
    lecturerStatus: {
      ...LectureLessonStatusSchema,
    },
    lecturerComment: {
      type: 'string',
      nullable: true,
    },
    lecturerAdminComment: {
      type: 'string',
      nullable: true,
    },
    startTime: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    endTime: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    moduleStartDate: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    moduleEndDate: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    moduleTotalDuration: {
      type: 'number',
    },
    duration: {
      type: 'number',
    },
    classIsPrototype: {
      type: 'boolean',
    },
    classStartYear: {
      type: 'integer',
    },
    teamTeaching: {
      type: 'boolean',
    },
    bookingAction: {
      enum: [
        0,
        1,
        2,
        4,
      ],
    },
  },
} as IJSONSchema;

export default schema;
