// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetReferentLookupRequestModel from '../../model/get-referent-lookup-request-model';
import GetReferentLookupRequestDTO from '../dto/get-referent-lookup-request-dto';
import dtoSchema from '../schemas/get-referent-lookup-request-schema';

export default abstract class GetReferentLookupRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetReferentLookupRequestDTO>) {
    super();
    this.paging = {};
    if (data) {
      this.paging = {
        currentPage: data['paging.CurrentPage'],
        pageSize: data['paging.PageSize'],
        includeCount: data['paging.IncludeCount'],
        noPaging: data['paging.NoPaging'],
      };
      this.id = data.id;
      this.key = data.key;
      this.searchCriteria = data.searchCriteria;
    }
  }

  paging: {
    /**
    * @type {int32}
    */
    currentPage?: number;
    /**
    * @type {int32}
    */
    pageSize?: number;
    /**
    */
    includeCount?: boolean;
    /**
    */
    noPaging?: boolean;
  };

  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  key?: string;
  /**
  */
  searchCriteria?: string;

  static toModel(dto: DeepPartial<GetReferentLookupRequestDTO>): GetReferentLookupRequestModel;
  static toModel(dto: DeepPartial<GetReferentLookupRequestDTO> | undefined | null): GetReferentLookupRequestModel | undefined;
  static toModel(dto: DeepPartial<GetReferentLookupRequestDTO> | undefined | null): GetReferentLookupRequestModel | undefined {
    return dto ? new GetReferentLookupRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetReferentLookupRequestModel> | GetReferentLookupRequestModel): GetReferentLookupRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      'paging.CurrentPage': unwrapped.paging?.currentPage,
      'paging.PageSize': unwrapped.paging?.pageSize,
      'paging.IncludeCount': unwrapped.paging?.includeCount,
      'paging.NoPaging': unwrapped.paging?.noPaging,
      id: unwrapped.id,
      key: unwrapped.key,
      searchCriteria: unwrapped.searchCriteria,
    } as GetReferentLookupRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for GetReferentLookupRequestModel, can not map to GetReferentLookupRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
