// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import DynamicFormFieldPayloadModel from '../../model/dynamic-form-field-payload-model';
import DynamicFormFieldPayloadDTO from '../dto/dynamic-form-field-payload-dto';
import dtoSchema from '../schemas/dynamic-form-field-payload-schema';

export default abstract class DynamicFormFieldPayloadModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<DynamicFormFieldPayloadDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.values = data.values;
    }
  }
  /**
  * @minimum 1
  */
  id?: string;
  /**
  */
  values?: any[];

  static toModel(dto: DeepPartial<DynamicFormFieldPayloadDTO>): DynamicFormFieldPayloadModel;
  static toModel(dto: DeepPartial<DynamicFormFieldPayloadDTO> | undefined | null): DynamicFormFieldPayloadModel | undefined;
  static toModel(dto: DeepPartial<DynamicFormFieldPayloadDTO> | undefined | null): DynamicFormFieldPayloadModel | undefined {
    return dto ? new DynamicFormFieldPayloadModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<DynamicFormFieldPayloadModel> | DynamicFormFieldPayloadModel): DynamicFormFieldPayloadDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      values: unwrapped.values,
    } as DynamicFormFieldPayloadDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for DynamicFormFieldPayloadModel, can not map to DynamicFormFieldPayloadDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
