// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import AppointmentListModel from '../../model/appointment-list-model';
import AppointmentListDTO from '../dto/appointment-list-dto';
import dtoSchema from '../schemas/appointment-list-schema';
import ResourceLinkModel from '../../model/resource-link-model';
import Int64LookupModel from '../../model/int64-lookup-model';
import RoomListModel from '../../model/room-list-model';

export default abstract class AppointmentListModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<AppointmentListDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.caption = data.caption ?? undefined;
      this.details = data.details ?? undefined;
      this.group = data.group ? Int64LookupModel.toModel(data.group) : undefined;
      this.sortOrder = data.sortOrder;
      this.isAllDay = data.isAllDay;
      this.startTime = data.startTime;
      this.endTime = data.endTime;
      this.bookedRooms = data.bookedRooms?.map((i) => RoomListModel.toModel(i)) ?? [];
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  */
  caption?: string;
  /**
  */
  details?: string[];
  /**
  */
  group?: Int64LookupModel;
  /**
  * @type {int32}
  */
  sortOrder?: number;
  /**
  */
  isAllDay?: boolean;
  /**
  * @type {date-time}
  */
  startTime?: string;
  /**
  * @type {date-time}
  */
  endTime?: string;
  /**
  */
  bookedRooms?: RoomListModel[];

  static toModel(dto: DeepPartial<AppointmentListDTO>): AppointmentListModel;
  static toModel(dto: DeepPartial<AppointmentListDTO> | undefined | null): AppointmentListModel | undefined;
  static toModel(dto: DeepPartial<AppointmentListDTO> | undefined | null): AppointmentListModel | undefined {
    return dto ? new AppointmentListModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<AppointmentListModel> | AppointmentListModel): AppointmentListDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      caption: unwrapped.caption,
      details: unwrapped.details,
      group: unwrapped.group,
      sortOrder: unwrapped.sortOrder,
      isAllDay: unwrapped.isAllDay,
      startTime: unwrapped.startTime,
      endTime: unwrapped.endTime,
      bookedRooms: unwrapped.bookedRooms,
    } as AppointmentListDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for AppointmentListModel, can not map to AppointmentListDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
