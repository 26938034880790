// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetWidgetClassesRequestModel from '../../model/get-widget-classes-request-model';
import GetWidgetClassesRequestDTO from '../dto/get-widget-classes-request-dto';
import dtoSchema from '../schemas/get-widget-classes-request-schema';
import ApiPagingRequestModel from '../../model/api-paging-request-model';

export default abstract class GetWidgetClassesRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetWidgetClassesRequestDTO>) {
    super();
    if (data) {
      this.searchCriteria = data.searchCriteria ?? undefined;
      this.paging = data.paging ? ApiPagingRequestModel.toModel(data.paging) : undefined;
      this.toccoFilter = data.toccoFilter;
      this.searchTag = data.searchTag ?? undefined;
    }
  }
  /**
  */
  searchCriteria?: string;
  /**
  */
  paging?: ApiPagingRequestModel;
  /**
  * @minimum 1
  */
  toccoFilter?: string;
  /**
  */
  searchTag?: string;

  static toModel(dto: DeepPartial<GetWidgetClassesRequestDTO>): GetWidgetClassesRequestModel;
  static toModel(dto: DeepPartial<GetWidgetClassesRequestDTO> | undefined | null): GetWidgetClassesRequestModel | undefined;
  static toModel(dto: DeepPartial<GetWidgetClassesRequestDTO> | undefined | null): GetWidgetClassesRequestModel | undefined {
    return dto ? new GetWidgetClassesRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetWidgetClassesRequestModel> | GetWidgetClassesRequestModel): GetWidgetClassesRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      searchCriteria: unwrapped.searchCriteria,
      paging: unwrapped.paging,
      toccoFilter: unwrapped.toccoFilter,
      searchTag: unwrapped.searchTag,
    } as GetWidgetClassesRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for GetWidgetClassesRequestModel, can not map to GetWidgetClassesRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
