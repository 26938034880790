






















import { DayPilot, DayPilotScheduler } from 'daypilot-pro-vue';
import Vue, { PropType } from 'vue';
import GetPublicHolidaysRequestModel from '../services/v2/model/get-public-holidays-request-model';

type RowHeaderType = {[name: string]: any, id: any, children?: RowHeaderType[], expanded?: boolean, tags?: any};
type RowHeaderColumnType = {field: string, value: any, width:number};

export default Vue.extend({
  name: 'PScheduler',
  components: {
    // DayPilotCalendar,
    DayPilotScheduler,
  },
  props: {
    loading: { type: Boolean, default: false },
    rowHeaderColumns: { type: Array as PropType<RowHeaderColumnType[]>, default: () => [] },
    rowHeaders: { type: Array as PropType<RowHeaderType[]>, default: () => [] },
    events: { type: Array as PropType<DayPilot.Event[]>, default: () => [] },
    value: { type: String as PropType<string>, default: undefined },
    timeScale: { type: String as PropType<DayPilot.SchedulerPropsAndEvents['scale']>, default: 'Day' },
  },
  data: () => ({
    holidays: [] as DayPilot.Event[],
    cornerRect: {} as DOMRect,
    component: DayPilotScheduler,
    timeRangeStart: Vue.$date.now().format('YYYY-MM-DD'),
    schedulerInitalized: false,
    lastScrollStartValue: undefined,
    lastScrollEndValue: undefined,
    collapsedRowHeaders: {} as Record<string, boolean>,
    config: {
      sortDirections: ['desc', 'asc'],
      locale: 'de-ch',
      eventMoveHandling: 'Disabled',
      eventResizeHandling: 'Disabled',
      showAllDayEvents: true,
      treeEnabled: true,
      businessBeginsHour: Vue.$config.values['cockpit-business-begins-hour'],
      businessEndsHour: Vue.$config.values['cockpit-business-ends-hour'],
      businessWeekends: true,
      showNonBusiness: false,
      dynamicEventRendering: 'Disabled',
      dynamicLoading: true,
      heightSpec: 'Parent100Pct',
      eventTextWrappingEnabled: true,
      cellDuration: 15,
      cellWidth: 300,
      cellHeight: 20,
      eventHeight: 20,
      rowMinHeight: 30,
      rowMarginTop: 2, // abstand vom ersten böxli zur zeilenoberkante
      rowMarginBottom: 2, // abstand vom letzten böxli zur zeilenunterkante
      // allowEventOverlap: true, // macht nix?
      // eventArrangement: 'Full', // macht nix?
      theme: 'agogis', // theme (siehe daypilot.scss)
      rowHeaderWidthAutoFit: false, // https://doc.daypilot.org/scheduler/row-header-width-auto-fit/
      rowHeaderHideIconEnabled: false,
      rowHeaderColumnsResizable: true,
      rowHeaderColumnResizedHandling: 'Update',
      rowHeaderColumnDefaultWidth: 200, // https://api.daypilot.org/daypilot-scheduler-rowheadercolumndefaultwidth/
      rowHeaderWidthMarginRight: 5, // https://api.daypilot.org/daypilot-scheduler-rowheaderwidthmarginright/
      rowHeaderColumns: [],
      resources: [],
      events: [],
    } as DayPilot.SchedulerPropsAndEvents,
  }),
  computed: {
    yearsVisible(): number[] {
      const startTimeObj = this.$date(this.timeRangeStart!);
      const endTimeObj = this.$date(this.getTimeRangeEnd()!);
      const startYear = startTimeObj?.year() ?? this.$date.now().year();
      const endYear = endTimeObj?.year() ?? this.$date.now().year();
      if (startYear === endYear) {
        return [startYear];
      }
      return [startYear, endYear];
    },
    // DayPilot.Scheduler object - https://api.daypilot.org/daypilot-scheduler-class/
    scheduler(): DayPilot.Scheduler {
      const scheduler = this.$refs.scheduler as InstanceType<typeof DayPilotScheduler>;
      return scheduler.control;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value && this.timeRangeStart !== this.value) {
          this.$withoutWatchers(() => {
            this.timeRangeStart = this.value;
          });
        }
        this.refresh();
      },
    },
    timeScale: {
      immediate: true,
      handler() {
        this.changeTimeScale(this.timeScale as DayPilot.SchedulerPropsAndEvents['scale']);
      },
    },
    timeRangeStart: {
      handler() {
        if (!this._.isSet(this.timeRangeStart)) {
          this.timeRangeStart = Vue.$date.now().format('YYYY-MM-DD');
          return;
        }
        this.$debounce(() => {
          this.loadHolidays(this.timeRangeStart, this.getTimeRangeEnd());
        }, 1000, this)();
        this.refresh();
      },
    },
    events: {
      immediate: true,
      handler() {
        const mappedHolidays = [] as any[];
        this.rowHeaders.forEach((rowHeader) => {
          this.holidays.forEach((holiday) => {
            mappedHolidays.push({ ...holiday, resource: rowHeader.id, id: DayPilot.guid() });
          });
        });
        (this.config as any).events = [...this.events, ...mappedHolidays];
      },
    },
    rowHeaders: {
      immediate: true,
      handler() {
        if (this.config.resources) {
          // TODO: This actually needs converting so there are no collisions with property names
          // Only "id" should be a root prop, all others should be within "tags"
          this.config.resources = this.rowHeaders;

          this.config.resources.forEach((res) => {
            const { id } = res;
            const isCollapsed = this.collapsedRowHeaders[id];
            if (isCollapsed !== undefined) {
              if (isCollapsed) {
                res.expanded = false;
              } else {
                res.expanded = true;
              }
            }
          });
        }
      },
    },
    rowHeaderColumns: {
      immediate: true,
      handler() {
        if (this.config.rowHeaderColumns) {
          this.config.rowHeaderColumns = this.rowHeaderColumns.map((h) => ({ text: h.value, display: h.field, width: h.width }));
        }
      },
    },
  },
  async beforeMount() {
    const self = this;
    // All of these configs will only be set once on page load
    this.config.locale = this.getLocale();
    this.config.onResourceExpand = (args) => {
      const id = args?.resource?.id;
      this.onRowHeaderGroupCollapse(false, id);
    };

    this.config.onBeforeCornerRender = (args) => {
      const isColumnHidden = (this.config.rowHeaderColumns?.length ?? 0) <= 1;
      args.areas = [
        {
          onClick() { self.toggleRowHeaderColumns(); },
          top: 0,
          bottom: 0,
          right: 0,
          width: 32,
          html: `<div style="cursor: pointer;"><span class="material-icons">${isColumnHidden ? 'chevron_right' : 'chevron_left'}</span></div>`,
        },
      ];
    };

    this.config.onResourceCollapse = (args) => {
      const id = args?.resource?.id;
      this.onRowHeaderGroupCollapse(true, id);
    };
    this.config.onBeforeRowHeaderRender = (args) => {
      // truncate text for row headers
      args.row.columns.forEach((column: any) => {
        if (column.text) {
          column.toolTip = column.text;
          column.text = `${Vue.$format.ellipsis(column.text, 60)}`;
        }
      });
    };

    this.config.onBeforeCellRender = (args) => {
      // Typescript does not recognise cssClass for some reason
      const cell = (args?.cell ?? {}) as DayPilot.Cell & {cssClass: string};
      const y = cell.y ?? 0;
      const isEven = y % 2;
      cell.cssClass = isEven ? 'even' : 'odd';
      const cellEndDate = this.$date(cell.end.toDate());
      const isFullHour = cellEndDate.minute() === 0 && cellEndDate.second() === 0;
      const isWide = (this.config.cellWidth ?? 0) > 10;
      if (isWide || isFullHour) {
        cell.cssClass += ' cell-seperator';
      }
      const cellResId = cell.resource;
      const allRes = args.control.resources;
      if (allRes?.find((res) => this._.isArray(res?.children) && res.id === cellResId)) {
        cell.cssClass = 'cell-on-root-resource';
      }
      // toDateLocal because $date parses date as local time zone if no offset is supplied
      const cellDateObj = this.$date(args.cell.start.toDateLocal());
      const foundHoliday = this.holidays?.find((holiday) => {
        const isAllDay = (holiday as any).allday as boolean;
        const sameUnit = isAllDay ? 'day' : 'minute';
        const holidayStartObj = this.$date(holiday.start.toString());
        const holidayEndObj = this.$date(holiday.end.toString());
        const isNotBefore = cellDateObj.isSame(holidayStartObj, sameUnit) || holidayStartObj.isBefore(cellDateObj);
        const isNotAfter = cellDateObj.isSame(holidayStartObj, sameUnit) || holidayEndObj.isAfter(cellDateObj);
        return isNotBefore && isNotAfter;
      });
      if (foundHoliday) {
        cell.properties.business = false;
        cell.properties.disabled = true;
        cell.cssClass += ' cell-holiday';
      }
    };
    this.changeTimeScale(this.timeScale as DayPilot.SchedulerPropsAndEvents['scale']);
  },
  mounted() {
    this.$set(this.config, 'onScroll', (args: DayPilot.SchedulerScrollArgs) => {
      this.$debounce(() => {
        // Don't call the scroll event if the viewport has not changed
        // (avoid loop due to layouting that causes a "scroll" when loading events dynamically)
        const from = (args?.viewport?.start as any)?.value; // ?? this.timeRangeStart;
        const to = (args?.viewport?.end as any)?.value; // ?? this.timeRangeEnd;
        const areSet = !!from && !!to;
        const haveChanged = this.lastScrollStartValue !== from || this.lastScrollEndValue !== to;
        if (areSet && haveChanged) {
          this.lastScrollStartValue = from;
          this.lastScrollEndValue = to;
          this.$emit('scroll', args);
        }
      }, 300, this)();
    });
    this.scheduler.scrollTo(DayPilot.Date.today());
    this.$nextTick(() => {
      this.hideRowHeaderColumns();
    });
  },
  methods: {
    showRowHeaderColumns() {
      if ((this.config.rowHeaderColumnDefaultWidth ?? 0) > 0) {
        return;
      }
      this.config.rowHeaderColumns?.push((this.config as any).previousRowHeaderColumns);
      this.config.rowHeaderColumnDefaultWidth = (this.config as any).previousRowHeaderColumnDefaultWidth;
    },
    hideRowHeaderColumns() {
      if ((this.config.rowHeaderColumnDefaultWidth ?? 0) === 0) {
        return;
      }
      (this.config as any).previousRowHeaderColumnDefaultWidth = this.config.rowHeaderColumnDefaultWidth;
      this.config.rowHeaderColumnDefaultWidth = 0;
      (this.config as any).previousRowHeaderColumns = this.config.rowHeaderColumns?.pop();
    },
    toggleRowHeaderColumns() {
      if ((this.config.rowHeaderColumnDefaultWidth ?? 0) > 0) {
        this.hideRowHeaderColumns();
      } else {
        this.showRowHeaderColumns();
      }
    },
    async loadHolidays(from: string, to: string) {
      this.holidays = [];
      const publicHolidaysRequest = new GetPublicHolidaysRequestModel();
      publicHolidaysRequest.dateFrom = from;
      publicHolidaysRequest.dateTo = to;
      const response = await this.$service.v2.api.publicHolidays.getPublicHolidays(publicHolidaysRequest);
      this.holidays = response.items.map((holiday) => ({
        id: holiday.data.id,
        text: holiday.data.caption,
        start: this.$date(holiday.data.startTime!).format('YYYY-MM-DDTHH:mm:ss'),
        end: this.$date(holiday.data.endTime!).format('YYYY-MM-DDTHH:mm:ss'),
        allday: holiday.data.isAllDay,
      }) as any);
    },
    getLocale(): string {
      const language = this.$translation.get();
      switch (language) {
      case 'de':
        return 'de-ch';
      case 'en':
        return 'en-gb';
      case 'it':
        return 'it-ch';
      case 'fr':
        return 'fr-ch';
      default:
        return 'de-ch';
      }
    },
    getTimeRangeEnd(): string {
      if (this.timeScale === 'Week') {
        console.log(this.$date(this.timeRangeStart).format('YYYY-MM-DD'));
        const endDate = this.$date(this.timeRangeStart).add(6, 'days').format('YYYY-MM-DD');
        console.log(endDate);
        return endDate;
      }
      return this.$date(this.timeRangeStart).add(1, this.timeScale).format('YYYY-MM-DD');
    },
    refresh() {
      this.refreshTimeRange();
      this.$emit('time-range', this.timeRangeStart, this.getTimeRangeEnd(), this.yearsVisible);
    },
    changeTimeScale(timeScale: DayPilot.SchedulerPropsAndEvents['scale']) {
      if (timeScale === 'Day') {
        this.timeRangeStart = this.timeRangeStart ?? this.$date.now().format('YYYY-MM-DD');
        this.refreshTimeRange();
        this.$set(this.config, 'cellDuration', 15);
        this.$set(this.config, 'scale', 'CellDuration');
        this.$set(this.config, 'cellWidth', 20);
        this.$set(this.config, 'timeHeaders', [
          { groupBy: 'Day', format: 'ddd d. MMMM - yyyy' },
          { groupBy: 'Hour' },
        ]);
      } else if (timeScale === 'Week') {
        const now = Vue.$date.now();
        const startOfWeek = this.$date(this.timeRangeStart ?? now).day(1);
        this.timeRangeStart = startOfWeek.format('YYYY-MM-DD');
        this.refreshTimeRange();
        this.$set(this.config, 'cellDuration', 15);
        this.$set(this.config, 'scale', 'CellDuration');
        this.$set(this.config, 'cellWidth', 4.5);
        this.$set(this.config, 'timeHeaders', [
          { groupBy: 'Day', format: 'ddd d. MMMM - yyyy' },
          { groupBy: 'Hour' },
        ]);
      } else if (timeScale === 'Month') {
        const start = this.$date(this.timeRangeStart ?? Vue.$date.now());
        const startOfMonth = Vue.$date(`${start.year()}-${start.month() + 1}-1`);
        this.timeRangeStart = startOfMonth.format('YYYY-MM-DD');
        this.refreshTimeRange();
        this.$set(this.config, 'cellWidth', 100);
        this.$set(this.config, 'scale', 'Day');
        this.$set(this.config, 'timeHeaders', [
          { groupBy: 'Month' },
          { groupBy: 'Day', format: 'd' },
        ]);
      }
      this.refresh();
    },
    onRowHeaderGroupCollapse(isCollapsed: boolean, id: any) {
      if (id !== null && id !== undefined) {
        this.collapsedRowHeaders[id] = isCollapsed;
      }
    },
    navigateInTime(amount: number) {
      const newStart = this.$date(this.timeRangeStart).add(amount, this.timeScale).format('YYYY-MM-DD');
      this.timeRangeStart = newStart;
    },
    refreshTimeRange() {
      if (this.timeScale === 'Week') {
        const dateObj = this.$date(this.timeRangeStart);
        // Set to start of week
        this.timeRangeStart = dateObj.day(1).format('YYYY-MM-DD');
      }

      if (this.timeScale === 'Month') {
        const dateObj = this.$date(this.timeRangeStart);
        // Set to start of Month
        this.timeRangeStart = dateObj.date(1).format('YYYY-MM-DD');
      }

      this.$set(this.config, 'startDate', this.timeRangeStart);
      const startDate = this.$date(this.timeRangeStart);
      const endDate = this.$date(this.getTimeRangeEnd());
      const daysBetween = endDate.diff(startDate, 'day');
      this.$set(this.config, 'days', daysBetween);
    },
  },
});
