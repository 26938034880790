// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ModulePlanningLecturerSchema from './module-planning-lecturer-schema';

const schema = {
  $id: '/module-planning-booking-slot-schema',
  type: 'object',
  required: ['modulePlanningViewLessonId', 'classId', 'lessonId'],
  nullable: true,
  properties: {
    modulePlanningViewLessonId: {
      type: 'integer',
    },
    isBookable: {
      type: 'boolean',
    },
    classId: {
      type: 'integer',
    },
    lessonId: {
      type: 'integer',
    },
    assignedLecturers: {
      type: 'array',
      nullable: true,
      items: { ...ModulePlanningLecturerSchema },
    },
  },
} as IJSONSchema;

export default schema;
