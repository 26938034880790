// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/online-payment-transaction-updated-schema',
  type: 'object',
  required: [],
  properties: {
    id: {
      type: 'string',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
