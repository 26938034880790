// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import Int32LookupResourceViewModel from '../../../../viewModel/resource/Int32LookupResourceViewModel';
import SchedulerResourceViewModel from '../../../../viewModel/resource/SchedulerResourceViewModel';
import SchedulerResourceDTO from '../../dto/resource/SchedulerResourceDTO';

export default abstract class SchedulerResourceModel extends BaseModel<SchedulerResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  */
  get type() { return this.getObjectModel(() => this.dto.type, Int32LookupResourceViewModel)!; }
  set type(value) { this.setObjectModel(() => this.dto.type, value); }
  /**
  */
  get caption() { return this.dto.caption; }
  set caption(value) { this.dto.caption = value; }
  /**
  */
  get details() { return this.dto.details; }
  set details(value) { this.dto.details = value; }
  /**
  */
  get sort() { return this.dto.sort; }
  set sort(value) { this.dto.sort = value; }
  /**
  * @type {date-time}
  */
  get startDate() { return this.dto.startDate; }
  set startDate(value) { this.dto.startDate = value; }
  /**
  * @type {date-time}
  */
  get endDate() { return this.dto.endDate; }
  set endDate(value) { this.dto.endDate = value; }
  /**
  */
  get tags() { return this.dto.tags; }
  set tags(value) { this.dto.tags = value; }
  /**
  * @type {int64}
  */
  get parentResourceId() { return this.dto.parentResourceId; }
  set parentResourceId(value) { this.dto.parentResourceId = value; }
  /**
  */
  get parentResourceType() { return this.getObjectModel(() => this.dto.parentResourceType, Int32LookupResourceViewModel)!; }
  set parentResourceType(value) { this.setObjectModel(() => this.dto.parentResourceType, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    this.dto.caption = qs.caption;
    this.dto.details = qs.details;
    this.dto.startDate = qs.startdate;
    this.dto.endDate = qs.enddate;
    value = Number.parseFloat(qs.parentresourceid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.parentResourceId = value;
  }
}
