// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetPrototypeModulesListRequestModel from '../../model/get-prototype-modules-list-request-model';
import GetPrototypeModuleOverviewRequestModel from '../../model/get-prototype-module-overview-request-model';
import LookupWorkloadSummaryRequestModel from '../../model/lookup-workload-summary-request-model';
import GetLectureLessonHistoryPagedListRequestModel from '../../model/get-lecture-lesson-history-paged-list-request-model';
import LecturePrototypeModuleListPagingResultModel from '../../model/lecture-prototype-module-list-paging-result-model';
import ModulePlanningOverviewModel from '../../model/module-planning-overview-model';
import LecturerWorkloadSummaryModel from '../../model/lecturer-workload-summary-model';
import LectureLessonHistoryListPagingResultModel from '../../model/lecture-lesson-history-list-paging-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async getPrototypeModulesList(request: GetPrototypeModulesListRequestModel, config?: RequestConfig) {
    const requestDTO = GetPrototypeModulesListRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-prototype-modules-list'] ?? 'agogisCockpit/classYears/{year}/PrototypeModules';
    endpointPath = endpointPath.replace(/\{year\}/g, `${requestDTO.year}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        classId: requestDTO.classId,
        classIds: requestDTO.classIds,
        moduleId: requestDTO.moduleId,
        lectureLessonStatusId: requestDTO.lectureLessonStatusId,
        userId: requestDTO.userId,
        lecturerId: requestDTO.lecturerId,
        primaryLeadLecturerId: requestDTO.primaryLeadLecturerId,
        isPrototype: requestDTO.isPrototype,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(LecturePrototypeModuleListPagingResultModel.toModel(dto));
  },
  /**  */
  async getPrototypeModuleOverview(request: GetPrototypeModuleOverviewRequestModel, config?: RequestConfig) {
    const requestDTO = GetPrototypeModuleOverviewRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-prototype-module-overview'] ?? 'agogisCockpit/classYears/{year}/PrototypeModules/Classes/{classId}/Modules/{moduleId}/Overview';
    endpointPath = endpointPath.replace(/\{classId\}/g, `${requestDTO.classId}`);
    endpointPath = endpointPath.replace(/\{moduleId\}/g, `${requestDTO.moduleId}`);
    endpointPath = endpointPath.replace(/\{year\}/g, `${requestDTO.year}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ModulePlanningOverviewModel.toModel(dto));
  },
  /**  */
  async lookupWorkloadSummary(request: LookupWorkloadSummaryRequestModel, config?: RequestConfig) {
    const requestDTO = LookupWorkloadSummaryRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-lookup-workload-summary'] ?? 'agogisCockpit/classYears/{year}/Lecturers/{id}/WorkloadSummary';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    endpointPath = endpointPath.replace(/\{year\}/g, `${requestDTO.year}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(LecturerWorkloadSummaryModel.toModel(dto));
  },
  /**  */
  async getLectureLessonHistoryPagedList(request: GetLectureLessonHistoryPagedListRequestModel, config?: RequestConfig) {
    const requestDTO = GetLectureLessonHistoryPagedListRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-lecture-lesson-history-paged-list'] ?? 'agogisCockpit/LectureLessonHistories';
    const response = await service.get<any>(endpointPath, {
      query: {
        lectureLessonId: requestDTO.lectureLessonId,
        lecturerId: requestDTO.lecturerId,
        from: requestDTO.from,
        to: requestDTO.to,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(LectureLessonHistoryListPagingResultModel.toModel(dto));
  },
});
