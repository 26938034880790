// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ModulePlanningBookingSlotModel from '../../model/module-planning-booking-slot-model';
import ModulePlanningBookingSlotDTO from '../dto/module-planning-booking-slot-dto';
import dtoSchema from '../schemas/module-planning-booking-slot-schema';
import ModulePlanningLecturerModel from '../../model/module-planning-lecturer-model';

export default abstract class ModulePlanningBookingSlotModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ModulePlanningBookingSlotDTO>) {
    super();
    if (data) {
      this.modulePlanningViewLessonId = data.modulePlanningViewLessonId;
      this.isBookable = data.isBookable;
      this.classId = data.classId;
      this.lessonId = data.lessonId;
      this.assignedLecturers = data.assignedLecturers?.map((i) => ModulePlanningLecturerModel.toModel(i)) ?? [];
    }
  }
  /**
  * @type {int64}
  */
  modulePlanningViewLessonId?: number;
  /**
  */
  isBookable?: boolean;
  /**
  * @type {int64}
  */
  classId?: number;
  /**
  * @type {int64}
  */
  lessonId?: number;
  /**
  */
  assignedLecturers?: ModulePlanningLecturerModel[];

  static toModel(dto: DeepPartial<ModulePlanningBookingSlotDTO>): ModulePlanningBookingSlotModel;
  static toModel(dto: DeepPartial<ModulePlanningBookingSlotDTO> | undefined | null): ModulePlanningBookingSlotModel | undefined;
  static toModel(dto: DeepPartial<ModulePlanningBookingSlotDTO> | undefined | null): ModulePlanningBookingSlotModel | undefined {
    return dto ? new ModulePlanningBookingSlotModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ModulePlanningBookingSlotModel> | ModulePlanningBookingSlotModel): ModulePlanningBookingSlotDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      modulePlanningViewLessonId: unwrapped.modulePlanningViewLessonId,
      isBookable: unwrapped.isBookable,
      classId: unwrapped.classId,
      lessonId: unwrapped.lessonId,
      assignedLecturers: unwrapped.assignedLecturers,
    } as ModulePlanningBookingSlotDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ModulePlanningBookingSlotModel, can not map to ModulePlanningBookingSlotDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
