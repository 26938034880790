



















import Vue from 'vue';

export default Vue.extend({
  auth: true,
  methods: {
    gotoModulePlanning() {
      this.$router.push('/agogis/cockpit/schedule-planning/modules');
    },
  },
});
