// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import AppInfoComponentModel from '../../model/app-info-component-model';
import AppInfoComponentDTO from '../dto/app-info-component-dto';
import dtoSchema from '../schemas/app-info-component-schema';

export default abstract class AppInfoComponentModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<AppInfoComponentDTO>) {
    super();
    if (data) {
      this.title = data.title ?? undefined;
      this.version = data.version ?? undefined;
      this.healthEndpoint = data.healthEndpoint ?? undefined;
    }
  }
  /**
  */
  title?: string;
  /**
  */
  version?: string;
  /**
  */
  healthEndpoint?: string;

  static toModel(dto: DeepPartial<AppInfoComponentDTO>): AppInfoComponentModel;
  static toModel(dto: DeepPartial<AppInfoComponentDTO> | undefined | null): AppInfoComponentModel | undefined;
  static toModel(dto: DeepPartial<AppInfoComponentDTO> | undefined | null): AppInfoComponentModel | undefined {
    return dto ? new AppInfoComponentModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<AppInfoComponentModel> | AppInfoComponentModel): AppInfoComponentDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      title: unwrapped.title,
      version: unwrapped.version,
      healthEndpoint: unwrapped.healthEndpoint,
    } as AppInfoComponentDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for AppInfoComponentModel, can not map to AppInfoComponentDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
