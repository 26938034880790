// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';

const schema = {
  $id: '/lecture-prototype-module-list-schema',
  type: 'object',
  required: ['id', 'moduleId', 'moduleTotalDuration', 'moduleLessonsCount', 'classId', 'classStartYear'],
  nullable: true,
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    moduleId: {
      type: 'integer',
    },
    moduleName: {
      type: 'string',
      nullable: true,
    },
    moduleStartDate: {
      type: 'string',
      format: 'date-time',
    },
    moduleEndDate: {
      type: 'string',
      format: 'date-time',
    },
    moduleTotalDuration: {
      type: 'number',
    },
    moduleLessonsCount: {
      type: 'integer',
    },
    classId: {
      type: 'integer',
    },
    className: {
      type: 'string',
      nullable: true,
    },
    classStartYear: {
      type: 'integer',
    },
    classIsPrototype: {
      type: 'boolean',
    },
    countOfRequiredLecturers: {
      type: 'integer',
      nullable: true,
    },
    countOfProvisionalClasses: {
      type: 'integer',
      nullable: true,
    },
    countOfReservedClasses: {
      type: 'integer',
      nullable: true,
    },
    countOfConfirmedClasses: {
      type: 'integer',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
