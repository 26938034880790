// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ForgotPasswordRequestModel from '../../model/forgot-password-request-model';
import ForgotPasswordRequestDTO from '../dto/forgot-password-request-dto';
import dtoSchema from '../schemas/forgot-password-request-schema';

export default abstract class ForgotPasswordRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ForgotPasswordRequestDTO>) {
    super();
    if (data) {
      this.targetUrl = data.targetUrl;
      this.email = data.email;
    }
  }
  /**
  * @minimum 1
  */
  targetUrl?: string;
  /**
  * @minimum 1
  * @type {email}
  */
  email?: string;

  static toModel(dto: DeepPartial<ForgotPasswordRequestDTO>): ForgotPasswordRequestModel;
  static toModel(dto: DeepPartial<ForgotPasswordRequestDTO> | undefined | null): ForgotPasswordRequestModel | undefined;
  static toModel(dto: DeepPartial<ForgotPasswordRequestDTO> | undefined | null): ForgotPasswordRequestModel | undefined {
    return dto ? new ForgotPasswordRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ForgotPasswordRequestModel> | ForgotPasswordRequestModel): ForgotPasswordRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      targetUrl: unwrapped.targetUrl,
      email: unwrapped.email,
    } as ForgotPasswordRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ForgotPasswordRequestModel, can not map to ForgotPasswordRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
