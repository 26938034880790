// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import LecturePrototypeModuleListResourceViewModel from '../../../../viewModel/resource/LecturePrototypeModuleListResourceViewModel';
import LecturePrototypeModuleListResourceDTO from '../../dto/resource/LecturePrototypeModuleListResourceDTO';

export default abstract class LecturePrototypeModuleListResourceModel extends BaseModel<LecturePrototypeModuleListResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get moduleId() { return this.dto.moduleId; }
  set moduleId(value) { this.dto.moduleId = value; }
  /**
  */
  get moduleName() { return this.dto.moduleName; }
  set moduleName(value) { this.dto.moduleName = value; }
  /**
  * @type {date-time}
  */
  get moduleStartDate() { return this.dto.moduleStartDate; }
  set moduleStartDate(value) { this.dto.moduleStartDate = value; }
  /**
  * @type {date-time}
  */
  get moduleEndDate() { return this.dto.moduleEndDate; }
  set moduleEndDate(value) { this.dto.moduleEndDate = value; }
  /**
  * @type {double}
  */
  get moduleTotalDuration() { return this.dto.moduleTotalDuration; }
  set moduleTotalDuration(value) { this.dto.moduleTotalDuration = value; }
  /**
  * @type {int32}
  */
  get moduleLessonsCount() { return this.dto.moduleLessonsCount; }
  set moduleLessonsCount(value) { this.dto.moduleLessonsCount = value; }
  /**
  * @type {int64}
  */
  get classId() { return this.dto.classId; }
  set classId(value) { this.dto.classId = value; }
  /**
  */
  get className() { return this.dto.className; }
  set className(value) { this.dto.className = value; }
  /**
  * @type {int32}
  */
  get classStartYear() { return this.dto.classStartYear; }
  set classStartYear(value) { this.dto.classStartYear = value; }
  /**
  */
  get classIsPrototype() { return this.dto.classIsPrototype; }
  set classIsPrototype(value) { this.dto.classIsPrototype = value; }
  /**
  * @type {int32}
  */
  get countOfRequiredLecturers() { return this.dto.countOfRequiredLecturers; }
  set countOfRequiredLecturers(value) { this.dto.countOfRequiredLecturers = value; }
  /**
  * @type {int32}
  */
  get countOfProvisionalClasses() { return this.dto.countOfProvisionalClasses; }
  set countOfProvisionalClasses(value) { this.dto.countOfProvisionalClasses = value; }
  /**
  * @type {int32}
  */
  get countOfReservedClasses() { return this.dto.countOfReservedClasses; }
  set countOfReservedClasses(value) { this.dto.countOfReservedClasses = value; }
  /**
  * @type {int32}
  */
  get countOfConfirmedClasses() { return this.dto.countOfConfirmedClasses; }
  set countOfConfirmedClasses(value) { this.dto.countOfConfirmedClasses = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.moduleid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.moduleId = value;
    this.dto.moduleName = qs.modulename;
    this.dto.moduleStartDate = qs.modulestartdate;
    this.dto.moduleEndDate = qs.moduleenddate;
    value = Number.parseFloat(qs.moduletotalduration);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.moduleTotalDuration = value;
    value = Number.parseFloat(qs.modulelessonscount);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.moduleLessonsCount = value;
    value = Number.parseFloat(qs.classid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.classId = value;
    this.dto.className = qs.classname;
    value = Number.parseFloat(qs.classstartyear);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.classStartYear = value;
    value = qs.classIsPrototype === 'true' ? true : qs.classisprototype;
    value = qs.classIsPrototype === 'false' ? false : undefined;
    this.dto.classIsPrototype = value;
    value = Number.parseFloat(qs.countofrequiredlecturers);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.countOfRequiredLecturers = value;
    value = Number.parseFloat(qs.countofprovisionalclasses);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.countOfProvisionalClasses = value;
    value = Number.parseFloat(qs.countofreservedclasses);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.countOfReservedClasses = value;
    value = Number.parseFloat(qs.countofconfirmedclasses);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.countOfConfirmedClasses = value;
  }
}
