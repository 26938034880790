// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/get-scheduler-by-rooms-request-schema',
  type: 'object',
  required: ['startTime', 'endTime'],
  properties: {
    startTime: {
      type: 'string',
      format: 'date-time',
    },
    endTime: {
      type: 'string',
      format: 'date-time',
    },
    selectedResourceTypes: {
      type: 'array',
      items: {
        type: 'integer',
      },
    },
  },
} as IJSONSchema;

export default schema;
